<!--start wrapper-->
<div class="wrapper">

  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-white rounded-0 border-bottom">
      <div class="container">
        <!-- <a class="navbar-brand" href="#"><img src="assets/images/brand-logo-2.png" width="140" alt="" /></a> -->
        <h4 class="logo-text" style="color: #015984;">DigiTax</h4>
      </div>
    </nav>
  </header>

  <!--start content-->
  <main class="authentication-content">
    <div class="container">
      <div class="mt-5 mb-2">
        <div class="card rounded-0 overflow-hidden shadow-none border mb-5 mb-lg-0">
          <div class="row g-0">
            <div class="col-12 order-1 col-xl-8 d-flex align-items-center justify-content-center border-end">
              <img src="assets/images/error/auth-img-7.png" class="img-fluid" alt="">
            </div>
            <div class="col-12 col-xl-4 order-xl-2">
              <div class="card-body p-4 p-sm-5">
                <h5 class="card-title">Mot de passe oublié</h5>
                <p class="card-text mb-4">Obtenez un nouveau mot passé.</p>
                <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                  {{successMessage}}
                </div>
                <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                  {{errorMessage}}
                </div>
                <form class="form-body" novalidate [formGroup]="forgotForm" (ngSubmit)="onForgot()">
                  <div class="row g-3">
                    <div class="col-12">
                      <label for="inputEmailAddress" class="form-label">Nom utilisateur</label>
                      <div class="ms-auto position-relative">
                        <div class="position-absolute top-50 translate-middle-y search-icon px-3"><i
                          class="bi bi-person-lines-fill"></i></div>
                        <input type="text" class="form-control radius-30 ps-5" id="inputEmailAddress"
                          placeholder="Nom utilisateur" formControlName="tenant">
                      </div>
                      <div class="text-danger" *ngIf="!tenant?.valid && (tenant?.dirty ||tenant?.touched)">Le nom utilisateur est
                        requis</div>
                    </div>
                    <div class="col-12">
                      <label for="inputEmailAddress" class="form-label">Votre adresse email</label>
                      <div class="ms-auto position-relative">
                        <div class="position-absolute top-50 translate-middle-y search-icon px-3"><i
                            class="bi bi-envelope-fill"></i></div>
                        <input type="email" class="form-control radius-30 ps-5" id="inputEmailAddress"
                          placeholder="Email" formControlName="email">
                      </div>
                      <div class="text-danger" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">L'email est
                        requis</div>
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <button type="submit" *ngIf="!isLoading" [disabled]="!forgotForm.valid" class="btn btn-primary radius-30">Valider</button>
                        <button *ngIf="isLoading" class="btn btn-primary radius-30" type="button" disabled=""><span
                          class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <a routerLink="/login" class="btn btn-light radius-30">Se connecter</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <!--end page main-->

  <footer class="bg-white border-top p-3 text-center fixed-bottom">
    <p class="mb-0">Copyright © 2021. All right reserved.</p>
  </footer>

</div>
<!--end wrapper-->