import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Importation } from './models/Importation';
import { ImportationCsvService } from './services/importation-csv.service';
import { filter } from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-importation-csv',
  templateUrl: './importation-csv.component.html',
  styleUrls: ['./importation-csv.component.css']
})
export class ImportationCsvComponent implements OnInit {

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  table: any;

  importation = new Importation();
  private fileSource: any;
  typeFile : any;

  importationForm = new FormGroup({
    source: new FormControl('', Validators.required)
  });

  dataTable: any;

  constructor(private router:Router, private activatedRoute:ActivatedRoute, 
    private elementRef: ElementRef, private localStorageService: LocalStorageService,
     private formBuilder: FormBuilder, private importationService : ImportationCsvService) { 
    this.findAll();
  }

  ngOnInit(): void {
    this.typeFile = this.localStorageService.getItem("typeImport");
  }

  get source() {
    return this.importationForm?.get('source');
  }

  download(): void {
    this.importationService
      .download("/assets/file_csv/" + this.typeFile + ".csv")
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = this.typeFile + '.csv';
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
  }


  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'libelle' },
        { data: 'line' },
        { data: 'contenu' },
        { data: 'erreur' },
        { data: 'type' }
      ],

    });
    //this.routeEdit();
  }

  showPreview(event : any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileSource = reader.result;
      };
    }
  }


  onSubmit(){
    this.isLoading = true;
    this.importation.base64 = this.fileSource.replace("data:text/csv;base64,", "");
    this.importation.type = this.typeFile;
    this.importationService.save(this.importation).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Succès";
        this.hasError = false;
        this.isLoading = false;
        this.importationForm.reset();
        this.table.destroy();
        this.findAll();
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

  findAll() {
    this.importationService.findAll().subscribe({
      next: (data) => {
        this.dataTable = data;
        this.onDataTable(this.dataTable);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }




}
