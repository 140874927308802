import { Component, ElementRef, OnInit } from '@angular/core';
import { UtilisateurPayload } from '../../models/utilisateur-payload';
import { Datum as DatumProfile } from '../../../profil/interfaces/profil-response';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfilService } from 'src/app/pages/profil/services/profil.service';
import { UtilisateurService } from '../../services/utilisateur.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  utisateurPayloag = new UtilisateurPayload();

  dataProfileValue: DatumProfile[] = [];

  dataUtilisateurValue: any = [];

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  utilisateurForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    profileId: new FormControl('', Validators.required)
  });
  paramId: any;
  Idprofil: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private profilService: ProfilService, private utilisateurService: UtilisateurService, private router: Router, 
    private route: ActivatedRoute, private localStorageService:LocalStorageService) { 
    this.findAllProfile();
  }

  ngOnInit(): void {

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);

    var f = document.createElement("script");
    f.type = "text/javascript";
    f.src = "../assets/js/form-select2.js";
    this.elementRef.nativeElement.appendChild(f);

    this.paramId = this.route.snapshot.paramMap.get('id');
  }

  get name() {
    return this.utilisateurForm?.get('name');
  }
  get email() {
    return this.utilisateurForm?.get('email');
  }

  get profileId() {
    return this.utilisateurForm?.get('profileId');
  }

  findAllProfile() {
    this.profilService.findAll().subscribe({
      next: (data) => {
        const response: DatumProfile[] = data;
        this.dataProfileValue = response;
      },
      error: (err) => {
        console.log(err);
      },

    })
  }

  findById(id: any){
    this.utilisateurService.findById(id).subscribe({
      next: (data) => {
        this.utilisateurForm.get('name')?.setValue(data.name);
        this.utilisateurForm.get('email')?.setValue(data.email);
        this.Idprofil = data.profile.id;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onUpdate(){
    this.isLoading = true;
    this.utisateurPayloag.name = this.utilisateurForm.get('name')?.value;
    this.utisateurPayloag.email = this.utilisateurForm.get('email')?.value;
    this.utisateurPayloag.profileId = this.utilisateurForm.get('profileId')?.value;
    this.utisateurPayloag.tenantId = this.localStorageService.getItem('tenantId');
    
    this.utilisateurService.update(this.utisateurPayloag, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "utilisateur ajouté avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.findById(this.paramId);
      },
      error: (err) => {
        this.hasSuccess = false;
        if (err.status == 422) {
          this.errorMessage = "Données invalides.Il semble que l'utilisateur existe déjà.";
          this.hasError = true;
          this.isLoading = false;
        }
        this.isLoading = false;
      },
    })
  }

}
