import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { debug } from 'src/app/utility/module';
import { AuditService } from '../../audit/services/audit.service';
import { AuthResponse } from '../interfaces/auth-response';
import { Auth } from '../models/auth';
import { Otp } from '../models/otp';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  authPayload = new Auth();

  otpPaylaod = new Otp();

  authForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    login: new FormControl('', Validators.required)
  })

  valeurOtpForm = new FormGroup({
    valeur1: new FormControl('', Validators.required),
    valeur2: new FormControl('', Validators.required),
    valeur3: new FormControl('', Validators.required),
    valeur4: new FormControl('', Validators.required),
  })

  hasError: boolean = false;
  hasErrorOtp: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  isLoadingOtp: boolean = false;
  isLoadingActualiser: boolean = false;
  errorMessage: any;
  errorMessageOtp: any;
  successMessage: any;

  username: any;

  loggedIn: boolean = false;


  twoFactor: boolean = false;

  niveauCompteRebours: any;

  btnActualiser: boolean = false;

  BaseUrl: any = window.location.origin;



  constructor(private elementRef: ElementRef, private authService: AuthService, private localStorageService: LocalStorageService, public router: Router) {

  }

  ngOnInit(): void {
    var p = document.createElement("script");
    p.type = "text/javascript";
    p.src = "../assets/js/otp.js";
    this.elementRef.nativeElement.appendChild(p);
  }

  get email() {
    return this.authForm?.get('email');
  }

  get password() {
    return this.authForm?.get('password');
  }

  get login() {
    return this.authForm?.get('login');
  }



  get valeur1() {
    return this.valeurOtpForm?.get('valeur1');
  }

  get valeur2() {
    return this.valeurOtpForm?.get('valeur2');
  }

  get valeur3() {
    return this.valeurOtpForm?.get('valeur3');
  }

  get valeur4() {
    return this.valeurOtpForm?.get('valeur4');
  }

  onCompteaRebours() {
    var cpt = 120;
    var temps = setInterval(() => {
      --cpt;
      this.niveauCompteRebours = cpt;
      if (cpt == 0) {
        clearInterval(temps);
        this.btnActualiser = true;
      }
    }, 1000);

  }

  onSubmit() {
    this.isLoading = true;
    this.authPayload.email = this.authForm.get('email')?.value;
    this.authPayload.password = this.authForm.get('password')?.value;
    var tenant = this.authForm.get('login')?.value;
    this.localStorageService.setItem('tenantId', tenant);

    this.username = this.authPayload.email;

    this.authService.login(this.authPayload).subscribe({
      next: (data) => {
        if (data.otpcode != null) {
          this.otpPaylaod.userlogin = data.userlogin;
          this.twoFactor = true;
          this.onCompteaRebours();
        }
        else if(data.profile.type == "WEB") {
          const authRespone: AuthResponse = data;
          if (authRespone.profile) {
            var array = authRespone.profile.menus;
            this.localStorageService.setItem('pages', JSON.stringify(array));
          }
          this.localStorageService.setItem('user', JSON.stringify(authRespone));
          this.localStorageService.setItem('token', authRespone.accessToken);
          this.localStorageService.auth();
          if (authRespone.profile) {
            if (authRespone.profile.menus[0].lien != null) {

              window.location.assign(this.BaseUrl + authRespone.profile.menus[0].lien);
            } else if (authRespone.profile.menus[0].sousmenus != null) {
              window.location.assign(this.BaseUrl + authRespone.profile.menus[0].sousmenus[0].lien!);
            }
          }
          this.hasError = false;
          this.isLoading = false;
        }

        this.hasError = false;
        this.isLoading = false;

      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Vous n'êtes pas autorisé à vous connecter";
          this.hasError = true;
          this.isLoading = false;
        } else {
          this.errorMessage = "Nous n'arrivons pas à traiter votre demande. Veuillez réessayer";
          this.hasError = true;
          this.isLoading = false;
        }
      },
    });
  }

  onConfirmOtp() {
    this.isLoadingOtp = true;
    var v1 = this.valeurOtpForm.get('valeur1')?.value;
    var v2 = this.valeurOtpForm.get('valeur2')?.value;
    var v3 = this.valeurOtpForm.get('valeur3')?.value;
    var v4 = this.valeurOtpForm.get('valeur4')?.value;

    this.otpPaylaod.otpcode = "" + v1 + v2 + v3 + v4;

    this.authService.otp(this.otpPaylaod).subscribe({
      next: (data) => {
        const authRespone: AuthResponse = data;
        if (authRespone.profile) {
          var array = authRespone.profile.menus;
          this.localStorageService.setItem('pages', JSON.stringify(array));
        }
        this.localStorageService.setItem('user', JSON.stringify(authRespone));
        this.localStorageService.setItem('token', authRespone.accessToken);
        this.localStorageService.auth();
        if (authRespone.profile) {
          if (authRespone.profile.menus[0].lien != null) {
            window.location.assign(this.BaseUrl + authRespone.profile.menus[0].lien);
          } else if (authRespone.profile.menus[0].sousmenus != null) {
            window.location.assign(this.BaseUrl + authRespone.profile.menus[0].sousmenus[0].lien!);
          }
        }
        this.twoFactor = true;
        this.errorMessageOtp = false;
        this.isLoadingOtp = false;

      },
      error: (err) => {
        if (err.status == 401) {
          this.twoFactor = true;
          this.errorMessageOtp = "L'otp est incorrect";
          this.hasErrorOtp = true;
          this.isLoadingOtp = false;
        } else {
          this.twoFactor = true;
          this.errorMessageOtp = "L'otp est incorrect";
          this.hasErrorOtp = true;
          this.isLoadingOtp = false;
        }
      },
    })
  }

  onActualiserOtp() {
    this.authService.Actualiserotp(this.otpPaylaod).subscribe({
      next: (data) => {
        console.log(data);
        this.isLoadingActualiser = true;
        this.btnActualiser = false;
        this.onCompteaRebours();
      },
      error: (err) => {
        this.isLoadingActualiser = false;
        this.btnActualiser = false;
        console.log(err);
      },
    })
  }


}

