<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Utilisateurs</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Utilisateurs</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">Utilisateurs</h5>
                    <div class="ms-auto position-relative">
                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><i class="bi bi-bookmark-plus"></i> Ajouter</button>
                    </div>
                </div>
                <div class="table-responsive mt-3">
                    <table id="example2" class="table align-middle">
                        <thead class="table-secondary" class="table-primary">
                            <tr>
                                <th>Nom</th>
                                <th>Email</th>
                                <th>Profil</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<form class="" novalidate [formGroup]="utilisateurForm" (ngSubmit)="onSubmit()">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ajouter un utilisateur</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row form-group">
                        <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                            {{successMessage}}
                        </div>
                        <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                            {{errorMessage}}
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Nom</label>
                            <input type="text" class="form-control" style="height: 35px;" placeholder=""
                                formControlName="name">
                            <div class="text-danger" *ngIf="!name?.valid && (name?.dirty ||name?.touched)">Le nom est
                                requis</div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Email</label>
                            <input type="email" class="form-control" style="height: 35px;" placeholder=""
                                formControlName="email">
                            <div class="text-danger" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">L'email
                                est requis</div>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Séléctionnez un profil</label>
                            <select class="form-control" formControlName="profileId">
                                <option *ngFor="let profil of dataProfileValue" value="{{profil.id}}">{{profil.name}}
                                </option>
                            </select>
                            <div class="text-danger"
                                *ngIf="!profileId?.valid && (profileId?.dirty ||profileId?.touched)">Le profil est
                                requis</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i
                            class="bi bi-x-lg"></i> Annuler</button>
                    <button type="submit" *ngIf="!isLoading" [disabled]="!utilisateurForm.valid"
                        class="btn btn-primary btn-sm"><i class="bi bi-plus-circle"></i>
                        Valider</button>
                    <button *ngIf="isLoading" class="btn btn-primary btn-sm" type="button" disabled=""><span
                            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                </div>
            </div>
        </div>
    </div>
</form>