import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { find } from 'rxjs';
import { AuditService } from './services/audit.service';

declare let $: any;

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

  table : any;

  auditForm = new FormGroup({
    date: new FormControl('')
  });



  constructor(private elementRef: ElementRef, private auditService: AuditService, public datepipe: DatePipe) {
    var cd = new Date();

    var currentDate = cd.getDate() + '-' + ((cd.getMonth()) + 1) + '-' + cd.getFullYear();

    this.findDate(currentDate);

   }

  ngOnInit(): void {
    this.auditForm.valueChanges.subscribe(x => {
      var getValueDate = this.auditForm.get('date')?.value;
      var date = this.datepipe.transform(getValueDate, 'dd-MM-yyyy');
      console.log(date);
      this.table.destroy();
      this.findDate(date);
    })
  }

  get date() {
    return this.auditForm?.get('date');
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'utilisateur' },
        { data: 'email' },
        { data: 'ip' },
        { data: 'page' },
        { 
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.action == "Modification"){
              res = `<span style="color: orange;">${row.action}</span>`
            }else if(row.action == "Enregistrement"){
              res=`<span style="color: blue;">${row.action}</span>`
            }else {
              res = `<span style="color: red;">${row.action}</span>`
            }
            return res;
          }
        },
        { data: 'message' },
        { data: 'valeur' },
        { data: 'status' },
      ],

    });
  }

  findDate(date : any) {
    this.auditService.findByDate(date).subscribe({
      next: (data) => {
        this.onDataTable(data);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
