import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { ContribuableService } from '../../services/contribuable.service';

declare let $: any;

@Component({
  selector: 'app-view-taxe',
  templateUrl: './view-taxe.component.html',
  styleUrls: ['./view-taxe.component.css']
})
export class ViewTaxeComponent implements OnInit {
  paramId: any;

  table : any;

  IdContribuable:any;
  IdLocal: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, private router: Router, private route: ActivatedRoute, 
    private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
  }

  onDataTable(data: any) {
    const that = this;
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'taxe.activite.libelle' },
        { data: 'taxe.categorie.libelle' },
        { data: 'taxe.cout' },
        {
          mRender: function (data: any, type: any, row: any) {

            var currentDate = new Date();
            var penalite = 0;
            var echeance = new Date(row.taxe.echeance);
            if (currentDate.getDate() > echeance.getDate()) {
              penalite = row.taxe.penalite
              return penalite + '%';
            } else {
              penalite = 0;
              return penalite + '%';
            }
          },
        },
        { data: 'taxe.echeance' },
        {
          mRender: function (data: any, type: any, row: any) {
            return row.taxe.frequence
          },
        }
      ],

    });
  }

  findById(id: any) {
    this.contribuableService.findByTaxeIdLocal(id).subscribe({
      next: (data) => {
        this.onDataTable(data);
        this.IdContribuable =  this.localStorageService.getItem('idContribuable');
        this.IdLocal =  this.localStorageService.getItem('idLocal');
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
