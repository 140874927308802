import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { ContribuableService } from '../../contribuable/services/contribuable.service';
import { TransactionPayload } from '../models/transaction-payload';
import { PaiementService } from '../services/paiement.service';

declare let $: any;

@Component({
  selector: 'app-regler',
  templateUrl: './regler.component.html',
  styleUrls: ['./regler.component.css']
})
export class ReglerComponent implements OnInit {

  checked: any;

  checkedMonney: any;

  transactionPayload = new TransactionPayload();

  paramId: any;
  secteur: any;
  activite: any;
  categorie: any;
  cout: any;
  echeance: any;
  penalite: any;

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  viewInput: boolean = false;

  viewMobileMonney: boolean = false;

  inputMobileMonney: boolean = false;


  newPenalite: any;

  LocalId: any;
  TaxeId: any;

  montantPaye: any;

  numeroMobile: any;

  operateur: any;

  idTaxe: any;

  idContribuable: any;

  btnConfirm: boolean = false;

  btnValidEspece: boolean = false;

  referenceId: any;

  dataTransferMonneyForm = new FormGroup({
    telephone: new FormControl('', Validators.required)
  })

  dataConpenseForm = new FormGroup({
    conpense: new FormControl('')
  })
  compenseAppliquer: any;
  compense: any;

  data: any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private paiementService: PaiementService, private router: Router, private route: ActivatedRoute,
    private localStorageService: LocalStorageService, private contribuableService: ContribuableService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);

    this.dataConpenseForm.get('compense')?.valueChanges.subscribe(x => {

    });
  }


  get conpense() {
    return this.dataConpenseForm?.get('conpense');
  }

  get telephone() {
    return this.dataTransferMonneyForm?.get('telephone');
  }

  onChecked(value: any) {
    this.checked = '';
    this.checked = value;
    if (this.checked == 'MOBILE MONNEY') {
      $('#especes').prop("checked", false);
      this.viewMobileMonney = true;
      this.btnValidEspece = false;
    } else if (this.checked == 'ESPECES') {
      $('#mobilemonney').prop("checked", false);
      this.viewMobileMonney = false;
      this.inputMobileMonney = false;
      this.btnValidEspece = true;
    }
    /*  */
  }

  onCheckedMobible(valueData: any) {
    this.checkedMonney = '';
    this.checkedMonney = valueData;
    if (this.checkedMonney == 'ORANGE MONNEY') {
      $('#mtn').prop("checked", false);
      $('#moov').prop("checked", false);
      this.inputMobileMonney = true;
      this.btnValidEspece = false;
    } else if (this.checkedMonney == 'MOOV MONNEY') {
      $('#mtn').prop("checked", false);
      $('#orange').prop("checked", false);
      this.inputMobileMonney = true;
      this.btnValidEspece = false;
    } else if (this.checkedMonney == 'MTN MONNEY') {
      $('#orange').prop("checked", false);
      $('#moov').prop("checked", false);
      this.inputMobileMonney = true;
      this.btnValidEspece = false;
    }
  }

  findById(id: any) {
    this.paiementService.findDetailTaxeContribuableById(id).subscribe({
      next: (data) => {
        console.log(data);
        this.secteur = data.taxe.activite.secteurActivite.libelle;
        this.activite = data.taxe.activite.libelle;
        this.categorie = data.taxe.categorie.libelle;

        this.cout = data.taxe.cout;
        this.echeance = data.taxe.echeance;
        var currentDate = new Date();
        var echeance = new Date(this.echeance);
        if (currentDate > echeance) {
          this.penalite = data.taxe.penalite;
        } else {
          this.penalite = 0;
        }

        if (this.penalite == 0) {
          this.montantPaye = this.cout;
        } else {
          //var res = this.penalite - this.compenseAppliquer;
          this.montantPaye = this.cout + ((this.cout * (this.penalite)) / 100);
        }

        this.LocalId = this.localStorageService.getItem('localId');
        this.TaxeId = this.localStorageService.getItem('taxeId');

      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onCalculWithConpense() {
    this.compense = this.dataConpenseForm.get('compense')?.value;
    if (this.compense != null || this.compense != 0) {
      this.compenseAppliquer = this.penalite - this.compense;
      this.compense = this.compenseAppliquer;
    }
  }

  onViewInputCompenser() {
    this.viewInput = true;
  }

  onCancelInputCompenser() {
    this.viewInput = false;
  }

  onConfirm() {
    $('#confirModal').modal('show');
    this.operateur = this.checkedMonney;
    this.numeroMobile = this.dataTransferMonneyForm.get('telephone')?.value;
  }


  onCancel() {
    $('#confirModal').modal('hide');
    if(this.checked == "MOBILE MONNEY"){
      this.paiementService.getStatus(this.referenceId).subscribe({
        next: (data) => {
          console.log(data);
        },
        error: (err) => {
          console.log(err);
        },
      })
    }
    this.router.navigateByUrl('/paiement/contribuable');
  }


  onSubmit() {
    this.isLoading = true;

    this.transactionPayload.type = this.checked;
    this.transactionPayload.etat = "100";
    this.transactionPayload.montant = this.cout;
    this.transactionPayload.montantTotal = this.montantPaye;
    this.transactionPayload.frais = (this.cout * this.penalite) / 100;
    this.transactionPayload.penalite = this.penalite;
    this.transactionPayload.conpense = this.compense == null ? 0 : this.compense;
    this.transactionPayload.idTaxeContribuable = this.paramId;
    this.transactionPayload.idContribuable = this.localStorageService.getItem('idContribuable');
    this.transactionPayload.tenant = this.localStorageService.getItem('tenantId');

    this.transactionPayload.creerPar = this.data.id;
    this.transactionPayload.modifierPar = null;


    if (this.transactionPayload.type == "MOBILE MONNEY") {

      this.transactionPayload.operateur = this.operateur;
      this.transactionPayload.telephone = this.numeroMobile;

      this.paiementService.sendMobile(this.transactionPayload).subscribe({
        next: (data) => {
          console.log(data);
          this.hasSuccess = true;
          this.successMessage = "Le paiement a été éffectué avec succès.";
          this.hasError = false;
          this.isLoading = false;
          this.btnConfirm = true;
          this.referenceId = data.cpm_trans_id;
          console.log(this.referenceId);

          window.open(data.data.payment_url, '_blank');
        },
        error: (err) => {
          this.errorMessage = "Une erreur s'est produite.";
          this.hasError = true;
          this.btnConfirm = true;
          this.isLoading = false;
          console.log(err);
        },
      })
    } else {
      this.transactionPayload.operateur = this.transactionPayload.tenant;
      this.transactionPayload.telephone = "0000000000";
      this.paiementService.sendEspece(this.transactionPayload).subscribe({
        next: (data) => {
          console.log(data);
          this.hasSuccess = true;
          this.btnConfirm = true;
          this.successMessage = "Le paiement a été éffectué avec succès.";
          this.hasError = false;
          this.isLoading = false;
        },
        error: (err) => {
          this.errorMessage = "Une erreur s'est produite.";
          this.hasError = true;
          this.btnConfirm = true;
          this.isLoading = false;
          console.log(err);
        },
      })
    }


  }

}
