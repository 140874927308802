import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VirtualTimeScheduler } from 'rxjs';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Datum } from './interfaces/secteur-activite-response';
import { SecteurActivitePayload } from './models/secteur-activite-payload';
import { SecteurActiviteService } from './services/secteur-activite.service';


declare let $: any;

@Component({
  selector: 'app-secteur-activite',
  templateUrl: './secteur-activite.component.html',
  styleUrls: ['./secteur-activite.component.css']
})
export class SecteurActiviteComponent implements OnInit {

  SecteurActivite : any = "secteur";

  dataTableSecteurActiviteValue: Datum[] = [];

  secteurActivitePayload = new SecteurActivitePayload();

  secteurActiviteForm = new FormGroup({
    libelle: new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  id: any;
  table: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private secteurActiviteService: SecteurActiviteService, private router: Router,
     private localStorageService: LocalStorageService) {
    this.findAll();
  }

  ngOnInit(): void {

  }

  ngRedirectImport(){
    this.localStorageService.setItem("typeImport", this.SecteurActivite);
    this.router.navigate(["/import"])
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'libelle' },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
              <a style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/secteuractivite/edit/${row.id}"
                  class="btn btn-warning btn-sm rowId"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
    //this.routeEdit();
  }

 /*  routeEdit() {
    const that = this;
    $('.rowId').on('click', function (e: any) {
      var id = e.delegateTarget.id
      that.router.navigateByUrl('secteuractivite/edit/' + id);
    });
  } */

  get libelle() {
    return this.secteurActiviteForm?.get('libelle');
  }

  findAll() {
    this.secteurActiviteService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableSecteurActiviteValue = response;
        this.onDataTable(response);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onSubmit() {
    this.isLoading = true;
    var lib = this.secteurActiviteForm.get('libelle')?.value;
    this.secteurActivitePayload.libelle = lib?.toUpperCase();
    this.secteurActivitePayload.creerPar = this.data.id;
    this.secteurActivitePayload.modifierPar = null;
    this.secteurActiviteService.save(this.secteurActivitePayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Secteur d'activité modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.secteurActiviteForm.reset()
        this.table.destroy();
        this.findAll();
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

}
