<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Contribuables</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable/viewcontribuable/{{IdContribuable}}">Detail contribuable</a></li>
                <li class="breadcrumb-item active" aria-current="page">Modifier activité contribuable</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-md-8 col-lg-6 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="card-title">
                    <h4 class="text-center">Modifier activité contribuable</h4>
                </div>
                <hr>
                <form class="" novalidate [formGroup]="activiteContribuableForm" (ngSubmit)="onUpdate()">
                    <div class="row">
                        <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                            {{successMessage}}
                        </div>
                        <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                            {{errorMessage}}
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Registre de Commerce</label>
                            <input type="text" class="form-control" style="height: 35px;"
                                placeholder="Entrer le registre de commerce" formControlName="numeroRegistreCommerce">
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Immatriculation</label>
                            <input type="text" class="form-control" style="height: 35px;"
                                placeholder="Entrer l'immatriculation" formControlName="numeroImmatriculation">
                            <div class="ms-3"
                                *ngIf="!numeroImmatriculation?.valid && (numeroImmatriculation?.dirty ||numeroImmatriculation?.touched)">
                                <div class="text-danger">Le champs Immatriculation est requis</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Date de creation</label>
                            <input type="date" class="form-control" style="height: 35px;"
                                placeholder="Entrer la date de creation" formControlName="dateCreationEntreprise">
                            <div class="ms-3"
                                *ngIf="!dateCreationEntreprise?.valid && (dateCreationEntreprise?.dirty ||dateCreationEntreprise?.touched)">
                                <div class="text-danger">Le champs date de creation est requis</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Date d'immatriculation </label>
                            <input type="date" class="form-control" style="height: 35px;"
                                placeholder="Entrer l'immatriculation" formControlName="dateImmatriculation">
                            <div class="ms-3"
                                *ngIf="!dateImmatriculation?.valid && (dateImmatriculation?.dirty ||dateImmatriculation?.touched)">
                                <div class="text-danger">Le champs date d'immatriculation est requis</div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Séléctionnez une activité</label>
                            <select class="form-control" formControlName="idActivite">
                                <option *ngFor="let activite of dataTableActiviteValue" [selected]="activite.id === Activite" value="{{activite.id}}">
                                    {{activite.libelle}}</option>
                            </select>
                            <div class="ms-3" *ngIf="!idActivite?.valid && (idActivite?.dirty ||idActivite?.touched)">
                                <div class="text-danger">Le champs activité est requis</div>
                            </div>
                        </div>
                        <div class="col-12 mb-2" style="text-align: right;">
                            <button *ngIf="!isLoading" type="submit" class="btn btn-warning btn-sm"
                                ><i class="bi bi-plus-circle"></i>
                                Modifier</button>
                            <button *ngIf="isLoading" class="btn btn-warning" type="button" disabled=""><span
                                    class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Loading...</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>