import { Component, ElementRef, OnInit } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ProfilService } from '../../services/profil.service';
import { Datum as DatumPage } from '../../interfaces/page-response';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Datum } from '../../interfaces/profil-response';
import { ProfilPayload } from '../../models/profil-payload';
import { ProfilCreate } from '../../models/profil-create';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

declare let $: any;

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  options: IndividualConfig;

  profilPayload = new ProfilPayload();

  profilCreate = new ProfilCreate();

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  dataPageValue: DatumPage[] = [];
  dataProfileValue: Datum[] = [];

  arrayMenu: any = [];

  dataMenu: any = [];

  tab: any = [];

  //Tab Menu
  arrayAction2: any = [];
  array2: any = [];

  value1: any;

  value2: any;

  alert2: boolean = false;

  paramId: any;

  typeArray : any = [
    {"libelle" : "WEB"},
    {"libelle" : "MOBILE"}
  ]

  action1From = new FormGroup({
    name: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    label1: new FormControl('', Validators.required),
    labelRemove: new FormControl('', Validators.required)
  });

  action2From = new FormGroup({
    label2: new FormControl('', Validators.required),
    label2Remove: new FormControl('', Validators.required)
  });

  permissionArray: any = [];

  
  data : any = JSON.parse(this.localStorageService.getItem('user'));

  arrayAction: any = [
    {
      "label": "Ajouter",
      "code": 30
    },
    {
      "label": "Lire",
      "code": 40
    },
    {
      "label": "Modifier",
      "code": 50
    },
    {
      "label": "Supprimer",
      "code": 60
    }
  ]
  selectType: any;

  constructor(private elementRef: ElementRef, private toastr: ToastrService, private profilService: ProfilService, private router: Router, 
    private route: ActivatedRoute,  private localStorageService: LocalStorageService) {
    this.options = this.toastr.toastrConfig;
    this.options.positionClass = 'toast-top-right';
    this.options.timeOut = 1500;
  }

  ngOnInit(): void {
    var f = document.createElement("script");
    f.type = "text/javascript";
    f.src = "../assets/js/form-select2.js";
    this.elementRef.nativeElement.appendChild(f);

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
    this.findAllPage();
  }

  get name() {
    return this.action1From?.get('name');
  }

  get type() {
    return this.action1From?.get('type');
  }

  get label1() {
    return this.action1From?.get('label1');
  }

  get labelRemove() {
    return this.action1From?.get('label');
  }

  get label2() {
    return this.action2From?.get('label2');
  }
  get label2Remove() {
    return this.action2From?.get('label2');
  }

  //Array Number 1

  onAdd() {
    this.value1 = this.action1From?.get('label1')?.value;

    if (this.value1 != "") {
      $('#exampleModal').modal('show');
      this.permissionArray = [];
      this.arrayAction2 = [];
    } else {
      $('#exampleModal').modal('hide');
      this.toastr.error('Error', 'Veuillez selectionner une page', this.options);
    }
  }

  onAddArray2() {
    var isExist = true;
    var ind = 0;
    if (this.array2.length > 0) {
      for (let index = 0; index < this.array2.length; index++) {
        if ("" + this.array2[index] === this.value1 + "") {
          isExist = false;
          ind = index;
        }
      }
    } else {
      isExist = true;
    }
    if (isExist) {
      this.array2.push(this.value1);
      this.dataMenu.push({ "page": this.value1, "permission": this.permissionArray });
    }
    $('#exampleModal').modal('hide');

    console.log(this.dataMenu);
    
  }

  onRemoveArray2() {
    this.value1 = this.action1From?.get('labelRemove')?.value;
    var isExist = false;
    if (this.dataMenu.length > 0) {
      for (let index = 0; index < this.dataMenu.length; index++) {
        if ("" + this.dataMenu[index].page === this.value1+ "") {
          isExist = true;
        }
      }
    }
    if (isExist) {
      this.dataMenu.forEach((item: any, index: any) => {
        if (item.page[0] === this.value1[0]) {
          this.dataMenu.splice(index, 1)
        }
      });
    }
  }

  //Array Number 2
  onAddAction2() {
    this.value2 = this.action2From?.get('label2')?.value;
    if (this.value2 != "") {
      this.alert2 = false;
      this.onAddArrayAction2(this.value2);
    } else {
      this.alert2 = true;
    }
  }


  onAddArrayAction2(element: any) {

    var convert = element?.toString();

    const convertToarray: any = convert?.split(',');

    var isExist2 = true;

    if (this.arrayAction2.length > 0) {
      for (let index = 0; index < this.arrayAction2.length; index++) {
        if ("" + this.arrayAction2[index] === convertToarray[0] + "") {
          isExist2 = false;
        }
      }
    } else {
      isExist2 = true;
    }
    if (isExist2) {
      this.arrayAction2.push(convertToarray[0]);
      this.permissionArray.push(convertToarray[1].toString())
    }

    this.permissionArray.filter((v: any, i: any, a: any) => a.indexOf(v) === i);

  }

  onRemoveArrayAction2() {

    this.value2 = this.action2From?.get('label2Remove')?.value;

    var convert = this.value2?.toString();

    var convertToarray: any = convert?.split(',');

    var isExist2 = false;

    if (this.arrayAction2.length > 0) {
      for (let index = 0; index < this.arrayAction2.length; index++) {
        if ("" + this.arrayAction2[index] === convertToarray[0] + "") {
          isExist2 = true;
        }
      }
    }
    if (isExist2) {

      this.removeItemOnce(this.arrayAction2, convertToarray[0]);
      this.removeItemOnce(this.permissionArray, convertToarray[1]);
    }

  }

  removeItemOnce(arr: any, value: any) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  findById(id: any) {
    this.profilService.findById(id).subscribe({
      next: (data) => {
        console.log(data);
        
        this.action1From.get('name')?.setValue(data.name);
        
        this.selectType = data.type;

        this.onPageUser(data.menus);
      },
      error: (err) => {
        console.log(err);
      },

    })
  }


  onPageUser(page: any) {
    var pagesUser: any[] = [];
    var permissionUser: any[] = [];
    page.forEach((item: any) => {
      if (item.sousmenus == null || item.sousmenus == "") {
        var pagesUser: any[] = [];
        var permissionUser: any[] = [];
        pagesUser.push(item.label);
        permissionUser.push(item.permission)
        this.dataMenu.push({ "page": pagesUser, "permission": permissionUser })
      } else {
        item.sousmenus.forEach((sous: any) => {
          var pagesUser: any[] = [];
          var permissionUser: any[] = [];
          pagesUser.push(sous.label);
          permissionUser.push(sous.permission)
          this.dataMenu.push({ "page": pagesUser, "permission": permissionUser })
        })
      }
    });
  }

  findAllPage() {
    this.profilService.findAllPage().subscribe({
      next: (data) => {
        const response: DatumPage[] = data;
        this.dataPageValue = response;
        this.dataPageValue.forEach((item: any) => {
          if (item.sousPages == null || item.sousPages == "") {
            this.arrayMenu.push({ "id": item.id, "label": item.label, "icon": item.icon, "lien": item.lien })
          } else {
            item.sousPages.forEach((sous: any) => {
              this.arrayMenu.push({ "id": item.id, "label": sous.label, "icon": sous.icon, "lien": sous.lien })
            })
          }
        });
      }
    })
  }

  onUpdate() {
    this.isLoading = true;
    for (let index = 0; index < this.dataPageValue.length; index++) {
      this.dataMenu.forEach((item: any) => {
        item.page.forEach((page: any) => {
          if ("" + this.dataPageValue[index].label == "" + page && (this.dataPageValue[index].sousPages?.length == 0 || this.dataPageValue[index].sousPages?.length == null)) {
            this.profilPayload.menu.push({ "label": page, "icon": this.dataPageValue[index].icon, "permission": item.permission.toString(), "lien": this.dataPageValue[index].lien, "sousMenu": null });

          } else if ("" + this.dataPageValue[index].label == "" + page) {

            this.dataPageValue[index].sousPages?.forEach((sous: any) => {
              this.tab.push({ "label": page, "icon": this.dataPageValue[index].icon, "permission": item.permission.toString(), "lien": sous.lien })
            });
            this.profilPayload.menu.push({ "label": page, "icon": this.dataPageValue[index].icon, "permission": null, "lien": null, "sousMenu": this.tab })
            this.tab = [];
          }
        });
      });
    }
    this.tab = [];

    this.profilPayload.name = this.action1From.get('name')?.value;
    this.profilPayload.type = this.action1From.get('type')?.value;


    this.profilCreate.name = this.profilPayload.name;
    this.profilCreate.type = this.profilPayload.type;
    this.profilCreate.menu = this.profilPayload.menu;

    console.log(this.profilCreate);
    

    this.profilService.update(this.profilCreate, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Profil modifié avec succès;";
        this.isLoading = false;
        this.paramId = data.id;
        this.findById(this.paramId);
        this.findAllPage();
      },
      error: (err) => {
        console.log(err);

        this.hasSuccess = false;
        if (err.status == 422) {
          this.errorMessage = "Données invalides.Il semble que le profil existe déjà.";
          this.hasError = true;
          this.isLoading = false;
        }
        this.isLoading = false;
      },
    })
  }

}
