import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActiviteContribuablePayload, ContribuablePayload } from '../contribuable/models/contribuable-payload';
import { Datum as DatumActivite } from '../activite/interfaces/activite-response';
import { ContribuableService } from '../contribuable/services/contribuable.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ActiviteService } from '../activite/services/activite.service';
import { LocalPayload } from '../contribuable/models/local-payload';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

@Component({
  selector: 'app-enrolement',
  templateUrl: './enrolement.component.html',
  styleUrls: ['./enrolement.component.css']
})
export class EnrolementComponent implements OnInit {

  dataTableActiviteValue: DatumActivite[] = [];

  contribuablePayload = new ContribuablePayload();

  activiteContribuablePayload = new ActiviteContribuablePayload();


  typePieceList: any = [
    { "libelle": "Attestion d'identité" },
    { "libelle": "Carte nationale d'identité" },
    { "libelle": "Passeport" },
    { "libelle": "Carte de séjour" }
  ]


  infoContribuableForm = new FormGroup({
    nom: new FormControl('', Validators.required),
    prenom: new FormControl('', Validators.required),
    telephone: new FormControl('', Validators.required),
    contact: new FormControl(''),
    email: new FormControl(''),
    typePiece: new FormControl('', Validators.required),
    numeroPiece: new FormControl('', Validators.required),
    adresse: new FormControl('', Validators.required),
    nationalite: new FormControl('', Validators.required),
    ville: new FormControl('', Validators.required),
  })

  activitecontribuableForm = new FormGroup({
    numeroImmatriculation: new FormControl('', Validators.required),
    numeroRegistreCommerce: new FormControl(''),
    dateCreationEntreprise: new FormControl('', Validators.required),
    dateImmatriculation: new FormControl('', Validators.required),
    idActivite: new FormControl('', Validators.required),

  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  minNumeroPiece: any;
  maxNumeroPiece: any;

  dataVille: any;

  textPiece: any;

  conbool: boolean = false;

  data: any = JSON.parse(this.localStorageService.getItem('user'));

  today: any;

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, private activiteService: ActiviteService,
    private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) {
    this.findAllActivite();
    this.onGetVille();
  }

  ngOnInit(): void {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/form-step.js";
    this.elementRef.nativeElement.appendChild(s);

    this.FcurrentDate();

    this.infoContribuableForm.get('typePiece')?.valueChanges.subscribe(x => {
      this.textPiece = this.infoContribuableForm.get('typePiece')?.value;
      if (this.textPiece == "Carte nationale d'identité") {
        this.infoContribuableForm.get('numeroPiece')?.setValue("CI");
        this.maxNumeroPiece = 11;
        this.minNumeroPiece = 11;
      }
    });
    this.infoContribuableForm.get('contact')?.valueChanges.subscribe(x => {
      var cont = this.infoContribuableForm.get('contact')?.value;
      var _lcont = cont?.length;
      this.conbool = false;
      if (_lcont != 10) {
        this.conbool = true;
      }
    });
    /* this.activitecontribuableForm.get('dateImmatriculation')?.valueChanges.subscribe(x => {
      var dI : any = this.activitecontribuableForm.get('dateImmatriculation')?.value;
      var dc :any = this.activitecontribuableForm.get('dateCreationEntreprise')?.value;
      
      var dateImm = new Date(dI);
      var dateCrea = new Date(dc);

      if(dateImm >= dateCrea){
        this.activitecontribuableForm.get;
      }

    }) */
  }

  FcurrentDate() {

    var current = new Date();
    var dd : any = current.getDate();
    var mm : any = current.getMonth() + 1;
    var yyyy : any = current.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    this.today = yyyy + '-' + mm + '-' + dd;
  }

  get nom() {
    return this.infoContribuableForm?.get('nom');
  }
  get prenom() {
    return this.infoContribuableForm?.get('prenom');
  }
  get telephone() {
    return this.infoContribuableForm?.get('telephone');
  }
  get contact() {
    return this.infoContribuableForm?.get('contact');
  }
  get email() {
    return this.infoContribuableForm?.get('email');
  }
  get typePiece() {
    return this.infoContribuableForm?.get('typePiece');
  }
  get numeroPiece() {
    return this.infoContribuableForm?.get('numeroPiece');
  }
  get nationalite() {
    return this.infoContribuableForm?.get('nationalite');
  }
  get adresse() {
    return this.infoContribuableForm?.get('adresse');
  }
  get ville() {
    return this.infoContribuableForm?.get('ville');
  }
  /* get quatierActivite() {
    return this.activitecontribuableForm?.get('quatierActivite');
  } */
  get numeroImmatriculation() {
    return this.activitecontribuableForm?.get('numeroImmatriculation');
  }
  get numeroRegistreCommerce() {
    return this.activitecontribuableForm?.get('numeroRegistreCommerce');
  }
  get idActivite() {
    return this.activitecontribuableForm?.get('idActivite');
  }
  get dateCreationEntreprise() {
    return this.activitecontribuableForm?.get('dateCreationEntreprise');
  }
  get dateImmatriculation() {
    return this.activitecontribuableForm?.get('dateImmatriculation');
  }

  findAllActivite() {
    this.activiteService.findAll().subscribe({
      next: (data) => {
        const response: DatumActivite[] = data;
        this.dataTableActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onSubmit() {
    this.isLoading = true;
    this.contribuablePayload.nom = this.infoContribuableForm.get('nom')?.value;
    this.contribuablePayload.prenom = this.infoContribuableForm.get('prenom')?.value;
    this.contribuablePayload.telephone = this.infoContribuableForm.get('telephone')?.value;
    this.contribuablePayload.contact = this.infoContribuableForm.get('contact')?.value;
    this.contribuablePayload.email = this.infoContribuableForm.get('email')?.value;
    this.contribuablePayload.typePiece = this.infoContribuableForm.get('typePiece')?.value;
    this.contribuablePayload.numeroPiece = this.infoContribuableForm.get('numeroPiece')?.value;
    this.contribuablePayload.adresse = this.infoContribuableForm.get('adresse')?.value;
    this.contribuablePayload.nationalite = this.infoContribuableForm.get('nationalite')?.value;
    this.contribuablePayload.ville = this.infoContribuableForm.get('ville')?.value;
    this.contribuablePayload.etat = 200;

    //this.activiteContribuablePayload.quatier = this.activitecontribuableForm.get('quatierActivite')?.value;

    this.activiteContribuablePayload.numeroImmatriculation = this.activitecontribuableForm.get('numeroImmatriculation')?.value;
    this.activiteContribuablePayload.numeroRegistreCommerce = this.activitecontribuableForm.get('numeroRegistreCommerce')?.value;
    this.activiteContribuablePayload.idActivite = this.activitecontribuableForm.get('idActivite')?.value;
    this.activiteContribuablePayload.dateCreationEntreprise = this.activitecontribuableForm.get('dateCreationEntreprise')?.value;
    this.activiteContribuablePayload.dateImmatriculation = this.activitecontribuableForm.get('dateImmatriculation')?.value;

    this.contribuablePayload.creerPar = this.data.id;
    this.contribuablePayload.modifierPar = null;

    this.contribuablePayload.detailRequest = this.activiteContribuablePayload;

    this.contribuableService.save(this.contribuablePayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Contribuable ajouté avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.infoContribuableForm.reset();
        this.activitecontribuableForm.reset();
        this.router.navigate(['contribuable']);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })

  }


  onGetVille() {
    this.contribuableService.getVille().subscribe({
      next: (data) => {
        this.dataVille = data;
      }
    })
  }

}
