<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="col col-md-6 mx-auto alert alert-success" role="alert" *ngIf="hasSuccess">
                    {{successMessage}}
                </div>
                <div class="col col-md-6 mx-auto alert alert-danger" role="alert" *ngIf="hasError">
                    {{errorMessage}}
                </div>
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">Paramètres</h5>
                </div>
                <div class="table-responsive mt-3">
                    <table class="table table-bordered mb-0">
                        <thead class="table-secondary" class="table-primary">
                            <tr>
                                <th>Nom </th>
                                <th>libelle</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>GENERER TAXE</td>
                                <td>GENERER TAXE</td>
                                <td>
                                    <button *ngIf="!isLoadingGenerate" (click)="onGenerateTaxe()" type="button"
                                        class="btn btn-primary btn-sm"><i class="bi bi-bookmark-plus"></i> Génerer
                                        taxes</button>
                                    <button *ngIf="isLoadingGenerate" class="btn btn-primary btn-sm" type="button"
                                        disabled=""><span class="spinner-grow spinner-grow-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...</button>
                                </td>
                            </tr>
                            <tr *ngFor="let data of table">
                                <td> {{data.nom}} </td>
                                <td> {{data.libelle}} </td>
                                <td>
                                    <button *ngIf="!isLoading" (click)="onActive(data)"
                                        class="btn btn-success btn-sm m-1"
                                        [disabled]="data.valeur == '1'">Activer</button>
                                    <button *ngIf="isLoading" class="btn btn-success btn-sm" type="button"
                                        disabled=""><span class="spinner-grow spinner-grow-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...</button>
                                    <button *ngIf="!isLoadingDesac" (click)="onDesactive(data)"
                                        class="btn btn-danger btn-sm"
                                        [disabled]="data.valeur != '1'">Désactiver</button>
                                    <button *ngIf="isLoadingDesac" class="btn btn-danger btn-sm" type="button"
                                        disabled=""><span class="spinner-grow spinner-grow-sm" role="status"
                                            aria-hidden="true"></span>
                                        Loading...</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>