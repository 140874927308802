<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Taxes</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Taxes</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">Taxes</h5>
                    <div class="ms-auto position-relative">
                        <a (click)="ngRedirectImport()" class="btn btn-success btn-sm m-2"><i class="bi bi-cloud-arrow-down"></i> Importation de fichier</a>
                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><i class="bi bi-bookmark-plus"></i> Ajouter</button>
                    </div>
                </div>
                <div class="table-responsive mt-3">
                    <table id="example2" class="table align-middle">
                        <thead class="table-secondary" class="table-primary">
                            <tr>
                                <th>Nom de la catégorie</th>
                                <th>Activité</th>
                                <th>Coût</th>
                                <th>Pénalité</th>
                                <th>Fréquence</th>
                                <th>Date échéance</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<form class="" novalidate [formGroup]="taxeForm" (ngSubmit)="onSubmit()">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ajouter une taxe</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                        {{successMessage}}
                    </div>
                    <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                        {{errorMessage}}
                    </div>
                    <div class="row">
                        <div class="col-6 mb-2">
                            <label class="form-label">Coût</label>
                            <input style="height: 35px;" type="text" class="form-control" formControlName="cout"
                                placeholder="Entrer un coût">
                            <div class="ms-3" *ngIf="!cout?.valid && (cout?.dirty ||cout?.touched)">
                                <div class="text-danger">Le champs coût est requis</div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="form-label">Pénalité</label>
                            <input style="height: 35px;" type="text" class="form-control" formControlName="penalite">
                            <div class="ms-3" *ngIf="!penalite?.valid && (penalite?.dirty ||penalite?.touched)">
                                <div class="text-danger">Le champs penalite est requis</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-2">
                            <label class="form-label">Fréquence</label>
                            <select style="height: 35px;" class="form-control" formControlName="frequence">
                                <option *ngFor="let frequence of dataFrequence" value="{{frequence.valeur}}">{{frequence.titre}}</option>
                            </select>
                            <div class="col-6 ms-3"
                                *ngIf="!frequence?.valid && (frequence?.dirty ||frequence?.touched)">
                                <div class="text-danger">Le champs fréquence est requis</div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="form-label" for="form3Example1c">Date limite</label>
                            <input type="date" style="height: 35px;" class="form-control"
                                formControlName="echeance">
                            <div class="col-6 ms-3"
                                *ngIf="!echeance?.valid && (echeance?.dirty ||echeance?.touched)">
                                <div class="text-danger">Le champs date limite est requis</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-2">
                            <label class="form-label" for="form3Example1c">Selectionnez une catégorie</label>
                            <select style="height: 35px;" class="form-control" formControlName="idCategorie">
                                <option *ngFor="let categorie of dataTableCategorieValue" value="{{categorie.id}}">{{categorie.libelle}}</option>
                            </select>
                            <div class="ms-3"
                                *ngIf="!idCategorie?.valid && (idCategorie?.dirty ||idCategorie?.touched)">
                                <div class="text-danger">Le champ catégorie est requis</div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <label class="form-label" for="form3Example1c">Selectionnez une activité</label>
                            <select style="height: 35px;" class="form-control" formControlName="idActivite">
                                <option *ngFor="let activite of dataTableActiviteValue" value="{{activite.id}}">
                                    {{activite.libelle}}</option>
                            </select>
                            <div class="col-6 ms-3" *ngIf="!idActivite?.valid && (idActivite?.dirty ||idActivite?.touched)">
                                <div class="text-danger">Le champ activité est requis</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i
                            class="bi bi-x-lg"></i> Annuler</button>
                    <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-sm"
                        [disabled]="!taxeForm.valid"><i class="bi bi-plus-circle"></i>
                        Valider</button>
                    <button *ngIf="isLoading" class="btn btn-primary" type="button" disabled=""><span
                            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                </div>
            </div>
        </div>
    </div>
</form>