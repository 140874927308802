import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { debug } from 'src/app/utility/module';
import { environment } from 'src/environments/environment.prod';
import { Auth } from '../models/auth';
import { Forgot } from '../models/forgot';
import { Otp } from '../models/otp';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
  constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }
  

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Accept-language': 'fr'})
    }
    return httpOption;
  }
   login(payload: Auth):Observable<any> {
     return this.http.post<Auth>(`${this.ipAdress}/${this.BaseUrl}users/login`, payload, this.onHeader());
   } 

   otp(payload: Otp):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}users/otp/match`, payload, this.onHeader());
  } 

  Actualiserotp(payload: Otp):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}users/otp`, payload, this.onHeader());
  } 
 
   reset(payload: Forgot):Observable<any> {
     return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}users/password/reset`, payload, this.onHeader());
   }
 
}
