import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Datum } from '../../interfaces/secteur-activite-response';
import { SecteurActivitePayload } from '../../models/secteur-activite-payload';
import { SecteurActiviteService } from '../../services/secteur-activite.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  secteurActivitePayload = new SecteurActivitePayload();

  dataTableSecteurActiviteValue: Datum[] = [];

  secteurActiviteForm = new FormGroup({
    libelle: new FormControl('', Validators.required)
  });

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  paramId: any;

  constructor(private secteurActiviteService: SecteurActiviteService, private router: Router, private route: ActivatedRoute, 
   private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
  }

  get libelle() {
    return this.secteurActiviteForm?.get('libelle');
  }

  findById(id : any){
    this.secteurActiviteService.findById(id).subscribe({
      next: (data) => {
        this.dataTableSecteurActiviteValue = data;
        console.log(data);
        this.secteurActiviteForm.get('libelle')?.setValue(data.libelle);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onUpdate(){
    this.isLoading = true;
    var libUpdate = this.secteurActiviteForm.get('libelle')?.value;
    this.secteurActivitePayload.libelle = libUpdate?.toUpperCase();
    this.secteurActivitePayload.creerPar = null;
    this.secteurActivitePayload.modifierPar = this.data.id;
    
    this.secteurActiviteService.update(this.secteurActivitePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Secteur d'activité modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

}
