<div class="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
  <div class="col">
    <div class="card overflow-hidden rounded-4">
      <div class="card-body p-2">
        <div class="d-flex align-items-stretch rounded-4 justify-content-between overflow-hidden bg-pink">
          <div class="w-50 p-3">
            <p class="text-white">Contribuables</p>
            <h4 class="text-white" id="valueContribuable"></h4>
          </div>
          <div class="w-50">
            <div id="chart1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card overflow-hidden rounded-4">
      <div class="card-body p-2">
        <div class="d-flex align-items-stretch justify-content-between overflow-hidden rounded-4 bg-purple">
          <div class="w-50 p-3">
            <p class="text-white">Impayées</p>
            <h4 class="text-white" id="valueImpaye"></h4>
          </div>
          <div class="w-50">
            <div id="chart2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card overflow-hidden rounded-4">
      <div class="card-body p-2">
        <div class="d-flex align-items-stretch justify-content-between overflow-hidden rounded-4 bg-success">
          <div class="w-50 p-3">
            <p class="text-white">Taxes payés</p>
            <h4 class="text-white" id="valuePaye"></h4>
          </div>
          <div class="w-50">
            <div id="chart3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card overflow-hidden rounded-4">
      <div class="card-body p-2">
        <div class="d-flex align-items-stretch justify-content-between overflow-hidden rounded-4 bg-orange">
          <div class="w-50 p-3">
            <p class="text-white">Locaux</p>
            <h4 class="text-white" id="valueLocal"></h4>
          </div>
          <div class="w-50">
            <div id="chart4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end row-->

<div class="row">
  <div class="col-12 col-lg-4 d-flex">
    <div class="card radius-10 w-100">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <h6 class="mb-0">Paiements par mois</h6>
          <div class="fs-5 ms-auto dropdown">
            <div class="dropdown-toggle dropdown-toggle-nocaret cursor-pointer">
              <select name="annee" id="annee" class="form-control">
                <option *ngFor="let y of year" value="{{y}}" [selected]="selectYear == y">{{y}}</option>
              </select>
            </div>
          </div>
        </div>
        <div id="chart5"></div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4 d-flex">
    <div class="card radius-10 w-100">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <h6 class="mb-0">Volumes de Paiements</h6>
        </div>
        <div class="row row-cols-1 row-cols-md-2 g-3 mt-2 align-items-center">
          <div class="col-lg-7 col-xl-7 col-xxl-8">
            <div class="by-device-container">
              <div class="piechart-legend">
                <h2 class="mb-1" id="per"></h2>
                <h6 class="mb-0" id="libelle"></h6>
              </div>
              <canvas id="chart6"></canvas>
            </div>
          </div>
          <div class="col-lg-5 col-xl-5 col-xxl-4">
            <div class="" id="libelleVolume">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4 d-flex">
    <div class="card radius-10 w-100">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <h6 class="mb-0">Paiement Journalier</h6>
          <div class="fs-5 ms-auto dropdown">
            <div class="row">
              <div class="col">
                <select name="week" id="week" class="form-control">
                  <option *ngFor="let w of WeekArry" value="{{w}}">{{w + "sem"}}</option>
                </select>
              </div>
              <div class="col">
                <select name="annee" id="annee2" class="form-control">
                  <option *ngFor="let y of year" value="{{y}}" [selected]="selectYear == y">{{y}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div id="chart11" class=""></div>
      </div>
    </div>
  </div>
</div>
<!--end row-->


<!-- <div class="row">
  <div class="col-12 col-lg-6 col-xl-4 d-flex">
    <div class="card radius-10 w-100">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <h6 class="mb-0">Traffic Source</h6>
          <div class="fs-5 ms-auto dropdown">
            <div class="dropdown-toggle dropdown-toggle-nocaret cursor-pointer" data-bs-toggle="dropdown"><i
                class="bi bi-three-dots"></i></div>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </div>
        </div>
        <div id="chart7" class=""></div>
        <div class="traffic-widget">
          <div class="progress-wrapper mb-3">
            <p class="mb-1">Social <span class="float-end">8,475</span></p>
            <div class="progress rounded-0" style="height: 8px;">
              <div class="progress-bar bg-primary" role="progressbar" style="width: 80%;"></div>
            </div>
          </div>
          <div class="progress-wrapper mb-3">
            <p class="mb-1">Direct <span class="float-end">7,989</span></p>
            <div class="progress rounded-0" style="height: 8px;">
              <div class="progress-bar bg-primary" role="progressbar" style="width: 65%;"></div>
            </div>
          </div>
          <div class="progress-wrapper mb-0">
            <p class="mb-1">Search <span class="float-end">6,359</span></p>
            <div class="progress rounded-0" style="height: 8px;">
              <div class="progress-bar bg-primary" role="progressbar" style="width: 50%;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 col-xl-4 d-flex">
    <div class="card radius-10 w-100">
      <div class="card-body">
        <div class="card radius-10 border shadow-none mb-3">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">Messages</p>
                <h4 class="mb-0 text-primary">289</h4>
              </div>
              <div class="dropdown ms-auto">
                <div class="dropdown-toggle dropdown-toggle-nocaret cursor-pointer" data-bs-toggle="dropdown"><i
                    class="bi bi-three-dots fs-4"></i>
                </div>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="javascript:;">Action</a>
                  </li>
                  <li><a class="dropdown-item" href="javascript:;">Another action</a>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="javascript:;">Something else here</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="chart8"></div>
          </div>
        </div>
        <div class="card radius-10 border shadow-none mb-3">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">Total Posts</p>
                <h4 class="mb-0 text-primary">489</h4>
              </div>
              <div class="dropdown ms-auto">
                <div class="dropdown-toggle dropdown-toggle-nocaret cursor-pointer" data-bs-toggle="dropdown"><i
                    class="bi bi-three-dots fs-4"></i>
                </div>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="javascript:;">Action</a>
                  </li>
                  <li><a class="dropdown-item" href="javascript:;">Another action</a>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="javascript:;">Something else here</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="chart9"></div>
          </div>
        </div>
        <div class="card radius-10 border shadow-none mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">New Tasks</p>
                <h4 class="mb-0 text-primary">149</h4>
              </div>
              <div class="dropdown ms-auto">
                <div class="dropdown-toggle dropdown-toggle-nocaret cursor-pointer" data-bs-toggle="dropdown"><i
                    class="bi bi-three-dots fs-4"></i>
                </div>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="javascript:;">Action</a>
                  </li>
                  <li><a class="dropdown-item" href="javascript:;">Another action</a>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="javascript:;">Something else here</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="chart10"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div> -->
<!--end row-->