<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
  <div class="breadcrumb-title pe-3">Contribuables</div>
  <div class="ps-3">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Contribuables</li>
          </ol>
      </nav>
  </div>
</div>
<div class="row">
  <div class="col col-md-12 col-lg-12 mx-auto">
    <div class="card radius-10">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <h5 class="mb-0">Contribuables</h5>
          <div class="ms-auto position-relative">
            <a href="/contribuable/enrolement" class="btn btn-primary btn-sm"><i class="bi bi-bookmark-plus"></i> Ajouter</a>
        </div>
        </div>
        <div class="table-responsive mt-3">
          <table id="example2" class="table align-middle">
            <thead class="table-secondary" class="table-primary">
              <tr>
                <th>Nom & prénoms</th>
                <th>Téléphone</th>
                <th>Ville</th>
                <th>adresse</th>
                <th class="text-center" style="width: 100px;">Actions</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>