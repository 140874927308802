import { isDevMode } from "@angular/core";

export class Config
{
  constructor() {  }
}

export function debug(string:any) {
  
  if(isDevMode()) {
    console.log('**************************************************');
    console.log(`DEBUG: ${JSON.stringify(string)}`); 
    console.log('**************************************************');}
}