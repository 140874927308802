import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
    constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }

  findStat():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}statistiques`, this.onHeader());
  }
}
