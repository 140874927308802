<!--start sidebar -->
<aside class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
        <div>
            <img src="assets/images/logo-icon.png" class="logo-icon" alt="logo icon">
        </div>
        <div>
            <h4 class="logo-text" style="color: #7CA1C4;">DigiTax</h4>
        </div>
        <div class="toggle-icon ms-auto"> <i class="bi bi-list"></i>
        </div>
    </div>
    <!--navigation-->
    <ul class="metismenu" id="menu">
        <li>
            <a href="/dashboard">
                <div class="parent-icon"><i class="bi bi-house-fill"></i>
                </div>
                <div class="menu-title">Tableau de bord</div>
            </a>
        </li>
        <li class="menu-label">Menus</li>
        <li *ngFor="let page of dataProfileValue">
            <a href="javascript:;" class="has-arrow">
                <div class="parent-icon"><i class="{{page.icon}}"></i>
                </div>
                <div class="menu-title">{{page.label}}</div>
            </a>
            <ul>
                <li *ngFor="let sous of page.sousmenus"> <a routerLink="/{{sous.lien}}"><i
                            class="{{sous.icon}}"></i>{{sous.label}}</a>
                </li>
            </ul>
        </li>
    </ul>
    <!--end navigation-->
</aside>""
<!--end sidebar -->