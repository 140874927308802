import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ActiviteService } from 'src/app/pages/activite/services/activite.service';
import { ActiviteContribuablePayload } from '../../models/contribuable-payload';
import { ContribuableService } from '../../services/contribuable.service';
import { Datum, Datum as DatumActivite } from '../../../activite/interfaces/activite-response';
import { DatePipe, formatDate } from '@angular/common';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';


@Component({
  selector: 'app-edit-activite-contribuable',
  templateUrl: './edit-activite-contribuable.component.html',
  styleUrls: ['./edit-activite-contribuable.component.css']
})
export class EditActiviteContribuableComponent implements OnInit {

  dataTableActiviteValue : DatumActivite[] = [];

  paramId: any;

  contribuableId: any;

  Activite: any;

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  
  activiteContribuablePayload = new ActiviteContribuablePayload();

  activiteContribuableForm = new FormGroup({
    numeroImmatriculation: new FormControl('', Validators.required),
    numeroRegistreCommerce: new FormControl(''),
    idActivite: new FormControl('', Validators.required),
    dateCreationEntreprise: new FormControl('', Validators.required),
    dateImmatriculation: new FormControl('', Validators.required)
  });

  IdContribuable: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private activiteService: ActiviteService, private contribuableService: ContribuableService, 
    private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
    this.findAllActivite();
  }


  //Activite Contribuable
  get numeroImmatriculation() {
    return this.activiteContribuableForm?.get('numeroImmatriculation');
  }
  get numeroRegistreCommerce() {
    return this.activiteContribuableForm?.get('numeroRegistreCommerce');
  }
  get idActivite() {
    return this.activiteContribuableForm?.get('idActivite');
  }
  get dateCreationEntreprise() {
    return this.activiteContribuableForm?.get('dateCreationEntreprise');
  }
  get dateImmatriculation() {
    return this.activiteContribuableForm?.get('dateImmatriculation');
  }

  findAllActivite() {
    this.activiteService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findById(id: any) {
    this.contribuableService.findActiviteContribuableById(id).subscribe({
      next: (data) => {
        this.activiteContribuableForm.get('numeroImmatriculation')?.setValue(data.numeroImatriculation);
        this.activiteContribuableForm.get('numeroRegistreCommerce')?.setValue(data.numeroRegistreCommerce);
        var dateCreationEntreprise = new DatePipe('en-US');
        this.activiteContribuableForm.get('dateCreationEntreprise')?.setValue(dateCreationEntreprise.transform(data.dateCreationEntreprise, 'YYYY-MM-dd'));
        var dateImmatriculation = new DatePipe('en-US');
        this.activiteContribuableForm.get('dateImmatriculation')?.setValue(dateImmatriculation.transform(data.dateImmatriculation, 'YYYY-MM-dd'));
        this.Activite = data.activite.id;
        this.IdContribuable =  this.localStorageService.getItem('idContribuable');

      },
      error: (err) => {
        console.log(err);
      },
    })
  }


  onUpdate(){
    this.isLoading = true;
    this.activiteContribuablePayload.numeroImmatriculation = this.activiteContribuableForm.get('numeroImmatriculation')?.value;
    this.activiteContribuablePayload.numeroRegistreCommerce = this.activiteContribuableForm.get('numeroRegistreCommerce')?.value;
    this.activiteContribuablePayload.idActivite = this.activiteContribuableForm.get('idActivite')?.value;
    this.activiteContribuablePayload.dateCreationEntreprise = this.activiteContribuableForm.get('dateCreationEntreprise')?.value;
    this.activiteContribuablePayload.dateImmatriculation = this.activiteContribuableForm.get('dateImmatriculation')?.value;
    this.activiteContribuablePayload.idContribuable = this.contribuableId;
    this.activiteContribuablePayload.creerPar = null;
    this.activiteContribuablePayload.modifierPar = this.data.id;

    this.contribuableService.updateActiviteContribuable(this.activiteContribuablePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Activité contribuable modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })

  }

}
