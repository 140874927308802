import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './pages/layouts/footer/footer.component';
import { HeaderComponent } from './pages/layouts/header/header.component';
import { SidebarComponent } from './pages/layouts/sidebar/sidebar.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthComponent } from './pages/auth/login/auth.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { EnrolementComponent } from './pages/enrolement/enrolement.component';
import { ContribuableComponent } from './pages/contribuable/contribuable.component';
import { SecteurActiviteComponent } from './pages/secteur-activite/secteur-activite.component';
import { ActiviteComponent } from './pages/activite/activite.component';
import { TaxeComponent } from './pages/taxe/taxe.component';
import { PaiementComponent } from './pages/paiement/paiement.component';
import { ReglerComponent } from './pages/paiement/regler/regler.component';
import { ParametreComponent } from './pages/parametre/parametre.component';
import { ReclammationComponent } from './pages/reclammation/reclammation.component';
import { UtilisateurComponent } from './pages/utilisateur/utilisateur.component';
import { AuditComponent } from './pages/audit/audit.component';
import { ProfilComponent } from './pages/profil/profil.component';
import { CategorieTaxeComponent } from './pages/categorie-taxe/categorie-taxe.component';
import { UpdateComponent } from './pages/profil/component/update/update.component';
import { UpdateComponent as UpdateComponentUtilisateur } from './pages/utilisateur/component/update/update.component';
import { UpdateComponent as UpdateComponentSecteurActivite } from './pages/secteur-activite/component/update/update.component';
import { UpdateComponent as UpdateComponentActivite } from './pages/activite/component/update/update.component';
import { UpdateComponent as UpdateComponentCategorie } from './pages/categorie-taxe/component/update/update.component';
import { UpdateComponent as UpdateComponentTaxe } from './pages/taxe/component/update/update.component';
import { ViewComponent } from './pages/contribuable/component/view/view.component';
import { ViewLocalComponent } from './pages/contribuable/component/view-local/view-local.component';
import { EditActiviteContribuableComponent } from './pages/contribuable/component/edit-activite-contribuable/edit-activite-contribuable.component';
import { EditLocalContribuableComponent } from './pages/contribuable/component/edit-local-contribuable/edit-local-contribuable.component';
import { LocalContribuableComponent } from './pages/paiement/components/local-contribuable/local-contribuable.component';
import { TaxeContribuableComponent } from './pages/paiement/components/taxe-contribuable/taxe-contribuable.component';
import { ActiviteContribuableComponent } from './pages/paiement/components/activite-contribuable/activite-contribuable.component';
import { CronEditorModule } from 'ngx-cron-editor';
import { AuthGuard } from './guard/auth-guard.guard';
import { ViewTaxeComponent } from './pages/contribuable/component/view-taxe/view-taxe.component';
import { EditContribuableComponent } from './pages/contribuable/component/edit-contribuable/edit-contribuable.component';
import { ImportationCsvComponent } from './pages/importation-csv/importation-csv.component';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra)



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    SidebarComponent,
    HeaderComponent,
    DashboardComponent,
    AuthComponent,
    ForgotComponent,
    EnrolementComponent,
    ContribuableComponent,
    SecteurActiviteComponent,
    ActiviteComponent,
    TaxeComponent,
    PaiementComponent,
    ReglerComponent,
    ParametreComponent,
    ReclammationComponent,
    UtilisateurComponent,
    AuditComponent,
    ProfilComponent,
    CategorieTaxeComponent,
    UpdateComponent,
    UpdateComponentUtilisateur,
    UpdateComponentSecteurActivite,
    UpdateComponentActivite,
    UpdateComponentCategorie,
    UpdateComponentTaxe,
    ViewComponent,
    ViewLocalComponent,
    EditActiviteContribuableComponent,
    EditLocalContribuableComponent,
    LocalContribuableComponent,
    TaxeContribuableComponent,
    ActiviteContribuableComponent,
    ViewTaxeComponent,
    EditContribuableComponent,
    ImportationCsvComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CronEditorModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AuthGuard, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
