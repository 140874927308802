<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Contribuables</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable">Contribuables</a></li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable/viewcontribuable/{{IdContribuable}}">Detail contribuable</a></li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable/viewlocal/{{IdLocal}}">Locaux contribuables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Taxes contribuables</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">Taxes contribuables</h5>
                </div>
                <div class="table-responsive mt-3">
                  <table id="example2" class="table align-middle">
                    <thead class="table-secondary">
                      <tr>
                        <th>Activité</th>
                        <th>Catégorie</th>
                        <th>Coût</th>
                        <th>Penalité</th>
                        <th>Date d'échéance</th>
                        <th>Fréquence</th>
                      </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>
    </div>
</div>