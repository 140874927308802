import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContribuableService } from 'src/app/pages/contribuable/services/contribuable.service';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'app-local-contribuable',
  templateUrl: './local-contribuable.component.html',
  styleUrls: ['./local-contribuable.component.css']
})
export class LocalContribuableComponent implements OnInit {

  ipAdress: string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;

  tenant: any  = this.localStorageService.getItem('tenantId');

  url: any = `${this.ipAdress}/${this.BaseUrl}locals/image/`;

  paramId : any;
  
  table: any;

  ActiviteId : any;

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.localStorageService.setItem('localId', this.paramId);
    this.findById(this.paramId);
  }

  onDataTable(data: any) {
    const that = this;
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<img class="product-img-2" src="${that.url}${row.id}?tenant=${that.tenant}">
            `)
          }
        },
        { data: 'nom' },
        { data: 'ilot' },
        { data: 'lot' },
        { data: 'longitude' },
        { data: 'latitude' },
        { data: 'pointRepere' },
        { data: 'quartier' },
        { data: 'lien_localisation' },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<a class="btn btn-warning btn-sm edit" style="cursor:pointer; color: white; background-color: #7CA1C4 !important;border-color: #7CA1C4 !important;" id="${row.id}">Taxes</a>`);
          }
        }

      ],

    });
    this.routeEdit();
  }


  routeEdit() {
    const that = this;
    $('.edit').on('click', function (e: any) {
      var id = e.delegateTarget.id
      that.router.navigateByUrl('paiement/contribuable/local/taxe/' + id);
    });
  }

  findById(id: any) {
    this.contribuableService.findByInfoLocal(id).subscribe({
      next: (data) => {
        this.onDataTable(data);
        this.ActiviteId = this.localStorageService.getItem('activiteId');
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
