import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { SearchContribuablePayload } from '../models/search-contribuable-payload';
import { TransactionPayload } from '../models/transaction-payload';

@Injectable({
  providedIn: 'root'
})
export class PaiementService {

  ipAdress : string = `${environment.IpAdress}`;
  //ipAdress2 : string = `${environment.IpAdress2}`;

  BaseUrl: string = `${environment.BaseUrl}`;
    constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }

  sendMobile(data : TransactionPayload):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}paiements/guichet`, data, this.onHeader());
  }

  sendEspece(data : TransactionPayload):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}paiements/espece`, data, this.onHeader());
  }

  getStatus(reference : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}paiements/status/${reference}`, this.onHeader());
  }

  findByTelephone(search : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}contribuables/search/${search}`, this.onHeader());
  }

  findTaxeContribuableById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}locals/taxes/impayes/${id}`, this.onHeader());
  }

  findDetailTaxeContribuableById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}locals/taxes/details/${id}`, this.onHeader());
  }
}
