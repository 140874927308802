<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Importation fichier</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a></li>
                <li class="breadcrumb-item">
                    <a *ngIf="typeFile == 'secteur'" routerLink="/secteuractivite">Secteur d'activité</a>
                    <a *ngIf="typeFile == 'activite'" routerLink="/activite">Activités</a>
                    <a *ngIf="typeFile == 'categorie'" routerLink="/categorie">Catégorie</a>
                    <a *ngIf="typeFile == 'taxe'" routerLink="/taxe">Taxe</a>
                    <!-- <a *ngIf="typeImport == secteur" routerLink="/secteuractivite">Secteur d'activité</a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Importation de fichier</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <h5 class="mb-0"></h5>
                    <div class="ms-auto position-relative">
                        <button type="button" class="btn btn-secondary btn-sm" (click)="download()"><i
                                class="bi bi-bookmark-plus"></i>Télécharger le modèle du fichier</button>
                    </div>
                </div>
                <form class="form-group" novalidate [formGroup]="importationForm" (ngSubmit)="onSubmit()">
                    <div class="col-md-12 m-2">
                        <input type="file" accept=".csv" (change)="showPreview($event)" class="form-control"
                            style="height: 35px;" formControlName="source">
                    </div>
                    <div style="text-align: right;">
                        <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-sm"
                            [disabled]="!importationForm.valid"><i class="bi bi-plus-circle"></i>
                            Soumettre</button>
                        <button *ngIf="isLoading" class="btn btn-primary" type="button" disabled=""><span
                                class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </div>
                </form>
                <div class="table-responsive mt-3">
                    <table id="example2" class="table align-middle">
                        <thead class="table-secondary" class="table-primary">
                            <tr>
                                <th>Nom du fichier</th>
                                <th> La ligne </th>
                                <th> Contenu </th>
                                <th> Contenu erreur </th>
                                <th> Type </th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>