<!--start top header-->
<header class="top-header">
  <nav class="navbar navbar-expand gap-3">
    <div class="mobile-toggle-icon fs-3">
      <i class="bi bi-list"></i>
    </div>
    <form class="searchbar">
      <div class="position-absolute top-50 translate-middle-y search-icon ms-3"><i class="bi bi-search"></i></div>
      <input class="form-control" type="text" placeholder="" disabled>
      <div class="position-absolute top-50 translate-middle-y search-close-icon"><i class="bi bi-x-lg"></i></div>
    </form>
    <div class="top-navbar-right ms-auto">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item search-toggle-icon">
          <a class="nav-link" href="#">
            <div class="">
              <i class="bi bi-search"></i>
            </div>
          </a>
        </li>
        <li class="nav-item dropdown dropdown-user-setting">
          <div class="nav-link dropdown-toggle dropdown-toggle-nocaret" style="cursor: pointer;" data-bs-toggle="dropdown">
            <div class="user-setting d-flex align-items-center">
              <img src="assets/images/avatars/user.png" class="user-img" alt="">
            </div>
          </div>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item" href="#">
                <div class="d-flex align-items-center">
                  <img src="assets/images/avatars/user.png" alt="" class="rounded-circle" width="54" height="54">
                  <div class="ms-3">
                    <h5 class="mb-0 dropdown-user-name">{{name}}</h5>
                    <small class="mb-0 dropdown-user-designation text-secondary">{{profile}}</small>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="onViewChangeModal()">
                <div class="d-flex align-items-center">
                  <div class=""><i class="bi bi-key-fill"></i></div>
                  <div class="ms-3"><span>Changer mot de passe</span></div>
                </div>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="onLogout()">
                <div class="d-flex align-items-center">
                  <div class=""><i class="bi bi-lock-fill"></i></div>
                  <div class="ms-3"><span>Se deconnecter</span></div>
                </div>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown dropdown-large">
          <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
            <div class="notifications">
              <span class="notify-badge">0</span>
              <i class="bi bi-bell-fill"></i>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end p-0">
            <div class="p-2 border-bottom m-2">
              <h5 class="h5 mb-0">Notifications</h5>
            </div>
            <div class="header-notifications-list p-2">
              <a class="dropdown-item" href="#">
                <div class="d-flex align-items-center">
                  <div class="notification-box bg-light-primary text-primary"><i class="bi bi-basket2-fill"></i></div>
                  <div class="ms-3 flex-grow-1">
                    <h6 class="mb-0 dropdown-msg-user">New Orders <span class="msg-time float-end text-secondary">1
                        m</span></h6>
                    <small class="mb-0 dropdown-msg-text text-secondary d-flex align-items-center">You have recived new
                      orders</small>
                  </div>
                </div>
              </a>
            </div>
            <div class="p-2">
              <div>
                <hr class="dropdown-divider">
              </div>
              <a class="dropdown-item" href="#">
                <div class="text-center">View All Notifications</div>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!--end top header-->
<form class="" novalidate [formGroup]="changeForm" (ngSubmit)="onChange()">
  <div class="modal fade" id="changeModal" tabindex="-1" aria-labelledby="changeModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Changer votre mot de passe</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row form-group">
            <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
              {{successMessage}}
            </div>
            <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
              {{errorMessage}}
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Email</label>
              <input type="email" class="form-control" style="height: 35px;" placeholder="" formControlName="email">
              <div class="text-danger" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">L'email est
                requis</div>
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Ancien mot de passe</label>
              <input type="password" class="form-control" style="height: 35px;" placeholder="" formControlName="password">
              <div class="text-danger" *ngIf="!password?.valid && (password?.dirty ||password?.touched)">L'ancien mot de passe
                est requis</div>
            </div>
            <div class="col-12 mb-2">
              <label class="form-label">Nouveau Mot de passe</label>
              <input type="password" class="form-control" style="height: 35px;" placeholder="" formControlName="newPassword">
              <div class="text-danger" *ngIf="!newPassword?.valid && (newPassword?.dirty ||newPassword?.touched)">Le nouveau mot de passe
                est requis</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="bi bi-x-lg"></i>
            Annuler</button>
          <button type="submit" *ngIf="!isLoading" [disabled]="!changeForm.valid" class="btn btn-primary btn-sm"><i
              class="bi bi-plus-circle"></i>
            Valider</button>
          <button *ngIf="isLoading" class="btn btn-primary btn-sm" type="button" disabled=""><span
              class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...</button>
        </div>
      </div>
    </div>
  </div>
</form>