import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Datum } from './interfaces/contribuable-response';
import { ContribuableService } from './services/contribuable.service';

declare let $: any;

/* <a class="rowId btn btn-warning btn-sm" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" id="${row.id}" class="text-warning"><i class="bi bi-pencil-fill"></i> Modifier</a> */

@Component({
  selector: 'app-contribuable',
  templateUrl: './contribuable.component.html',
  styleUrls: ['./contribuable.component.css']
})
export class ContribuableComponent implements OnInit {

  dataTableContribuableValue: Datum[] = [];

  table: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, private router: Router, private localStorageService: LocalStorageService) {
    this.findAll();

  }

  ngOnInit(): void {
    
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        {
          mRender: function (data: any, type: any, row: any) {
            return row.nom + ' ' + row.prenom
          },
        },
        { data: 'telephone' },
        { data: 'ville' },
        { data: 'adresse' },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
            <a class="viewId btn btn-success btn-sm" href="/contribuable/viewcontribuable/${row.id}" style="cursor:pointer; color: white; background-color: #1A345C !important;border-color: #1A345C !important;"><i class="bi bi-eye-fill"></i> Détails</a>
            <a class="rowId btn btn-success btn-sm" href="/contribuable/edit/${row.id}" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
  }

  findAll() {
    this.contribuableService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableContribuableValue = response;
        this.onDataTable(response);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
