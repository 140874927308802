import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Datum as DatumProfile } from '../profil/interfaces/profil-response';
import { ProfilService } from '../profil/services/profil.service';
import { UtilisateurPayload } from './models/utilisateur-payload';
import { UtilisateurService } from './services/utilisateur.service';

declare let $: any;

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.css']
})
export class UtilisateurComponent implements OnInit {

  utisateurPayloag = new UtilisateurPayload();

  dataProfileValue: DatumProfile[] = [];

  dataUtilisateurValue: any = [];

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  table: any;

  utilisateurForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    profileId: new FormControl('', Validators.required)
  });

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private profilService: ProfilService, private utilisateurService: UtilisateurService, private router: Router, 
    private localStorageService:LocalStorageService) {
    this.findAll();
    this.findAllProfile();
  }

  ngOnInit(): void {
    var f = document.createElement("script");
    f.type = "text/javascript";
    f.src = "../assets/js/form-select2.js";
    this.elementRef.nativeElement.appendChild(f);
  }

  get name() {
    return this.utilisateurForm?.get('name');
  }
  get email() {
    return this.utilisateurForm?.get('email');
  }

  get profileId() {
    return this.utilisateurForm?.get('profileId');
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'name' },
        { data: 'email' },
        {
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.profile == null){
              res = `Aucun profil`
            }else {
              res = row.profile.name
            }
            return res;
          }
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
              <a class="rowId btn btn-warning btn-sm" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/utilisateur/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
  }

  findAllProfile() {
    this.profilService.findAll().subscribe({
      next: (data) => {
        const response: DatumProfile[] = data;
        this.dataProfileValue = response;
      },
      error: (err) => {
        console.log(err);
      },

    })
  }

  findAll() {
    this.utilisateurService.findAll().subscribe({
      next: (data) => {
        console.log(data);
        
        this.onDataTable(data);
      },
      error: (err) => {
        console.log(err);
      },

    })
  }



  onSubmit() {
    this.isLoading = true;
    this.utisateurPayloag.name = this.utilisateurForm.get('name')?.value;
    this.utisateurPayloag.email = this.utilisateurForm.get('email')?.value;
    this.utisateurPayloag.profileId = this.utilisateurForm.get('profileId')?.value;
    this.utisateurPayloag.tenantId = this.localStorageService.getItem('tenantId');
    this.utisateurPayloag.password = "12345678"

    this.utilisateurService.save(this.utisateurPayloag).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "utilisateur ajouté avec succès;";
        this.hasError = false;
        this.isLoading = false;
        this.utilisateurForm.reset();
        this.table.destroy();
        this.findAll();
      },
      error: (err) => {
        this.hasSuccess = false;
        if (err.status == 422) {
          this.errorMessage = "Données invalides.Il semble que l'utilisateur existe déjà.";
          this.hasError = true;
          this.isLoading = false;
        }
        this.isLoading = false;
      },
    })

  }

}
