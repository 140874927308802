import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { ChangePayload } from '../../utilisateur/models/change-payload';
import { UtilisateurService } from '../../utilisateur/services/utilisateur.service';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  changePayload = new ChangePayload();

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  name : any;

  emailUser : any;

  profile : any;

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  changeForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required)
  });

  BaseUrl: any = window.location.origin;

  constructor(private localStorageService: LocalStorageService, private utilisateurService: UtilisateurService, public router: Router) { }

  ngOnInit(): void {
    console.log(this.data);
    this.name = this.data.name;
    this.emailUser = this.data.email;
    this.profile = this.data.profile.name;
  }

  get email() {
    return this.changeForm?.get('email');
  }
  get password() {
    return this.changeForm?.get('password');
  }
  get newPassword() {
    return this.changeForm?.get('newPassword');
  }


  onViewChangeModal(){
    $('#changeModal').modal('show');
  }

  onRedirect(){
    window.location.assign(this.BaseUrl + '/dashboard')
  }

  onChange(){
    this.isLoading = true;
    this.changePayload.email = this.changeForm.get('email')?.value;
    this.changePayload.password = this.changeForm.get('password')?.value;
    this.changePayload.newPassword = this.changeForm.get('newPassword')?.value;

    this.utilisateurService.change(this.changePayload).subscribe({
      next: (data) => {
        console.log(data);
        this.hasSuccess = true;
        this.successMessage = "mot de passe a été modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.changeForm.reset();
      },
      error: (err) => {
        this.hasSuccess = false;
        if (err.status == 422) {
          this.errorMessage = "Une erreur s'est produite.";
          this.hasError = true;
          this.isLoading = false;
        }
        this.isLoading = false;
      },
    })
  }


  onLogout(){
    this.localStorageService.removeItem('pages');
    this.localStorageService.removeItem('user');
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('tenant');

    this.localStorageService.auth();
    return this.router.navigate(['/login']);
  }

}
