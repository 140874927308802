import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { TaxePayload } from '../models/taxe-payload';

@Injectable({
  providedIn: 'root'
})
export class TaxeService {
  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
    constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }
  save(payload: TaxePayload):Observable<any> {
    return this.http.post<TaxePayload>(`${this.ipAdress}/${this.BaseUrl}taxes`, payload, this.onHeader());
  }

  findAll():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}taxes`, this.onHeader());
  }

  findById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}taxes/${id}`, this.onHeader());
  }

  update(payload: TaxePayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}taxes/${id}`, payload, this.onHeader());
  }
}
