import { Component, ElementRef, OnInit } from '@angular/core';
import { Datum } from './interfaces/taxe-response';
import { Datum as DatumActivite } from '../activite/interfaces/activite-response';
import { Datum as DatumCategorie } from '../categorie-taxe/interfaces/categorie-response';
import { TaxeService } from './services/taxe.service';
import { ActiviteService } from '../activite/services/activite.service';
import { CategorieService } from '../categorie-taxe/services/categorie.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaxePayload } from './models/taxe-payload';
import { Router } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';

declare let $: any;

@Component({
  selector: 'app-taxe',
  templateUrl: './taxe.component.html',
  styleUrls: ['./taxe.component.css']
})
export class TaxeComponent implements OnInit {

  taxe : any = "taxe";

  dataTableTaxeValue: Datum[] = [];

  dataTableActiviteValue: DatumActivite[] = [];

  dataTableCategorieValue: DatumCategorie[] = [];

  dataFrequence : any = [
    {"titre" : "Journalière", "valeur" : 100},
    {"titre" : "Mensuelle", "valeur" : 200},
    {"titre" : "Trimestrielle", "valeur" : 300},
    {"titre" : "Semestrielle", "valeur" : 400},
    {"titre" : "Annuelle", "valeur" : 500}
  ]

  taxePayload = new TaxePayload();

  taxeForm = new FormGroup({
    cout: new FormControl('', Validators.required),
    penalite: new FormControl('', Validators.required),
    frequence: new FormControl('', Validators.required),
    echeance: new FormControl('', Validators.required),
    idActivite: new FormControl('', Validators.required),
    idCategorie: new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  table : any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private taxeService: TaxeService, private activiteService: ActiviteService, private categorieService: CategorieService, 
    private router: Router, private localStorageService: LocalStorageService) { 
    this.findAll();
    this.findAllActivite();
    this.findAllCategorie();
  }

  ngOnInit(): void {

    /* var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/table-datatable.js";
    this.elementRef.nativeElement.appendChild(s); */


    var f = document.createElement("script");
    f.type = "text/javascript";
    f.src = "../assets/js/form-select2.js";
    this.elementRef.nativeElement.appendChild(f);
  }

  ngRedirectImport(){
    this.localStorageService.setItem("typeImport", this.taxe);
    this.router.navigate(["/import"])
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'categorie.libelle'},
        { data: 'activite.libelle'},
        { data: 'cout'},
        {
          mRender: function (data: any, type: any, row: any) {
            return row.penalite+'%'
          },
        },
        { data: 'frequence'},
        { data: 'echeance'},
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
              <a class="btn btn-warning btn-sm rowId" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/taxe/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
  }

  get cout() {
    return this.taxeForm?.get('cout');
  }

  get penalite() {
    return this.taxeForm?.get('penalite');
  }

  get frequence() {
    return this.taxeForm?.get('frequence');
  }

  get echeance() {
    return this.taxeForm?.get('echeance');
  }

  get idActivite() {
    return this.taxeForm?.get('idActivite');
  }

  get idCategorie() {
    return this.taxeForm?.get('idCategorie');
  }

  findAll() {
    this.taxeService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableTaxeValue = response;
        this.onDataTable(response);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findAllActivite() {
    this.activiteService.findAll().subscribe({
      next: (data) => {
        const response: DatumActivite[] = data;
        this.dataTableActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findAllCategorie() {
    this.categorieService.findAll().subscribe({
      next: (data) => {
        const response: DatumCategorie[] = data;
        this.dataTableCategorieValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onSubmit(){
    this.isLoading = true;
    this.taxePayload.cout = this.taxeForm.get('cout')?.value;
    this.taxePayload.frequence = this.taxeForm.get('frequence')?.value;
    this.taxePayload.penalite = this.taxeForm.get('penalite')?.value;
    this.taxePayload.echeance = this.taxeForm.get('echeance')?.value;
    this.taxePayload.idActivite = this.taxeForm.get('idActivite')?.value;
    this.taxePayload.idCategorie = this.taxeForm.get('idCategorie')?.value;
    this.taxePayload.creerPar = this.data.id;
    this.taxePayload.modifierPar = null; 
    this.taxeService.save(this.taxePayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Taxe modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.taxeForm.reset();
        this.table.destroy();
        this.findAll();
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
    
  }

}
