export class LocalPayload {
    nom: any;
    ilot: any;
    lot: any;
    longitude: any;
    latitude: any;
    pointRepere: any;
    quartier: any;
    lien_localisation: any;
    photoLocal: any;
    idDetailContribuable: any;
    idActivite : any;
    creerPar : any;
    modifierPar : any;
}