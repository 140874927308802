<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Contribuables</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable">Contribuables</a></li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable/viewcontribuable/{{IdContribuable}}">Detail contribuable</a></li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable/viewlocal/{{IdLocal}}">Locaux contribuables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Modifier local contribuable</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-lg-8 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="card-title">
                    <h4 class="text-center">Modifier local contribuable</h4>
                </div>
                <form class="" novalidate [formGroup]="localContribuableForm" (ngSubmit)="onUpdate()">
                    <div class="row">
                        <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                            {{successMessage}}
                        </div>
                        <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                            {{errorMessage}}
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">nom</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le nom" formControlName="nom">
                                <div class="ms-3" *ngIf="!nom?.valid && (nom?.dirty ||nom?.touched)">
                                    <div class="text-danger">Le champs nom est requis</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label">ilot</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le ilot" formControlName="ilot">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">lot</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le lot" formControlName="lot">
                            </div>
                            <div class="col-6">
                                <label class="form-label">longitude</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le longitude" formControlName="longitude">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">latitude</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le latitude" formControlName="latitude">
                            </div>
                            <div class="col-6">
                                <label class="form-label">pointRepere</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le pointRepere" formControlName="pointRepere">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">Localisation</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le lien_localisation" formControlName="lien_localisation">
                            </div>
                            <div class="col-6">
                                <label class="form-label">Quatier</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le quartier" formControlName="quartier">
                                <div class="ms-3" *ngIf="!quartier?.valid && (quartier?.dirty ||quartier?.touched)">
                                    <div class="text-danger">Le champs quartier est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">photoLocal</label>
                            <input type="file" class="form-control" style="height: 35px;" accept="image/*"
                                (change)="showPreview($event)" placeholder="Entrer le photoLocal"
                                formControlName="photoLocal">
                        </div>
                        <div class="col-12 mb-2" style="text-align: right;">
                            <button *ngIf="!isLoading" type="submit" class="btn btn-warning btn-sm"
                                ><i class="bi bi-plus-circle"></i>
                                Modifier</button>
                            <button *ngIf="isLoading" class="btn btn-warning" type="button" disabled=""><span
                                    class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Loading...</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>