<!--start wrapper-->
<div class="wrapper">

  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-white rounded-0 border-bottom">
      <div class="container">
        <!-- <a class="navbar-brand" href="#"><img src="assets/images/brand-logo-2.png" width="140" alt="" /></a> -->
        <h4 class="logo-text" style="color: #015984;">DigiTax</h4>
      </div>
    </nav>
  </header>

  <!--start content-->
  <main class="authentication-content" *ngIf="!twoFactor">
    <div class="container">
      <div class="mt-5 mb-2">
        <div class="card rounded-0 overflow-hidden shadow-none border mb-5 mb-lg-0">
          <div class="row g-0">
            <div class="col-12 order-1 col-xl-8 d-flex align-items-center justify-content-center border-end">
              <img src="assets/images/error/auth-img-7.png" class="img-fluid" alt="">
            </div>
            <div class="col-12 col-xl-4 order-xl-2">
              <div class="card-body p-4 p-sm-5">
                <h5 i18n class="card-title">Se Connecter</h5>
                <p class="card-text mb-4">Connectez vous, pour avoir accèss aux fonctionnalités.</p>
                <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                  {{errorMessage}}
                </div>
                <form class="form-body" novalidate [formGroup]="authForm" (ngSubmit)="onSubmit()">

                  <div class="row g-3">
                    <div class="col-12">
                      <label for="inputChoosePassword" class="form-label">Nom utilisateur</label>
                      <div class="ms-auto position-relative">
                        <div class="position-absolute top-50 translate-middle-y search-icon px-3"><i
                            class="bi bi-person-lines-fill"></i></div>
                        <input type="text" class="form-control radius-30 ps-5" id="inputChoosePassword"
                          placeholder="Nom utilisateur" formControlName="login">
                      </div>
                      <div class="text-danger" *ngIf="!login?.valid && (login?.dirty ||login?.touched)">Le nom
                        d'utilisteur est requis</div>
                    </div>
                    <div class="col-12">
                      <label for="inputEmailAddress" class="form-label">Email</label>
                      <div class="ms-auto position-relative">
                        <div class="position-absolute top-50 translate-middle-y search-icon px-3"><i
                            class="bi bi-envelope-fill"></i></div>
                        <input type="email" class="form-control radius-30 ps-5" id="inputEmailAddress"
                          placeholder="Email" formControlName="email">
                      </div>
                      <div class="text-danger" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">L'email est
                        requis</div>
                    </div>
                    <div class="col-12">
                      <label for="inputChoosePassword" class="form-label">Mot de passe</label>
                      <div class="ms-auto position-relative">
                        <div class="position-absolute top-50 translate-middle-y search-icon px-3"><i
                            class="bi bi-lock-fill"></i></div>
                        <input type="password" class="form-control radius-30 ps-5" id="inputChoosePassword"
                          placeholder="Mot de passe" formControlName="password">
                      </div>
                      <div class="text-danger" *ngIf="!password?.valid && (password?.dirty ||password?.touched)">Le mot
                        de passe est requis</div>
                    </div>
                    <div class="col-12">
                        <p class="col-12 text-end"> <a routerLink="/forgot">Mot de passe oublié ?</a>
                    </p>
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <button type="submit" *ngIf="!isLoading" class="btn btn-primary radius-30"
                          [disabled]="!authForm.valid">Se connecter</button>
                        <button *ngIf="isLoading" class="btn btn-primary radius-30" type="button" disabled=""><span
                            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          Loading...</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <main class="authentication-content" *ngIf="twoFactor">
    <div class="row mt-5">
      <div class="col col-md-4 mx-auto">
        <div class="position-relative">
          <div class="card p-2 text-center">
            <h6>Svp veuillez entrer l'otp <br> pour la verification du compte</h6>
            <div> <span>L'otp a été envoyé sur votre compte e-mail </span> <small>{{username}}</small>
            </div>
            <div class="mb-2">
              <h2 style="color: red;"> {{niveauCompteRebours}} </h2>
            </div>
            <form class="" novalidate [formGroup]="valeurOtpForm" (ngSubmit)="onConfirmOtp()">
              <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
                <input class="m-2 text-center form-control rounded" style="width: 10% !important;" type="text" id="first"
                  maxlength="1" formControlName="valeur1"/>
                <input class="m-2 text-center form-control rounded" style="width: 10% !important;" type="text" id="second"
                  maxlength="1" formControlName="valeur2"/>
                <input class="m-2 text-center form-control rounded" style="width: 10% !important;" type="text" id="third"
                  maxlength="1" formControlName="valeur3"/>
                <input class="m-2 text-center form-control rounded" style="width: 10% !important;" type="text" id="fourth"
                  maxlength="1" formControlName="valeur4"/>
              </div>
              <div class="mt-4">
                <button *ngIf="!isLoadingOtp && !btnActualiser" class="btn btn-primary btn-sm radius-30" style="margin: 2px;" [disabled]="!valeurOtpForm.valid">Validate</button>
                <button *ngIf="isLoadingOtp && !btnActualiser" class="btn btn-primary btn-sm radius-30" type="button">
                  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Loading...</button>
                <button *ngIf="!isLoadingActualiser && btnActualiser" class="btn btn-primary btn-sm radius-30" (click)="onActualiserOtp()">Actualiser Otp</button>
                <button *ngIf="isLoadingActualiser && !btnActualiser" class="btn btn-primary btn-sm radius-30" type="button">
                  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Loading...</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>

  <!--end page main-->

  <footer class="bg-white border-top p-3 text-center fixed-bottom">
    <p class="mb-0">Copyright © 2021. All right reserved.</p>
  </footer>

</div>
<!--end wrapper-->