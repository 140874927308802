import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { ParametrePayload } from '../models/parametre-payload';

@Injectable({
  providedIn: 'root'
})
export class ParametreService {

  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
  constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }

  generateTaxe():Observable<any> {
    return this.http.post<null>(`${this.ipAdress}/${this.BaseUrl}taxes/generate`, null, this.onHeader());
  }

  findByType(type : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}parametres/type/${type}`, this.onHeader());
    
  }

  findById(id :any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}parametres/${id}`, this.onHeader());
    
  }

  update(payload: ParametrePayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}parametres/${id}`, payload, this.onHeader());
    
  }
}
