import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { ContribuablePayload } from '../../models/contribuable-payload';
import { ContribuableService } from '../../services/contribuable.service';

@Component({
  selector: 'app-edit-contribuable',
  templateUrl: './edit-contribuable.component.html',
  styleUrls: ['./edit-contribuable.component.css']
})
export class EditContribuableComponent implements OnInit {

  contribuablePayload = new ContribuablePayload();

  typePieceList : any = [
    {"libelle" : "Attestion d'identité"},
    {"libelle" : "Carte nationale d'identité"},
    {"libelle" : "Passeport"},
    {"libelle" : "Carte de séjour"}
  ]
  
  editContribuableForm = new FormGroup({
    nom: new FormControl('', Validators.required),
    prenom: new FormControl('', Validators.required),
    telephone: new FormControl('', Validators.required),
    contact: new FormControl('', Validators.required),
    email: new FormControl(''),
    typePiece: new FormControl('', Validators.required),
    numeroPiece: new FormControl('', Validators.required),
    adresse: new FormControl('', Validators.required),
    nationalite: new FormControl('', Validators.required),
    ville: new FormControl('', Validators.required),
  })

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  minNumeroPiece: any;
  maxNumeroPiece : any;

  dataVille : any;

  textPiece : any;
  paramId: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, private router: Router, private localStorageService: LocalStorageService, 
    private route: ActivatedRoute) { 
      this.onGetVille();
    }

  ngOnInit(): void {

    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);

    
    this.editContribuableForm.get('typePiece')?.valueChanges.subscribe(x => {
      this.textPiece = this.editContribuableForm.get('typePiece')?.value;
      if(this.textPiece == "Carte nationale d'identité"){
        this.editContribuableForm.get('numeroPiece')?.setValue("CI");
        this.maxNumeroPiece = 11;
        this.minNumeroPiece = 11;
      }
    });
  }

  get nom() {
    return this.editContribuableForm?.get('nom');
  }
  get prenom() {
    return this.editContribuableForm?.get('prenom');
  }
  get telephone() {
    return this.editContribuableForm?.get('telephone');
  }
  get contact() {
    return this.editContribuableForm?.get('contact');
  }
  get email() {
    return this.editContribuableForm?.get('email');
  }
  get typePiece() {
    return this.editContribuableForm?.get('typePiece');
  }
  get numeroPiece() {
    return this.editContribuableForm?.get('numeroPiece');
  }
  get nationalite() {
    return this.editContribuableForm?.get('nationalite');
  }
  get adresse() {
    return this.editContribuableForm?.get('adresse');
  }
  get ville() {
    return this.editContribuableForm?.get('ville');
  }

  findById(id : any) {
    this.contribuableService.findById(id).subscribe({
      next: (data) => {
        this.editContribuableForm?.get('nom')?.setValue(data.nom);
        this.editContribuableForm?.get('prenom')?.setValue(data.prenom);
        this.editContribuableForm?.get('telephone')?.setValue(data.telephone);
        this.editContribuableForm?.get('telephone')?.setValue(data.telephone);
        this.editContribuableForm?.get('contact')?.setValue(data.contactUrgence);
        this.editContribuableForm?.get('email')?.setValue(data.email);
        this.editContribuableForm?.get('typePiece')?.setValue(data.typePiece);
        this.editContribuableForm?.get('numeroPiece')?.setValue(data.numeroPiece);
        this.editContribuableForm?.get('adresse')?.setValue(data.adresse);
        this.editContribuableForm?.get('nationalite')?.setValue(data.nationalite);
        this.editContribuableForm?.get('ville')?.setValue(data.ville);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onGetVille(){
    this.contribuableService.getVille().subscribe({
      next: (data) => {
        this.dataVille = data;
      }
    })
  }

  onUpdate(){
    this.isLoading = true;
    this.contribuablePayload.nom = this.editContribuableForm.get('nom')?.value;
    this.contribuablePayload.prenom = this.editContribuableForm.get('prenom')?.value;
    this.contribuablePayload.telephone = this.editContribuableForm.get('telephone')?.value;
    this.contribuablePayload.contact = this.editContribuableForm.get('contact')?.value;
    this.contribuablePayload.email = this.editContribuableForm.get('email')?.value;
    this.contribuablePayload.typePiece = this.editContribuableForm.get('typePiece')?.value;
    this.contribuablePayload.numeroPiece = this.editContribuableForm.get('numeroPiece')?.value;
    this.contribuablePayload.adresse = this.editContribuableForm.get('adresse')?.value;
    this.contribuablePayload.nationalite = this.editContribuableForm.get('nationalite')?.value;
    this.contribuablePayload.ville = this.editContribuableForm.get('ville')?.value;
    this.contribuablePayload.etat = 200;

    this.contribuablePayload.creerPar = null;
    this.contribuablePayload.modifierPar = this.data.id;

    this.contribuableService.update(this.contribuablePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Contribuable ajouté avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.editContribuableForm.reset();
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

}
