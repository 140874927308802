import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { AuthComponent } from './pages/auth/login/auth.component';
import { EnrolementComponent } from './pages/enrolement/enrolement.component';
import { ContribuableComponent } from './pages/contribuable/contribuable.component';
import { ActiviteComponent } from './pages/activite/activite.component';
import { SecteurActiviteComponent } from './pages/secteur-activite/secteur-activite.component';
import { TaxeComponent } from './pages/taxe/taxe.component';
import { CategorieTaxeComponent } from './pages/categorie-taxe/categorie-taxe.component';
import { PaiementComponent } from './pages/paiement/paiement.component';
import { ReglerComponent } from './pages/paiement/regler/regler.component';
import { ProfilComponent } from './pages/profil/profil.component';
import { UtilisateurComponent } from './pages/utilisateur/utilisateur.component';
import { ReclammationComponent } from './pages/reclammation/reclammation.component';
import { ParametreComponent } from './pages/parametre/parametre.component';
import { AuditComponent } from './pages/audit/audit.component';
import { UpdateComponent } from './pages/profil/component/update/update.component';
import { UpdateComponent as UpdateComponentSecteurActivite } from './pages/secteur-activite/component/update/update.component';
import { UpdateComponent as UpdateComponentActivite } from './pages/activite/component/update/update.component';
import { UpdateComponent as UpdateComponentCategorie } from './pages/categorie-taxe/component/update/update.component';
import { UpdateComponent as UpdateComponentTaxe } from './pages/taxe/component/update/update.component';
import { UpdateComponent as UpdateComponentUtilisateur } from './pages/utilisateur/component/update/update.component';
import { ViewComponent } from './pages/contribuable/component/view/view.component';
import { ViewLocalComponent } from './pages/contribuable/component/view-local/view-local.component';
import { EditActiviteContribuableComponent } from './pages/contribuable/component/edit-activite-contribuable/edit-activite-contribuable.component';
import { EditLocalContribuableComponent } from './pages/contribuable/component/edit-local-contribuable/edit-local-contribuable.component';
import { LocalContribuableComponent } from './pages/paiement/components/local-contribuable/local-contribuable.component';
import { TaxeContribuableComponent } from './pages/paiement/components/taxe-contribuable/taxe-contribuable.component';
import { AuthGuard } from './guard/auth-guard.guard';
import { ActiviteContribuableComponent } from './pages/paiement/components/activite-contribuable/activite-contribuable.component';
import { ViewTaxeComponent } from './pages/contribuable/component/view-taxe/view-taxe.component';
import { EditContribuableComponent } from './pages/contribuable/component/edit-contribuable/edit-contribuable.component';
import { ImportationCsvComponent } from './pages/importation-csv/importation-csv.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'login', component: AuthComponent},
  { path: 'forgot', component: ForgotComponent},

  //Routes Enrôlement
  {
    path: 'contribuable/enrolement',
    component: EnrolementComponent,
    canActivate: [AuthGuard]
  },

  //Routes Contribuable

  { 
    path: 'contribuable',
    component: ContribuableComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'contribuable/edit/:id',
    component: EditContribuableComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'contribuable/viewcontribuable/:id',
    component: ViewComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'contribuable/viewlocal/:id',
    component: ViewLocalComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'contribuable/activite/edit/:id',
    component: EditActiviteContribuableComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'contribuable/local/edit/:id',
    component: EditLocalContribuableComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'contribuable/local/taxe/:id',
    component: ViewTaxeComponent,
    canActivate: [AuthGuard]
  },

  //Routes Secteur Activité
  {
    path: 'secteuractivite',
    component: SecteurActiviteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'secteuractivite/edit/:id',
    component: UpdateComponentSecteurActivite,
    canActivate: [AuthGuard]
  },

  //Routes Activité
  {
    path: 'activite',
    component: ActiviteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activite/edit/:id',
    component: UpdateComponentActivite,
    canActivate: [AuthGuard]
  },

  //Routes Taxe
  {
    path: 'taxe',
    component: TaxeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'taxe/edit/:id',
    component: UpdateComponentTaxe,
    canActivate: [AuthGuard]
  },

  //Routes Categorie Taxe
  {
    path: 'categorie',
    component: CategorieTaxeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'categorie/edit/:id',
    component: UpdateComponentCategorie,
    canActivate: [AuthGuard]
  },
  //Routes Paiement
  {
    path: 'paiement/contribuable',
    component: PaiementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'paiement/regler/:id',
    component: ReglerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'paiement/contribuable/local/:id',
    component: LocalContribuableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'paiement/contribuable/activite/:id',
    component: ActiviteContribuableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'paiement/contribuable/local/taxe/:id',
    component: TaxeContribuableComponent,
    canActivate: [AuthGuard]
  },

  //Routes Profil
  {
    path: 'profil',
    component: ProfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profil/edit/:id',
    component: UpdateComponent,
    canActivate: [AuthGuard]
  },

  //Routes Utilisateur
  {
    path: 'utilisateur',
    component: UtilisateurComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'utilisateur/edit/:id',
    component: UpdateComponentUtilisateur,
    canActivate: [AuthGuard]
  },

  //Routes Reclammation
  {
    path: 'reclammation',
    component: ReclammationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reclammation/edit/:id',
    component: ReclammationComponent,
    canActivate: [AuthGuard]
  },

  //Routes Parametre
  {
    path: 'parametre',
    component: ParametreComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'parametre/edit/:id',
    component: ParametreComponent,
    canActivate: [AuthGuard]
  },

  //Routes audits
  { 
    path: 'audit', 
    component: AuditComponent,
    canActivate: [AuthGuard]
  },
  //Routes Importations
  { 
    path: 'import', 
    component: ImportationCsvComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
