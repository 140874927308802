export class TaxePayload {
    cout:     any;
    penalite: any;
    frequence: any;
    echeance: any;
    idActivite : any;
    idCategorie : any;
    creerPar : any;
    modifierPar : any;
}
