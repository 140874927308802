import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { ActiviteContribuablePayload, ContribuablePayload } from '../models/contribuable-payload';
import { LocalPayload } from '../models/local-payload';

@Injectable({
  providedIn: 'root'
})
export class ContribuableService {

  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
    constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }
  save(payload: any):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}contribuables`, payload, this.onHeader());
  }
  saveActiviteContribuable(payload: ActiviteContribuablePayload):Observable<any> {
    return this.http.post<ActiviteContribuablePayload>(`${this.ipAdress}/${this.BaseUrl}contribuable/details`, payload, this.onHeader());
  }

  saveLocaliteContribuable(payload: LocalPayload):Observable<any> {
    return this.http.post<LocalPayload>(`${this.ipAdress}/${this.BaseUrl}locals`, payload, this.onHeader());
  }

  findAll():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}contribuables`, this.onHeader());
  }

  findById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}contribuables/${id}`, this.onHeader());
  }

  findActiviteContribuableById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}contribuable/details/${id}`, this.onHeader());
  }

  findByIdLocal(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}locals/${id}`, this.onHeader());
  }

  findByTaxeIdLocal(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}locals/taxes/${id}`, this.onHeader());
  }

  findByInfoLocal(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}locals/${id}/contribuable`, this.onHeader());
  }

  update(payload: ContribuablePayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}contribuables/${id}`, payload, this.onHeader());
  }

  updateLocal(payload: LocalPayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}locals/${id}`, payload, this.onHeader());
  }

  updateActiviteContribuable(payload: ActiviteContribuablePayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}contribuable/details/${id}`, payload, this.onHeader());
  }

  getImage(id : any){
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}locals/image/${id}`, this.onHeader());
  }

  getVille(){
    return this.http.get<any>('assets/listes_villes_ci.json');
  }
}
