import { Component, ElementRef, OnInit } from '@angular/core';
import { debug } from 'src/app/utility/module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { ProfilPayload } from './models/profil-payload';
import { Datum } from './interfaces/profil-response';
import { Datum as DatumPage } from './interfaces/page-response';
import { ProfilService } from './services/profil.service';
import { ProfilCreate } from './models/profil-create';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

declare let $: any;

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  profilPayload = new ProfilPayload();

  profilCreate = new ProfilCreate();

  dataProfileValue: Datum[] = [];

  dataPageValue: DatumPage[] = [];

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  arrayMenu: any = [];

  dataMenu: any = [];

  tab : any = [];

  //Tab Menu
  arrayAction2: any = [];
  array2: any = [];

  value1: any;

  value2: any;

  alert2: boolean = false;

  table : any;

  typeArray : any = [
    {"libelle" : "WEB"},
    {"libelle" : "MOBILE"}
  ]

  action1From = new FormGroup({
    name: new FormControl('', Validators.required),
    type : new FormControl('', Validators.required),
    label1: new FormControl('', Validators.required),
    labelRemove: new FormControl('', Validators.required)
  });

  action2From = new FormGroup({
    label2: new FormControl('', Validators.required),
    label2Remove: new FormControl('', Validators.required)
  });

  permissionArray: any = [];

  options: IndividualConfig;

  arrayAction: any = [
    {
      "label": "Ajouter",
      "code": 30
    },
    {
      "label": "Lire",
      "code": 40
    },
    {
      "label": "Modifier",
      "code": 50
    },
    {
      "label": "Supprimer",
      "code": 60
    }
  ];

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private toastr: ToastrService, private profilService: ProfilService, private router: Router, 
     private localStorageService: LocalStorageService) {
    this.options = this.toastr.toastrConfig;
    this.options.positionClass = 'toast-top-right';
    this.options.timeOut = 1500;

    this.findAllPage();
    this.findAll();
  }

  ngOnInit(): void {

    var f = document.createElement("script");
    f.type = "text/javascript";
    f.src = "../assets/js/form-select2.js";
    this.elementRef.nativeElement.appendChild(f);
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'name'},
        { data: 'type'},
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
              <a class="rowId btn btn-warning btn-sm" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/profil/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
  }

  get name() {
    return this.action1From?.get('name');
  }

  get type() {
    return this.action1From?.get('type');
  }

  get label1() {
    return this.action1From?.get('label1');
  }

  get labelRemove() {
    return this.action1From?.get('label');
  }

  get label2() {
    return this.action2From?.get('label2');
  }
  get label2Remove() {
    return this.action2From?.get('label2');
  }

  //Array Number 1

  onAdd() {
    this.value1 = this.action1From?.get('label1')?.value;

    if (this.value1 != "") {
      $('#exampleModal').modal('show');
      this.permissionArray = [];
      this.arrayAction2 = [];
    } else {
      $('#exampleModal').modal('hide');
      this.toastr.error('Error', 'Veuillez selectionner une page', this.options);
    }
  }

  onAddArray2() {
    var isExist = true;
    var ind = 0;
    if (this.array2.length > 0) {
      for (let index = 0; index < this.array2.length; index++) {
        if ("" + this.array2[index] === this.value1 + "") {
          isExist = false;
          ind = index;
        }
      }
    } else {
      isExist = true;
    }
    if (isExist) {
      this.array2.push(this.value1);
      this.dataMenu.push({ "page": this.value1, "permission": this.permissionArray });
    }
    $('#exampleModal').modal('hide');
    console.log(this.dataMenu);
  }

  onRemoveArray2() {
    this.value1 = this.action1From?.get('labelRemove')?.value;
    var isExist = false;
    if (this.array2.length > 0) {
      for (let index = 0; index < this.array2.length; index++) {
        if ("" + this.array2[index] === this.value1 + "") {
          isExist = true;
        }
      }
    }
    if (isExist) {
      this.array2.forEach((item: any, index: any) => {
        if (item[0] === this.value1[0]) {
          this.array2.splice(index, 1)
        }
      });
      this.dataMenu.forEach((item: any, index: any) => {
        if (item.page[0] === this.value1[0]) {
          this.dataMenu.splice(index, 1);
        }
      });
      console.log(this.dataMenu);
    }
  }

  //Array Number 2
  onAddAction2() {
    this.value2 = this.action2From?.get('label2')?.value;
    if (this.value2 != "") {
      this.alert2 = false;
      this.onAddArrayAction2(this.value2);
    } else {
      this.alert2 = true;
    }
  }


  onAddArrayAction2(element: any) {

    var convert = element?.toString();

    const convertToarray: any = convert?.split(',');

    var isExist2 = true;

    if (this.arrayAction2.length > 0) {
      for (let index = 0; index < this.arrayAction2.length; index++) {
        if ("" + this.arrayAction2[index] === convertToarray[0] + "") {
          isExist2 = false;
        }
      }
    } else {
      isExist2 = true;
    }
    if (isExist2) {
      this.arrayAction2.push(convertToarray[0]);
      this.permissionArray.push(convertToarray[1].toString())
    }

    this.permissionArray.filter((v: any, i: any, a: any) => a.indexOf(v) === i);

  }

  onRemoveArrayAction2() {

    this.value2 = this.action2From?.get('label2Remove')?.value;

    var convert = this.value2?.toString();

    var convertToarray: any = convert?.split(',');

    var isExist2 = false;

    if (this.arrayAction2.length > 0) {
      for (let index = 0; index < this.arrayAction2.length; index++) {
        if ("" + this.arrayAction2[index] === convertToarray[0] + "") {
          isExist2 = true;
        }
      }
    }
    if (isExist2) {

      this.removeItemOnce(this.arrayAction2, convertToarray[0]);
      this.removeItemOnce(this.permissionArray, convertToarray[1]);
    }

  }

  removeItemOnce(arr: any, value: any) {
    var index = arr.indexOf(value);
    console.log(index);

    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  findAll() {
    this.profilService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataProfileValue = response;
        this.onDataTable(response); 
      },
      error: (err) => {
        console.log(err);
      },

    })
  }

  findAllPage() {
    this.profilService.findAllPage().subscribe({
      next: (data) => {
        const response: DatumPage[] = data;
        this.dataPageValue = response;
        this.dataPageValue.forEach((item: any) => {
          if (item.sousPages == null || item.sousPages == "") {
            this.arrayMenu.push({ "id": item.id, "label": item.label, "icon": item.icon, "lien": item.lien })
          } else {
            item.sousPages.forEach((sous: any) => {
              this.arrayMenu.push({ "id": item.id, "label": sous.label, "icon": sous.icon, "lien": sous.lien })
            })
          }
        });
      }
    })
  }


  onSubmit() {
    this.isLoading = true;

    for (let index = 0; index < this.dataPageValue.length; index++) {
      this.dataMenu.forEach((item:any) => {
        item.page.forEach((page:any) => {
        if(""+this.dataPageValue[index].label == ""+page && (this.dataPageValue[index].sousPages?.length == 0 || this.dataPageValue[index].sousPages?.length == null )){
          this.profilPayload.menu.push({"label": page, "icon": this.dataPageValue[index].icon, "permission": item.permission.toString(), "lien": this.dataPageValue[index].lien, "sousMenu": null});
          
        }else if(""+this.dataPageValue[index].label == ""+page){
          
          this.dataPageValue[index].sousPages?.forEach((sous:any) => {
            this.tab.push({"label": page, "icon": this.dataPageValue[index].icon, "permission": item.permission.toString(), "lien": sous.lien})
          });
          this.profilPayload.menu.push({"label": page, "icon": this.dataPageValue[index].icon, "permission": null, "lien": null, "sousMenu": this.tab})
          this.tab = [];
        }
        });
      });
    }
    this.tab = [];
    this.profilPayload.name = this.action1From.get('name')?.value;
    this.profilPayload.type = this.action1From.get('type')?.value;

    this.profilCreate.name = this.profilPayload.name;
    this.profilCreate.menu = this.profilPayload.menu;
    this.profilCreate.type = this.profilPayload.type;
    
    this.profilService.save(this.profilCreate).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Profil ajouté avec succès;";
        this.isLoading = false;
        this.toastr.success('Succès', this.successMessage, this.options);
        this.action1From.reset();
        this.table.destroy();
        this.findAll();
        this.findAllPage();
      },
      error: (err) => {
        console.log(err);
        
        this.hasSuccess=false;
        if (err.status == 422) {
          this.errorMessage = "Données invalides.Il semble que l'article existe déjà.";
          this.hasError = true;
          this.isLoading = false;
        }
        this.isLoading = false;
      },

    })

  }


}
