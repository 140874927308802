<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Catégories</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/categorie">Catégories</a></li>
                <li class="breadcrumb-item active" aria-current="page">Modifier catégorie</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-lg-6 mx-auto">
        <div class="card raduis-10">
            <div class="card-body">
                <div class="card-title">
                    <h4 class="text-center">Modifier une catégorie </h4>
                </div>
                <hr>
                <form class="" novalidate [formGroup]="categorieForm" (ngSubmit)="onUpdate()">
                    <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                        {{successMessage}}
                    </div>
                    <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                        {{errorMessage}}
                    </div>
                    <div class="col-12 mb-2">
                        <label class="form-label">Nom de la catégorie</label>
                        <input type="text" style="height: 35px;" class="form-control" formControlName="libelle"
                            placeholder="Entrer une catégorie">
                        <div class="ms-3" *ngIf="!libelle?.valid && (libelle?.dirty ||libelle?.touched)">
                            <div class="text-danger">Le champs libelle est requis</div>
                        </div>
                    </div>
                    <div class="col-12 mb-2" style="text-align: right;">
                        <button *ngIf="!isLoading" type="submit" class="btn btn-warning btn-sm"><i class="bi bi-plus-circle"></i>
                            Modifier</button>
                        <button *ngIf="isLoading" class="btn btn-warning" type="button" disabled=""><span
                                class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>