<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Paiements</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Effectuer un paiement</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row mt-5 mb-5">
    <div class="col col-lg-10 mx-auto">
        <form class="g-3" novalidate [formGroup]="searchForm" (ngSubmit)="onSearchContribuableByTelephone()">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9">
                            <input type="text" style="height: 35px;" class="form-control raduis-10"
                                placeholder="Rechercher par le numero / regis de commerce / numéro d'identité / Immatriculation" formControlName="element">
                            <div class="ms-3" *ngIf="!element?.valid && (element?.dirty ||element?.touched)">
                                <div class="text-danger">Le champ est requis</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-sm  w-100"
                                [disabled]="!searchForm.valid"><i class="bi bi-search"></i> Rechercher</button>
                            <button *ngIf="isLoading" class="btn btn-primary btn-sm  w-100" type="button" disabled=""><span
                                    class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Loading...</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- *ngIf="tableActiviteContribuable" -->
<div class="row mb-3" >
    <div class="col col-md-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="row">
                    <div class="col col-md-12 mx-auto">
                        <div class="table-responsive mt-3">
                            <table id="example2" class="table align-middle">
                                <thead class="table-secondary" class="table-primary">
                                    <tr>
                                        <th>Nom & Prénoms</th>
                                        <th>Téléphone / Téléphone d'urgence</th>
                                        <th>adresse</th>
                                        <th class="text-center" style="width: 100px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>