<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Contribuables</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable">Contribuables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Modifier contribuable</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-lg-10 mx-auto">
        <div class="card raduis-10">
            <div class="card-body">
                <div class="card-title">
                    <h4 class="text-center">Modifier un contribuable </h4>
                </div>
                <hr>
                <form class="" novalidate [formGroup]="editContribuableForm" (ngSubmit)="onUpdate()">
                    <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                        {{successMessage}}
                    </div>
                    <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                        {{errorMessage}}
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Nom</label>
                                <input type="text" style="height: 35px;" id="form3Example1c" class="form-control"
                                    placeholder="Ex: Akesse" formControlName="nom" />
                                <div class="ms-3" *ngIf="!nom?.valid && (nom?.dirty ||nom?.touched)">
                                    <div class="text-danger">Le champs nom est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Prénoms</label>
                                <input type="text" style="height: 35px;" id="form3Example1c" class="form-control"
                                    placeholder="Ex: Akesse Fofana" formControlName="prenom" />
                                <div class="ms-3" *ngIf="!prenom?.valid && (prenom?.dirty ||prenom?.touched)">
                                    <div class="text-danger">Le champs prenom est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label" for="form3Example1c">Nationalité</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: Ivoirienne" formControlName="nationalite" />
                                <div class="ms-3"
                                    *ngIf="!nationalite?.valid && (nationalite?.dirty ||nationalite?.touched)">
                                    <div class="text-danger">Le champs nationalite est requis</div>
                                </div>
                        </div>
                        <div class="col">
                            <label class="form-label" for="form3Example1c">Type de pièce</label>
                                <select style="height: 35px;" class="single-select form-control"
                                    formControlName="typePiece">
                                    <option *ngFor="let type of typePieceList" value="{{type.libelle}}">{{type.libelle}}
                                    </option>
                                </select>
                                <div class="ms-3" *ngIf="!typePiece?.valid && (typePiece?.dirty ||typePiece?.touched)">
                                    <div class="text-danger">Le champs type de papier est requis</div>
                                </div>
                        </div>
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Numéro {{textPiece}}</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 00000000000" formControlName="numeroPiece"
                                    minlength={{minNumeroPiece}} maxlength={{maxNumeroPiece}} />
                                <div class="ms-3"
                                    *ngIf="!numeroPiece?.valid && (numeroPiece?.dirty ||numeroPiece?.touched)">
                                    <div class="text-danger">Le champs Numéro pièce d'identite est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Contact 1</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 000000000" formControlName="telephone" />
                                <div class="ms-3" *ngIf="!telephone?.valid && (telephone?.dirty ||telephone?.touched)">
                                    <div class="text-danger">Le champs contact 1 est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Contact d'urgence</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 000000000" formControlName="contact" />
                                <div class="ms-3" *ngIf="!contact?.valid && (contact?.dirty ||contact?.touched)">
                                    <div class="text-danger">Le champs contact d'urgence est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Ville</label>
                                <select style="height: 35px;" class="single-select form-control"
                                    formControlName="ville">
                                    <option *ngFor="let villeci of dataVille" value="{{villeci.city}}">{{villeci.city}}
                                    </option>
                                </select>
                                <div class="ms-3" *ngIf="!ville?.valid && (ville?.dirty ||ville?.touched)">
                                    <div class="text-danger">Le champs ville est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Quatier d'habitation</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: Cocody" formControlName="adresse" />
                                <div class="ms-3" *ngIf="!adresse?.valid && (adresse?.dirty ||adresse?.touched)">
                                    <div class="text-danger">Le champs quatier est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Email</label>
                                <input type="email" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: Tax@gmail.com" formControlName="email" />
                                <div class="ms-3" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">
                                    <div class="text-danger">Le champs email est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-2" style="text-align: right;">
                        <button *ngIf="!isLoading" type="submit" class="btn btn-warning btn-sm"><i class="bi bi-plus-circle"></i>
                            Modifier</button>
                        <button *ngIf="isLoading" class="btn btn-warning" type="button" disabled=""><span
                                class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>