import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({ providedIn: "root" })
export class LocalStorageService{
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }


  auth(){
    if(this.getItem("user")){
      this.loggedIn.next(true);
    }else{
      this.loggedIn.next(false);
    }
  }

  public setItem(key: string, data: any): void {
    localStorage.setItem(key, data);
    this.auth();
  }

  public getItem(key: string): string {
 
    let data = localStorage.getItem(key)|| "";
    return data;
  }

  public removeItem(key:any): void {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }
}
