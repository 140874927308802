<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Paiements</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a></li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/paiement/contribuable">Effectuer un
                        paiement</a></li>
                <li class="breadcrumb-item active" aria-current="page">Finalisation du paiement</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-lg-12 mx-auto">
        <form class="">
            <div class="card raduis-10">
                <div class="card-body">
                    <div class="card-tile text-center">
                        <h4><strong>Effectuer un paiement</strong></h4>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col col-lg-9 mx-auto">
                            <div class="row">
                                <div>
                                    <h5>Informations de la taxe à régler</h5>
                                </div>
                                <hr>
                                <div class="col-12 mb-4">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p>Secteur d'activité : {{secteur}}</p>
                                            <p>Activité : {{activite}}</p>
                                            <p>Catégorie : {{categorie}}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p>Montant de la taxe : {{cout}}</p>
                                            <p>Date d'échéance : {{echeance}}</p>
                                            <p *ngIf="penalite == 0"><span style="color: rgb(120, 241, 120);">Pénalité :
                                                    {{penalite}}</span></p>
                                            <p *ngIf="penalite != 0"><span style="color: rgb(238, 94, 94);">Pénalité :
                                                    {{penalite}}</span></p>
                                            <p style="color: orange;">Montant à payer : {{montantPaye}}</p>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-2">
                                            <button class="btn btn-warning btn-sm" (click)="onViewInputCompenser()"
                                                *ngIf="!viewInput && penalite != 0">Compenser</button>
                                            <button class="btn btn-danger btn-sm" (click)="onCancelInputCompenser()"
                                                *ngIf="viewInput">Annuler</button>
                                        </div>
                                        <div class="col-md-10" *ngIf="viewInput">
                                            <form class="" novalidate [formGroup]="dataConpenseForm">
                                                <input type="number" class="form-control" style="height: 35px;"
                                                    placeholder="Entrer la compensation" formControlName="conpense">
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h5 style="color: rgb(216, 122, 60);">Mode de paiement</h5>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="form-label">Mobile Monney</label>
                                            <input class="" type="checkbox" name="" id="mobilemonney"
                                                value="mobilemonney" (click)="onChecked('MOBILE MONNEY')"
                                                style="margin: 10px;">
                                        </div>
                                        <div class="col-12">
                                            <label class="form-label">Espèces</label>
                                            <input class="" type="checkbox" name="" id="especes" value="especes"
                                                (click)="onChecked('ESPECES')" style="margin: 10px;">
                                        </div>
                                    </div>
                                </div>
                                <div class="row text-center" *ngIf="viewMobileMonney">
                                    <div class="col-md-4">
                                        <input class="" type="checkbox" name="" id="orange" value="orange"
                                            (click)="onCheckedMobible('ORANGE MONNEY')">
                                        <img src="assets/images/icons/orange.jpg" alt=""
                                            style="height: 50px; width: 70px; margin: 10px;">
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" name="moov" id="moov" value="moov"
                                            (click)="onCheckedMobible('MOOV MONNEY')">
                                        <img src="assets/images/icons/moov.png" alt=""
                                            style="height: 50px; width: 70px; margin: 10px;">
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" name="mtn" id="mtn" value="mtn"
                                            (click)="onCheckedMobible('MTN MONNEY')">
                                        <img src="assets/images/icons/mtn.jpg" alt=""
                                            style="height: 50px; width: 70px; margin: 10px;">
                                    </div>
                                </div>
                                <hr>
                                <div class="col-12" *ngIf="inputMobileMonney">
                                    <form class="" novalidate [formGroup]="dataTransferMonneyForm">
                                        <label class="form-label">Numéro Mobile Monney</label>
                                        <input class="form-control" type="text"
                                            placeholder="Entrer le numéro mobile monney" formControlName="telephone"
                                            style="height: 35px;">

                                        <div class="col-12 mt-2 mb-3" style="text-align: right;">
                                            <button class="btn btn-success btn-sm"
                                                [disabled]="!dataTransferMonneyForm.valid"
                                                (click)="onConfirm()">Valider</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 mt-2 mb-3" *ngIf="btnValidEspece" style="text-align: right;">
                                    <button class="btn btn-success btn-sm" (click)="onConfirm()">Valider</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<form class="row">
    <div class="modal fade" id="confirModal" tabindex="-1" aria-labelledby="confirModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="confirModalLabel">Confirmer le paiement de la taxe</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12" *ngIf="checkedMonney != null && !hasSuccess && !hasError">
                            <p>Vous allez éffectuer un paiement de taxe d'une valeur de <strong
                                    style="color: red;">{{montantPaye}}</strong> avec le numéro {{numeroMobile}} sur
                                {{operateur}} </p>
                        </div>
                        <div class="col-12" *ngIf="checkedMonney == null && !hasSuccess && !hasError">
                            <p>Vous allez éffectuer un paiement de taxe d'une valeur de <strong
                                    style="color: red;">{{montantPaye}}</strong></p>
                        </div>
                        <div class="col-12 alert alert-success" *ngIf="hasSuccess">
                            {{successMessage}}
                        </div>
                        <div class="col-12 alert alert-danger" *ngIf="hasError">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="!btnConfirm" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i
                            class="bi bi-x-lg"></i> Fermer</button>
                    <button type="button" *ngIf="btnConfirm" (click)="onCancel()" class="btn btn-success btn-sm" data-bs-dismiss="modal"><i
                            class="bi bi-x-lg"></i> OK</button>
                    <button type="submit" *ngIf="!isLoading && !btnConfirm" class="btn btn-primary btn-sm" (click)="onSubmit()"><i
                            class="bi bi-plus-circle"></i>
                        Confirmer</button>
                    <button *ngIf="isLoading" class="btn btn-primary btn-sm" type="button" disabled=""><span
                            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                </div>
            </div>
        </div>
    </div>
</form>