<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Contribuables</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable">Contribuables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Detail contribuable</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="card-title">
                    <h3 class="text-center">Détail du contribuable</h3>
                </div>
                <hr>
                <div class="row mb-3">
                    <div class="col-md-8">
                        <h5 class="color: ;"><small>Nom & pénoms : </small> {{NomPrenom}}</h5>
                    </div>
                    <div class="col-md-2">
                        <span class="badge bg-light-success text-success" *ngIf="etat == 200"><i
                                class="bi bi-check-circle"></i></span>
                        <span class="badge bg-light-danger text-danger" *ngIf="etat != 200"><i
                                class="bi bi-x-circle-fill"></i></span>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-sm" data-bs-toggle="modal"
                            data-bs-target="#addActiviteModal"><i class="bi bi-check-circle"></i> Ajouter une
                            activité</button>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <p>Téléphone / Téléphone d'urgence : <strong>{{tel}} / <span
                                    style="color: red;">{{telUrgence}}</span></strong></p>
                        <p>Email : <strong>{{email}}</strong></p>
                        <p>Ville : <strong>{{ville}}</strong> / Adresse : <strong>{{adresse}}</strong></p>
                    </div>
                    <div class="col-md-6">
                        <p>Type de pièce : <strong>{{typePiece}}</strong></p>
                        <p>N°pièce d'identité : <strong>{{numeroPiece}}</strong></p>
                        <p>Nationalité : <strong>{{nationalite}}</strong></p>
                    </div>
                </div>
                <div>
                    <h6><small>Ses Activités</small></h6>
                </div>
                <hr>
                <div class="row">
                    <div class="col col-md-12 mx-auto">
                        <div class="table-responsive mt-3">
                            <table id="example2" class="table align-middle">
                                <thead class="table-secondary">
                                    <tr>
                                        <th>Activité</th>
                                        <th>Secteur d'activite</th>
                                        <th>Registre de commerce</th>
                                        <th>Immatriculation</th>
                                        <th>Date de creation</th>
                                        <th>Date d'immatriculation</th>
                                        <th class="text-center" style="width: 100px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<form class="row" novalidate [formGroup]="activiteContribuableForm" (ngSubmit)="onAddActivite()">
    <div class="modal fade" id="addActiviteModal" tabindex="-1" aria-labelledby="addActiviteModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addActiviteModalLabel">Ajouter un activité</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccessActivite">
                            {{successMessageActivite}}
                        </div>
                        <div class="col-12 alert alert-danger" role="alert" *ngIf="hasErrorActivite">
                            {{errorMessageActivite}}
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Registre de Commerce</label>
                            <input type="text" class="form-control" style="height: 35px;"
                                placeholder="Entrer le registre de commerce" formControlName="numeroRegistreCommerce">
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Immatriculation</label>
                            <input type="text" class="form-control" style="height: 35px;"
                                placeholder="Entrer l'immatriculation" formControlName="numeroImmatriculation">
                            <div class="ms-3"
                                *ngIf="!numeroImmatriculation?.valid && (numeroImmatriculation?.dirty ||numeroImmatriculation?.touched)">
                                <div class="text-danger">Le champs Immatriculation est requis</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Date de creation</label>
                            <input type="date" class="form-control" style="height: 35px;"
                                placeholder="Entrer la date de creation" formControlName="dateCreationEntreprise">
                            <div class="ms-3"
                                *ngIf="!dateCreationEntreprise?.valid && (dateCreationEntreprise?.dirty ||dateCreationEntreprise?.touched)">
                                <div class="text-danger">Le champs date de creation est requis</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Date d'immatriculation </label>
                            <input type="date" class="form-control" style="height: 35px;"
                                placeholder="Entrer l'immatriculation" formControlName="dateImmatriculation">
                            <div class="ms-3"
                                *ngIf="!dateImmatriculation?.valid && (dateImmatriculation?.dirty ||dateImmatriculation?.touched)">
                                <div class="text-danger">Le champs date d'immatriculation est requis</div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Séléctionnez une activité</label>
                            <select class="form-control" formControlName="idActivite">
                                <option *ngFor="let activite of dataTableActiviteValue" value="{{activite.id}}">
                                    {{activite.libelle}}</option>
                            </select>
                            <div class="ms-3" *ngIf="!idActivite?.valid && (idActivite?.dirty ||idActivite?.touched)">
                                <div class="text-danger">Le champs activité est requis</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i
                            class="bi bi-x-lg"></i> Fermer</button>
                    <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-sm"
                        [disabled]="!activiteContribuableForm.valid"><i class="bi bi-plus-circle"></i>
                        Valider</button>
                    <button *ngIf="isLoading" class="btn btn-primary btn-sm" type="button" disabled=""><span
                            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                </div>
            </div>
        </div>
    </div>
</form>

<form class="" novalidate [formGroup]="localContribuableForm" (ngSubmit)="onAddLocalite()">
    <div class="modal fade" id="addlocaliteModal" tabindex="-1" aria-labelledby="addlocaliteModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addActiviteModalLabel">Ajouter une localité</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                            {{successMessage}}
                        </div>
                        <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                            {{errorMessage}}
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">Nom</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le nom" formControlName="nom">
                                <div class="ms-3" *ngIf="!nom?.valid && (nom?.dirty ||nom?.touched)">
                                    <div class="text-danger">Le champs nom est requis</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Ilot</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le ilot" formControlName="ilot">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">Lot</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le lot" formControlName="lot">
                            </div>
                            <div class="col-6">
                                <label class="form-label">Longitude</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le longitude" formControlName="longitude">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">Latitude</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le latitude" formControlName="latitude">
                            </div>
                            <div class="col-6">
                                <label class="form-label">Point de repère</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le pointRepere" formControlName="pointRepere">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="form-label">Localisation</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le lien_localisation" formControlName="lien_localisation">
                            </div>
                            <div class="col-6">
                                <label class="form-label">Quatier</label>
                                <input type="text" class="form-control" style="height: 35px;"
                                    placeholder="Entrer le quartier" formControlName="quartier">
                                <div class="ms-3"
                                    *ngIf="!quartier?.valid && (quartier?.dirty ||quartier?.touched)">
                                    <div class="text-danger">Le champs quartier est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">photo du local</label>
                            <input type="file" class="form-control" style="height: 35px;" accept="image/*"
                                (change)="showPreview($event)" placeholder="Entrer le photoLocal"
                                formControlName="photoLocal">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i
                            class="bi bi-x-lg"></i> Fermer</button>
                    <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-sm"
                        [disabled]="!localContribuableForm.valid"><i class="bi bi-plus-circle"></i>
                        Valider</button>
                    <button *ngIf="isLoading" class="btn btn-primary" type="button" disabled=""><span
                            class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                </div>
            </div>
        </div>
    </div>
</form>