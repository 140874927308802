<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Utilisateurs</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/utilisateur">Utilisateurs</a></li>
                <li class="breadcrumb-item active" aria-current="page">Modifier utilisateur</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col col-lg-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="card-title">
                    <h4 class="text-center">Modifier utilisateur</h4>
                </div>
                <hr>
                <form class="" novalidate [formGroup]="utilisateurForm" (ngSubmit)="onUpdate()">
                    <div class="row form-group">
                        <div class="col-12 alert alert-success" role="alert" *ngIf="hasSuccess">
                            {{successMessage}}
                        </div>
                        <div class="col-12 alert alert-danger" role="alert" *ngIf="hasError">
                            {{errorMessage}}
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Nom</label>
                            <input type="text" class="form-control" style="height: 35px;" placeholder=""
                                formControlName="name">
                            <div class="text-danger" *ngIf="!name?.valid && (name?.dirty ||name?.touched)">Le nom est
                                requis</div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Email</label>
                            <input type="email" class="form-control" style="height: 35px;" placeholder=""
                                formControlName="email">
                            <div class="text-danger" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">L'email
                                est requis</div>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="form-label">Séléctionnez un profil</label>
                            <select class="form-control" formControlName="profileId">
                                <option *ngFor="let profil of dataProfileValue" value="{{profil.id}}"
                                    [selected]="profil.id === Idprofil">{{profil.name}}</option>
                            </select>
                            <div class="text-danger"
                                *ngIf="!profileId?.valid && (profileId?.dirty ||profileId?.touched)">Le
                                profil est
                                requis</div>
                        </div>
                        <div class="col-12 mb-2" style="text-align: right;">
                            <button *ngIf="!isLoading" type="submit" class="btn btn-warning btn-sm"><i class="bi bi-plus-circle"></i>
                                Modifier</button>
                            <button *ngIf="isLoading" class="btn btn-warning" type="button" disabled=""><span
                                    class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Loading...</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>