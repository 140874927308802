<div class="wrapper" *ngIf="!(_router.url === '/login' || _router.url === '/forgot' || _router.url === '/pages-error404')">
    <app-header *ngIf="!(_router.url === '/login' || _router.url === '/forgot' || _router.url === '/pages-error404')">
    </app-header>
    <app-sidebar *ngIf="!(_router.url === '/login' || _router.url === '/forgot' || _router.url === '/pages-error404')">
    </app-sidebar>

    <!--start content-->
    <main class="page-content">
        <router-outlet></router-outlet>
    </main>
    <app-footer *ngIf="!(_router.url === '/login' || _router.url === '/forgot' || _router.url === '/pages-error404')">
    </app-footer>
</div>

<div class="wrapper" *ngIf="(_router.url === '/login' || _router.url === '/forgot' || _router.url === '/pages-error404')">
    <router-outlet></router-outlet>
</div>
