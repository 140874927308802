import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Datum } from './interfaces/activite-response';
import { Datum as DatumSecteurActivite } from '../secteur-activite/interfaces/secteur-activite-response';
import { Activite } from './models/activite';
import { ActiviteService } from './services/activite.service';
import { SecteurActiviteService } from '../secteur-activite/services/secteur-activite.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

declare let $: any;

@Component({
  selector: 'app-activite',
  templateUrl: './activite.component.html',
  styleUrls: ['./activite.component.css']
})
export class ActiviteComponent implements OnInit {

  Activite : any = "activite";

  dataTableActiviteValue: Datum[] = [];

  dataTableSecteurActiviteValue: DatumSecteurActivite[] = [];

  activitePayload = new Activite();

  activiteForm = new FormGroup({
    libelle: new FormControl('', Validators.required),
    idSecteurActivite: new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  table: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private activiteService: ActiviteService, private secteurActiviteService: SecteurActiviteService, private router: Router,
     private localStorageService: LocalStorageService) {
    this.findAll();
    this.findAllSecteurActivite();
  }

  ngOnInit(): void {

    /* var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/table-datatable.js";
    this.elementRef.nativeElement.appendChild(s); */

    var f = document.createElement("script");
    f.type = "text/javascript";
    f.src = "../assets/js/form-select2.js";
    this.elementRef.nativeElement.appendChild(f);
  }

  ngRedirectImport(){
    this.localStorageService.setItem("typeImport", this.Activite);
    this.router.navigate(["/import"])
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'libelle' },
        { data: 'secteurActivite.libelle' },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
              <a class="btn btn-warning btn-sm rowId" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/activite/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
    
  }

 

  get libelle() {
    return this.activiteForm?.get('libelle');
  }

  get idSecteurActivite() {
    return this.activiteForm?.get('idSecteurActivite');
  }


  findAll() {
    this.activiteService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableActiviteValue = response;
        this.onDataTable(response);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findAllSecteurActivite() {
    this.secteurActiviteService.findAll().subscribe({
      next: (data) => {
        const response: DatumSecteurActivite[] = data;
        this.dataTableSecteurActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onSubmit() {
    this.isLoading = true;
    var lib = this.activiteForm.get('libelle')?.value;
    this.activitePayload.libelle = lib?.toUpperCase();
    this.activitePayload.idSecteurActivite = this.activiteForm.get('idSecteurActivite')?.value;
    this.activitePayload.creerPar = this.data.id;
    this.activitePayload.modifierPar = null;
    this.activiteService.save(this.activitePayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Activité modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.activiteForm.reset();
        this.table.destroy();
        this.findAll();
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }
}
