<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Paiements</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/paiement/contribuable">Effectuer un
                        paiement</a></li>
                <li class="breadcrumb-item active" aria-current="page">Activité du contribuable</li>
            </ol>
        </nav>
    </div>
</div>
<!-- *ngIf="tableActiviteContribuable" -->
<div class="row mb-3">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="card-title">
                    <h5 class="text-center" style="color:#015984;">Informations du contribuable</h5>
                </div>
                <hr>
                <div class="row mb-2 text-center">
                    <div class="col-md-6">
                        <p>Nom & prénoms : <strong>{{NomPrenom}}</strong></p>
                        <p>Téléphone / Téléphone d'urgence : <strong>{{tel}} / {{telUrgence}}</strong></p>
                        <p>Email / Adresse: <strong>{{email}} / {{adresse}}</strong></p>
                    </div>
                    <div class="col-md-6">
                        <p>Type de pièce : <strong>{{typePiece}}</strong></p>
                        <p>N°pièce d'identité : <strong>{{numeroPiece}}</strong></p>
                        <p>Nationalité : <strong>{{nationalite}}</strong></p>
                    </div>
                </div>
                <hr>
                <div class="card-body" *ngIf="isActivite">
                    <h5 class="card-title" style="color: #da7836;">Ses Activités</h5>
                    <div class="my-3 border-top"></div>
                    <div class="row">
                        <div class="col col-md-12 mx-auto">
                            <div class="table-responsive mt-3">
                                <table id="example2" class="table align-middle">
                                    <thead class="table-secondary" class="table-primary">
                                        <tr>
                                            <th>Activité</th>
                                            <th>Secteur d'activite</th>
                                            <th>Registre de commerce</th>
                                            <th>Immatriculation</th>
                                            <th>Date de creation</th>
                                            <th>Date d'immatriculation</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="isLocal">
                    <div class="d-flex align-items-center">
                        <div class="ms-auto position-relative">
                            <a href="/paiement/contribuable/activite/{{paramId}}" class="btn btn-secondary btn-sm m-2">Mes Activités</a>
                        </div>
                    </div>
                    <h5 class="card-title" style="color: #da7836;">Mes Locaux</h5>
                    <div class="my-3 border-top"></div>
                    <div class="row">
                        <div class="col col-md-12 mx-auto">
                            <div class="table-responsive mt-3">
                                <table class="table align-middle">
                                    <thead class="table-secondary" class="table-primary">
                                        <tr>
                                            <th>Image</th>
                                            <th>Nom</th>
                                            <th>ilot</th>
                                            <th>lot</th>
                                            <th>longitude</th>
                                            <th>latitude</th>
                                            <th>point de rèpere</th>
                                            <th>quartier</th>
                                            <th>lien de localisation</th>
                                            <th class="text-center" style="width: 50px;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of dataTableLocal">
                                            <td>
                                                <img class="product-img-2" src="{{url + data.id + '?tenant' + '=' + tenant}}">
                                            </td>
                                            <td> {{data.nom}} </td>
                                            <td>{{data.ilot}}</td>
                                            <td>{{data.lot}}</td>
                                            <td>{{data.longitude}}</td>
                                            <td>{{data.latitude}}</td>
                                            <td>{{data.pointRepere}}</td>
                                            <td>{{data.quartier}}</td>
                                            <td>{{data.lien_localisation}}</td>
                                            <td>
                                                <a class="btn btn-warning btn-sm" (click)="onNavigateTaxe(data.id)"> Mes taxes</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="isTaxe">
                    <div class="d-flex align-items-center">
                        <div class="ms-auto position-relative">
                            <a (click)="onRedirectLocal()" class="btn btn-secondary btn-sm m-2">Mes Activités</a>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <h5 class="mb-0">Taxes contribuables</h5>
                    </div>
                    <div class="table-responsive mt-3">
                      <table class="table align-middle">
                        <thead class="table-secondary" class="table-primary">
                          <tr>
                            <th>Activité</th>
                            <th>Catégorie</th>
                            <th>Coût</th>
                            <th>Penalité</th>
                            <th>Date d'échéance</th>
                            <th>Fréquence</th>
                            <th class="text-center" style="width: 50px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dataTaxe of dataTableTaxe">
                                <td> {{dataTaxe.taxe.activite.libelle}} </td>
                                <td>{{dataTaxe.taxe.categorie.libelle}}</td>
                                <td>{{dataTaxe.taxe.cout}}</td>
                                <td *ngIf="res == false">
                                    {{result}}
                                </td>
                                <td *ngIf="res != false">
                                    {{dataTaxe.taxe.penalite + '%'}}
                                </td>
                                <td>
                                    {{dataTaxe.taxe.echeance}}
                                </td>
                                <td>
                                    {{dataTaxe.taxe.frequence}}
                                </td>
                                <td>
                                    <a class="btn btn-primary btn-sm" routerLink="/paiement/regler/{{dataTaxe.taxe.id}}">Régler</a>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>