import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-reclammation',
  templateUrl: './reclammation.component.html',
  styleUrls: ['./reclammation.component.css']
})
export class ReclammationComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/table-datatable.js";
    this.elementRef.nativeElement.appendChild(s);
  }

}
