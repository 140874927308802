export class TransactionPayload{
    type: any;
    telephone : any;
    etat : any;
    frais : any;
    montant : any;
    montantTotal : any;
    operateur : any | undefined;
    penalite : any;
    conpense : any;
    idContribuable : any;
    tenant : any;
    idTaxeContribuable : any;
    creerPar : any;
    modifierPar : any;

}