import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ActiviteService } from 'src/app/pages/activite/services/activite.service';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { LocalPayload } from '../../models/local-payload';
import { ContribuableService } from '../../services/contribuable.service';

@Component({
  selector: 'app-edit-local-contribuable',
  templateUrl: './edit-local-contribuable.component.html',
  styleUrls: ['./edit-local-contribuable.component.css']
})
export class EditLocalContribuableComponent implements OnInit {

  localContribuablePayload = new LocalPayload();

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  localContribuableForm = new FormGroup({
    nom: new FormControl('', Validators.required),
    ilot: new FormControl('', Validators.required),
    lot: new FormControl('', Validators.required),
    longitude: new FormControl('', Validators.required),
    latitude: new FormControl('', Validators.required),
    pointRepere: new FormControl('', Validators.required),
    quartier: new FormControl('', Validators.required),
    lien_localisation: new FormControl('', Validators.required),
    photoLocal: new FormControl(''),
    fileSource: new FormControl(),

  })
  paramId: any;
  IdContribuable: any;
  IdLocal : any;
  DetailId: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private activiteService: ActiviteService, private contribuableService: ContribuableService, private router: Router, 
    private route: ActivatedRoute,  private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
  }

  //Local Contribuable
  get nom() {
    return this.localContribuableForm?.get('nom');
  }
  get ilot() {
    return this.localContribuableForm?.get('ilot');
  }
  get lot() {
    return this.localContribuableForm?.get('lot');
  }

  get longitude() {
    return this.localContribuableForm?.get('longitude');
  }
  get latitude() {
    return this.localContribuableForm?.get('latitude');
  }
  get pointRepere() {
    return this.localContribuableForm?.get('pointRepere');
  }
  get quartier() {
    return this.localContribuableForm?.get('quartier');
  }
  get lien_localisation() {
    return this.localContribuableForm?.get('lien_localisation');
  }
  get photoLocal() {
    return this.localContribuableForm?.get('photoLocal');
  }
  get idDetailContribuable() {
    return this.localContribuableForm?.get('idDetailContribuable');
  }

  // Image Preview
  showPreview(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.localContribuableForm.patchValue({
          fileSource: reader.result
        });

      };
    }
  }

  findById(id: any) {
    this.contribuableService.findByIdLocal(id).subscribe({
      next: (data) => {
        this.localContribuableForm.get('nom')?.setValue(data.nom);
        this.localContribuableForm.get('ilot')?.setValue(data.ilot);
        this.localContribuableForm.get('lot')?.setValue(data.lot);
        this.localContribuableForm.get('longitude')?.setValue(data.longitude);
        this.localContribuableForm.get('latitude')?.setValue(data.latitude);
        this.localContribuableForm.get('pointRepere')?.setValue(data.pointRepere);
        this.localContribuableForm.get('quartier')?.setValue(data.quartier);
        this.localContribuableForm.get('lien_localisation')?.setValue(data.lien_localisation);
        this.IdContribuable =  this.localStorageService.getItem('idContribuable');
        this.IdLocal = this.localStorageService.getItem('idLocal');

      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onUpdate() { 
    this.isLoading = true;
    var dataImage = this.localContribuableForm.get('fileSource')?.value.split(",")[1];
    this.localContribuablePayload.nom = this.localContribuableForm.get('nom')?.value;
    this.localContribuablePayload.ilot = this.localContribuableForm.get('ilot')?.value;
    this.localContribuablePayload.longitude = this.localContribuableForm.get('longitude')?.value;
    this.localContribuablePayload.latitude = this.localContribuableForm.get('latitude')?.value;
    this.localContribuablePayload.pointRepere = this.localContribuableForm.get('pointRepere')?.value;
    this.localContribuablePayload.quartier = this.localContribuableForm.get('quartier')?.value;
    this.localContribuablePayload.lien_localisation = this.localContribuableForm.get('lien_localisation')?.value;
    this.localContribuablePayload.photoLocal = dataImage;
    this.localContribuablePayload.idDetailContribuable = this.DetailId;
    this.localContribuablePayload.creerPar = null;
    this.localContribuablePayload.modifierPar = this.data.id;

    this.contribuableService.updateLocal(this.localContribuablePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Local contribuable modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

}
