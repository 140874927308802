import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { Importation } from '../models/Importation';

@Injectable({
  providedIn: 'root'
})
export class ImportationCsvService {

  
  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
    constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }


  save(payload : Importation):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}importations`, payload, this.onHeader());
  }

  findAll():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}importations/historique`, this.onHeader());
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

}
