import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from './services/dashboard.service';
import Chart from 'chart.js/auto';

declare let $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  contribuable: any;
  impaye: any;
  local: any;
  paye: any;

  public chart: any;

  year: any = [];

  WeekArry : any = [];

  selectYear: any;

  constructor(private elementRef: ElementRef, public _router: Router, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.selectYear = new Date().getFullYear();
    this.onYear();

    this.onDate();

  }


  findStat(value: any) {
    console.log(value);
  }

  onYear() {

    var InitYear = 2020;
    var currentYear = new Date().getFullYear();
    for (let i = InitYear; i <= currentYear; i++) {
      this.year.push(i);
    }
  }

  onCalculWeek(dowOffset : any) {
    dowOffset = typeof (dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
    var newYear = new Date(new Date().getFullYear(), 0, 1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((new Date().getTime() - newYear.getTime() -
      (new Date().getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if (day < 4) {
      weeknum = Math.floor((daynum + day - 1) / 7) + 1;
      if (weeknum > 52) {
        var nYear = new Date(new Date().getFullYear() + 1, 0, 1);
        var nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        /*if the next year starts before the middle of
          the week, it is week #1 of that year*/
        weeknum = nday < 4 ? 1 : 53;
      }
    }
    else {
      weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
  }

  onDate() {
    var week = this.onCalculWeek(new Date());
    for(let i=1; i<=week; i++){
      this.WeekArry.push(i);
    }
    
  }



}
