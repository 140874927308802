import { Component, ElementRef, OnInit } from '@angular/core';
import { Datum } from '../../interfaces/activite-response';
import { Datum as DatumSecteurActivite} from '../../../secteur-activite/interfaces/secteur-activite-response';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Activite } from '../../models/activite';
import { SecteurActiviteService } from 'src/app/pages/secteur-activite/services/secteur-activite.service';
import { ActiviteService } from '../../services/activite.service';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  dataTableActiviteValue: Datum[] = [];

  dataTableSecteurActiviteValue: DatumSecteurActivite[] = [];

  activitePayload = new Activite();
  
  activiteForm = new FormGroup({
    libelle : new FormControl('', Validators.required),
    idSecteurActivite : new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  paramId:any;
  secteurId : any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private activiteService: ActiviteService, private secteurActiviteService : SecteurActiviteService, private router: Router, 
    private route: ActivatedRoute, private localStorageService: LocalStorageService) {
    this.findAllSecteurActivite();
   }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
  }

  get libelle() {
    return this.activiteForm?.get('libelle');
  }

  get idSecteurActivite() {
    return this.activiteForm?.get('idSecteurActivite');
  }

  findAllSecteurActivite(){
    this.secteurActiviteService.findAll().subscribe({
      next: (data) => {
        const response: DatumSecteurActivite[] = data;
        this.dataTableSecteurActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findById(id:any){
    this.activiteService.findById(id).subscribe({
      next: (data) => {
        this.activiteForm.get('libelle')?.setValue(data.libelle);
        this.secteurId = data.secteurActivite.id;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onUpdate(){
    this.isLoading = true;
    var libUpdate = this.activiteForm.get('libelle')?.value;
    this.activitePayload.libelle = libUpdate?.toUpperCase();
    this.activitePayload.idSecteurActivite = this.activiteForm.get('idSecteurActivite')?.value;
    this.activitePayload.creerPar = null;
    this.activitePayload.modifierPar = this.data.id;
    
    this.activiteService.update(this.activitePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Activité modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

}
