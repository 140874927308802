import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Datum } from './interfaces/categorie-response';
import { CategoriePayload } from './models/categorie-payload';
import { CategorieService } from './services/categorie.service';

declare let $: any;

@Component({
  selector: 'app-categorie-taxe',
  templateUrl: './categorie-taxe.component.html',
  styleUrls: ['./categorie-taxe.component.css']
})
export class CategorieTaxeComponent implements OnInit {

  categorie : any = "categorie";

  dataTableCategorieValue: Datum[] = [];

  categoriePayload = new CategoriePayload();

  categorieForm = new FormGroup({
    libelle : new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  table : any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private categorieService: CategorieService, private router: Router,
     private localStorageService: LocalStorageService) { 
    this.findAll();
  }

  ngOnInit(): void {
    /* var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/table-datatable.js";
    this.elementRef.nativeElement.appendChild(s); */
  }

  ngRedirectImport(){
    this.localStorageService.setItem("typeImport", this.categorie);
    this.router.navigate(["/import"])
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0, ':visible'],
          },
        },
      'colvis'
      ],
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'libelle'},
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
              <a class="btn btn-warning btn-sm rowId" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/categorie/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }
      ],

    });
  }

  get libelle() {
    return this.categorieForm?.get('libelle');
  }

  findAll(){
    this.categorieService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableCategorieValue = response;
        this.onDataTable(response);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onSubmit(){
    this.isLoading = true;
    this.categoriePayload.code = null;
    this.categoriePayload.libelle = this.categorieForm.get('libelle')?.value;
    this.categoriePayload.creerPar = this.data.id;
    this.categoriePayload.modifierPar = null;
    this.categorieService.save(this.categoriePayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Catégorie modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.categorieForm.reset();
        this.table.destroy();
        this.findAll();
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
    
  }

}
