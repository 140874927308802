import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment';
import { SearchContribuablePayload } from './models/search-contribuable-payload';
import { PaiementService } from './services/paiement.service';

declare let $: any;

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit {

  searchContribuablePayload = new SearchContribuablePayload()

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;

  table : any;

  etat : any;

  dataResponse : any;

  tableActiviteContribuable : boolean = false;
  tableLocalContribuable : boolean = false;

  searchForm = new FormGroup({
    element : new FormControl('', Validators.required)
  })
  DetailId: any;

  dataResult : any [] = [];

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private paiementService: PaiementService, private router: Router, private route: ActivatedRoute, 
    private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.searchForm.get('element')?.valueChanges.subscribe(x => {
      console.log(this.searchForm.get('element')?.value);
      this.onSearchContribuableByTelephone();
    });
  }

  get element() {
    return this.searchForm?.get('element');
  }

  //ActiviteContribuable
  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        {
          mRender: function (data: any, type: any, row: any) {
            return row.nom + ' ' + row.prenom
          },
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return row.telephone + ' / ' + row.contactUrgence
          },
        },
        { data: 'adresse' },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<a class="btn btn-secondary btn-sm activite" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" id="${row.id}"><i class="bi bi-eye-fill"> Activités</a>`);
          }
        }
        
      ],

    });

    this.routeActivite()
  }

  routeActivite(){
    const that = this;
    $('.activite').on('click', function (e: any) {
      var id = e.delegateTarget.id;
      that.router.navigateByUrl('paiement/contribuable/activite/' + id);
    });
  }

  


  onSearchContribuableByTelephone(){
    this.isLoading = true;
    
    var element = this.searchForm.get('element')?.value;
    this.paiementService.findByTelephone(element).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "";
        this.hasError = false;
        this.isLoading = false;
        this.localStorageService.setItem('ContribuableId', data.id);
        this.tableActiviteContribuable = true;
        if(this.table != null){
          this.table.destroy();
        }
        this.onDataTable(data);
      },
      error: (err) => {
        this.errorMessage = "";
        this.hasError = true;
        this.isLoading = false;
        this.tableActiviteContribuable = false;
      }
    })
  }

  

}
