import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContribuableService } from 'src/app/pages/contribuable/services/contribuable.service';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { PaiementService } from '../../services/paiement.service';

declare let $: any;

@Component({
  selector: 'app-activite-contribuable',
  templateUrl: './activite-contribuable.component.html',
  styleUrls: ['./activite-contribuable.component.css']
})
export class ActiviteContribuableComponent implements OnInit {

  ipAdress: string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;

  tenant: any  = this.localStorageService.getItem('tenantId');

  url: any = `${this.ipAdress}/${this.BaseUrl}locals/image/`;

  table : any;
  NomPrenom : any;
  tel:any;
  telUrgence: any;
  email : any;
  adresse : any;
  typePiece : any;
  numeroPiece : any;
  nationalite: any;
  paramId : any;
  data_activites: any;

  isActivite: boolean = true;
  isLocal : boolean = false;
  isTaxe : boolean = false;
  dataTableLocal: any;
  dataTableTaxe: any;

  currentDate : any = new Date();
  result: any;
  res: any;

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, 
    private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService, private paiementService: PaiementService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.localStorageService.setItem('activiteId', this.paramId);
    this.findById(this.paramId);
  }

  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'activite.libelle' },
        { data: 'activite.secteurActivite.libelle' },
        { data: 'numeroRegistreCommerce' },
        { data: 'numeroImatriculation' },
        {
          mRender: function (data: any, type: any, row: any) {
            return formatDate(row.dateCreationEntreprise, 'dd-MM-yyyy', 'en')
          },
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return formatDate(row.dateImmatriculation, 'dd-MM-yyyy', 'en')
          },
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<button class="btn btn-success btn-sm local" id="${row.id}" style="cursor:pointer; color: white; background-color: #1A345C !important;border-color: #1A345C !important;"><i class="bi bi-eye-fill"> Locaux</button>`);
          }
        }
        
      ],

    });
    this.routeLocal();
  }

  onCompareEcheance(echeance : any){
    if(!(this.currentDate.getDate() > echeance.getDate())){
      this.result = 0 + '%';
      this.res = false;
    }
    this.res = true;
  }

  routeLocal(){
    const that = this;
    $('.local').on('click', function (e: any) {
      var id = e.delegateTarget.id;
      //that.router.navigateByUrl('paiement/contribuable/local/' + id);
      that.onNavigateLocal(id);
    });
  }

  findById(id: any) {
    this.contribuableService.findById(id).subscribe({
      next: (data) => {
        this.NomPrenom = data.nom + ' ' + data.prenom;
        this.tel = data.telephone;
        this.telUrgence = data.contactUrgence;
        this.email = data.email;
        this.adresse = data.adresse;
        this.typePiece = data.typePiece;
        this.numeroPiece = data.numeroPiece;
        this.nationalite = data.nationalite;
        //this.etat = data.etat;
        this.data_activites = data.details;
        this.onDataTable(data.details);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onNavigateLocal(id : any){
    this.isActivite = false;
    this.isLocal = true;
    this.findByIdLocal(id);
  }

  findByIdLocal(id: any) {
    this.contribuableService.findByInfoLocal(id).subscribe({
      next: (data) => {
        console.log(data);
        
        this.dataTableLocal = data;
        console.log(this.dataTableLocal);
        
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onRedirectLocal(){
    this.isTaxe = false;
    this.isLocal = true;
  }

  onNavigateTaxe(id : any){
    this.isActivite = false;
    this.isLocal = false;
    this.isTaxe = true;
    this.findByIdTaxe(id);
  }

  findByIdTaxe(id: any) {
    this.paiementService.findTaxeContribuableById(id).subscribe({
      next: (data) => {
        this.dataTableTaxe = data;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
