import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { PaiementService } from '../../services/paiement.service';

declare let $: any;

@Component({
  selector: 'app-taxe-contribuable',
  templateUrl: './taxe-contribuable.component.html',
  styleUrls: ['./taxe-contribuable.component.css']
})
export class TaxeContribuableComponent implements OnInit {

  paramId: any;

  table: any;

  LocalId : any;
  ActiviteId : any;

  constructor(private elementRef: ElementRef, private paiementService: PaiementService, private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.localStorageService.setItem('taxeId', this.paramId);
    this.findById(this.paramId);
  }

  onDataTable(data: any) {
    const that = this;
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'taxe.activite.libelle' },
        { data: 'taxe.categorie.libelle' },
        { data: 'taxe.cout' },
        {
          mRender: function (data: any, type: any, row: any) {

            var currentDate = new Date();
            var penalite = 0;
            var echeance = new Date(row.taxe.echeance);
            if (currentDate.getDate() > echeance.getDate()) {
              penalite = row.taxe.penalite
              return penalite + '%';
            } else {
              penalite = 0;
              return penalite + '%';
            }
          },
        },
        { data: 'taxe.echeance' },
        {
          mRender: function (data: any, type: any, row: any) {
            return row.taxe.frequence
          },
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<a class="btn btn-danger btn-sm edit" style="cursor:pointer;" id="${row.id}">Payer</a>`);
          }
        }

      ],

    });
    this.routeEdit();
  }


  routeEdit() {
    const that = this;
    $('.edit').on('click', function (e: any) {
      var id = e.delegateTarget.id
      that.router.navigateByUrl('paiement/regler/' + id);
    });
  }


  onFrequence(freq: any) {
    var frequenceText;
    switch (freq) {
      case '100':
        frequenceText = 'Journalière'
        break;
      case '200':
        frequenceText = 'Mensuelle'
        break;
      case '300':
        frequenceText = 'Trimestrielle'
        break;
      case '400':
        frequenceText = 'Semestrielle'
        break;
      default:
        frequenceText = 'Annuelle'
        break;
    }
  }

  findById(id: any) {
    this.paiementService.findTaxeContribuableById(id).subscribe({
      next: (data) => {
        this.onDataTable(data);
        this.LocalId = this.localStorageService.getItem('localId');
        this.ActiviteId = this.localStorageService.getItem('activiteId');
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
