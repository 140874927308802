<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Profils</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Profils</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col-lg-7">
        <div class="card raduis-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">Profils</h5>
                </div>
                <div class="table-responsive mt-3">
                    <table id="example2" class="table align-middle">
                        <thead class="table-secondary" class="table-primary">
                            <tr>
                                <th>Profil</th>
                                <th>Type</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5">
        <div class="card">
            <div class="card-body">
                <h4 class="text-center">Ajouter un profil</h4>
                <form novalidate class="row" [formGroup]="action1From" (ngSubmit)="onSubmit()">
                    <div class="col-12 alert border-0 border-success border-start border-4 bg-light-success alert-dismissible fade show py-2 mb-2"
                        *ngIf="hasError">
                        <div class="d-flex align-items-center">
                            <div class="fs-3 text-success"><i class="bi bi-x-fill"></i>
                            </div>
                            <div class="ms-3">
                                <div class="text-success">{{successMessage}}</div>
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="col-12 alert border-0 border-danger border-start border-4 bg-light-danger alert-dismissible fade show py-2 mb-2"
                        *ngIf="hasError">
                        <div class="d-flex align-items-center">
                            <div class="fs-3 text-danger"><i class="bi bi-x-circle-fill"></i>
                            </div>
                            <div class="ms-3">
                                <div class="text-danger">{{errorMessage}}</div>
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="col-12 mb-2">
                        <label class="form-label">Nom du profil</label>
                        <input type="text" class="form-control" style="height: 35px;" placeholder="Nom du profil"
                            formControlName="name">
                        <div class="text-danger" *ngIf="!name?.valid && (name?.dirty ||name?.touched)">Le
                            libelle est requis</div>
                    </div>
                    <div class="col-12 mb-2">
                        <label class="form-label">Type du profil</label>
                        <select name="" id="" class="form-control" style="height: 35px;" formControlName="type">
                            <option *ngFor="let type of typeArray" value=" {{type.libelle}} ">{{type.libelle}}</option>
                        </select>
                        <div class="text-danger" *ngIf="!type?.valid && (type?.dirty ||type?.touched)">Le
                            type est requis</div>
                    </div>
                    <div class="col-12 mb-2">
                        <div class="row">
                            <div class="col-md-5">
                                <label class="form-label">Rôles disponibles</label>
                                <select class="multiple-select" multiple="multiple" class="form-control"
                                    formControlName="label1">
                                    <option *ngFor="let item of arrayMenu" value="{{item.label}}">{{item.label}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1" style="margin: 40px 10px 10px 10px;">
                                <div class="row mb-1">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="onAdd()"><i
                                            class="bi bi-chevron-double-right"></i></button>
                                </div>
                                <div class="row">
                                    <button type="button" class="btn btn-primary btn-sm" (click)="onRemoveArray2()"><i
                                            class="bi bi-chevron-double-left"></i></button>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <label class="form-label">Rôles selectionnés</label>
                                <select class="multiple-select" multiple="multiple" class="form-control"
                                    formControlName="labelRemove">
                                    <option *ngFor="let el of array2" value="{{el}}">{{el}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="text-align: right;">
                        <button type="submit" *ngIf="!isLoading" class="btn btn-primary btn-sm"><i
                                class="bi bi-plus-circle"></i>
                            Valider</button>
                        <button *ngIf="isLoading" class="btn btn-primary btn-sm" type="button" disabled=""><span
                                class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choississez les actions</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form novalidate class="row" [formGroup]="action2From">
                    <div class="alert border-0 border-danger border-start border-4 bg-light-danger alert-dismissible fade show py-2"
                        *ngIf="alert2">
                        <div class="d-flex align-items-center">
                            <div class="fs-3 text-danger"><i class="bi bi-x-circle-fill"></i>
                            </div>
                            <div class="ms-3">
                                <div class="text-danger">Veuillez selectioner l'action</div>
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="col-12 mb-2">
                        <div class="row">
                            <div class="col-md-5">
                                <label class="form-label">Actions disponibles</label>
                                <select class="multiple-select" multiple="multiple" class="form-control"
                                    formControlName="label2">
                                    <option *ngFor="let action of arrayAction" value="{{action.label}},{{action.code}}">
                                        {{action.label}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1" style="margin: 40px 10px 10px 10px;">
                                <div class="row mb-1">
                                    <button class="btn btn-primary btn-sm" (click)="onAddAction2()"><i
                                            class="bi bi-chevron-double-right"></i></button>
                                </div>
                                <div class="row">
                                    <button class="btn btn-primary btn-sm" (click)="onRemoveArrayAction2()"><i
                                            class="bi bi-chevron-double-left"></i></button>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <label class="form-label">Actions selectionnés</label>
                                <select class="multiple-select" multiple="multiple" class="form-control"
                                    formControlName="label2Remove">
                                    <option *ngFor="let el of arrayAction2" value="{{el}}">{{el}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i
                        class="bi bi-x-lg"></i> Annuler</button>
                <button class="btn btn-primary btn-sm" (click)="onAddArray2()"><i class="bi bi-plus-circle"></i>
                    confirmer</button>
            </div>
        </div>
    </div>
</div>