import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Datum } from '../../interfaces/categorie-response';
import { CategoriePayload } from '../../models/categorie-payload';
import { CategorieService } from '../../services/categorie.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  dataTableCategorieValue: Datum[] = [];

  categoriePayload = new CategoriePayload();

  categorieForm = new FormGroup({
    libelle : new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  paramId : any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private categorieService: CategorieService, private router: Router, private route: ActivatedRoute,
     private localStorageService: LocalStorageService) { 
  }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
  }

  get libelle() {
    return this.categorieForm?.get('libelle');
  }

  findById(id:any){
    this.categorieService.findById(id).subscribe({
      next: (data) => {
        this.categorieForm?.get('libelle')?.setValue(data.libelle);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onUpdate(){
    this.isLoading = true;
    this.categoriePayload.code = null;
    this.categoriePayload.libelle = this.categorieForm.get('libelle')?.value;
    this.categoriePayload.creerPar = null;
    this.categoriePayload.modifierPar = this.data.id;
    this.categorieService.update(this.categoriePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Catégorie modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
    
  }

}
