import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { data } from 'jquery';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { ContribuableService } from '../../services/contribuable.service';

declare let $: any;

@Component({
  selector: 'app-view-local',
  templateUrl: './view-local.component.html',
  styleUrls: ['./view-local.component.css']
})
export class ViewLocalComponent implements OnInit {

  paramId: any;

  table: any;

  dataLocal: any = [];

  ipAdress: string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;

  tenant: any  = this.localStorageService.getItem('tenantId');

  url: any = `${this.ipAdress}/${this.BaseUrl}locals/image/`;

  IdContribuable:any;

  IdActivite : any;

  constructor(private elementRef: ElementRef, private contribuableService: ContribuableService, private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.localStorageService.setItem('idLocal', this.paramId);
    this.findById(this.paramId);
  }

  onDataTable(data: any) {
    const that = this;
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<img class="product-img-2" src="${that.url}${row.id}?tenant=${that.tenant}">
            `)
          }
        },
        { data: 'nom' },
        {
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.ilot != null){
              res = row.ilot
            }else {
              res = 'Aucune information'
            }
            return res;
          }
        },
        { 
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.lot != null){
              res = row.lot
            }else {
              res = 'Aucune information'
            }
            return res;
          }
         },
        { 
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.longitude != null){
              res = row.longitude
            }else {
              res = 'Aucune information'
            }
            return res;
          }
        },
        {
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.latitude != null){
              res = row.latitude
            }else {
              res = 'Aucune information'
            }
            return res;
          }
        },
        {
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.pointRepere != null){
              res = row.pointRepere
            }else {
              res = 'Aucune information'
            }
            return res;
          }
        },
        { data: 'quartier' },
        {
          mRender: function (data: any, type: any, row: any) {
            var res;
            if(row.lien_localisation != null){
              res = row.lien_localisation
            }else {
              res = 'Aucune information'
            }
            return res;
          }},
        {
          mRender: function (data: any, type: any, row: any) {
            return (`
            <a class="taxe btn btn-success btn-sm" style="cursor:pointer; color: white; background-color: #7CA1C4 !important;border-color: #7CA1C4 !important;" href="/contribuable/local/taxe/${row.id}"><i class="bi bi-eye-fill"></i> Les Taxes</a>
            <a class="edit btn btn-warning btn-sm" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/contribuable/local/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>`);
          }
        }

      ],

    });
  }



  findById(id: any) {
    this.contribuableService.findByInfoLocal(id).subscribe({
      next: (data) => {
        this.onDataTable(data);
        this.IdContribuable =  this.localStorageService.getItem('idContribuable');
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

}
