import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  menuList: Observable<any> | undefined;

  dataProfileValue: any[] = [];

  constructor(private localStorageService: LocalStorageService) { }



  ngOnInit(): void {

    this.dataProfileValue = JSON.parse(this.localStorageService.getItem('pages'));
  }

}
