import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ActiviteService } from 'src/app/pages/activite/services/activite.service';
import { CategorieService } from 'src/app/pages/categorie-taxe/services/categorie.service';
import { Datum } from '../../interfaces/taxe-response';
import { TaxePayload } from '../../models/taxe-payload';
import { TaxeService } from '../../services/taxe.service';
import { Datum as DatumActivite } from '../../../activite/interfaces/activite-response';
import { Datum as DatumCategorie } from '../../../categorie-taxe/interfaces/categorie-response';

import { LocalStorageService } from 'src/app/storage/LocalStorageService';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  dataTableTaxeValue: Datum[] = [];

  dataTableActiviteValue: DatumActivite[] = [];

  dataTableCategorieValue: DatumCategorie[] = [];

  dataFrequence : any = [
    {"titre" : "Journaliere", "valeur" : "100"},
    {"titre" : "Mensuelle", "valeur" : "200"},
    {"titre" : "Trimestrielle", "valeur" : "300"},
    {"titre" : "Semestrielle", "valeur" : "400"},
    {"titre" : "Annuelle", "valeur" : "500"}
  ]

  taxePayload = new TaxePayload();

  taxeForm = new FormGroup({
    cout: new FormControl('', Validators.required),
    penalite: new FormControl('', Validators.required),
    frequence: new FormControl('', Validators.required),
    echeance: new FormControl('', Validators.required),
    idActivite: new FormControl('', Validators.required),
    idCategorie: new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  successMessage: any;
  paramId : any;
  activiteId:any;
  categorieId:any;
  frequenceTitre: any;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private taxeService: TaxeService, private activiteService: ActiviteService, private categorieService: CategorieService,
     private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) { 
      this.findAllActivite();
      this.findAllCategorie();
     }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.findById(this.paramId);
  }

  get cout() {
    return this.taxeForm?.get('cout');
  }

  get penalite() {
    return this.taxeForm?.get('penalite');
  }

  get frequence() {
    return this.taxeForm?.get('frequence');
  }

  get echeance() {
    return this.taxeForm?.get('echeance');
  }

  get idActivite() {
    return this.taxeForm?.get('idActivite');
  }

  get idCategorie() {
    return this.taxeForm?.get('idCategorie');
  }

  findAllActivite() {
    this.activiteService.findAll().subscribe({
      next: (data) => {
        const response: DatumActivite[] = data;
        this.dataTableActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findAllCategorie() {
    this.categorieService.findAll().subscribe({
      next: (data) => {
        const response: DatumCategorie[] = data;
        this.dataTableCategorieValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findById(id:any){
    this.taxeService.findById(id).subscribe({
      next: (data) => {
        this.taxeForm.get('cout')?.setValue(data.cout);
        this.taxeForm.get('penalite')?.setValue(data.penalite);
        this.taxeForm.get('echeance')?.setValue(data.echeance);
        this.activiteId =  data.activite.id;
        this.categorieId = data.categorie.id;
        this.frequenceTitre = data.frequence;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onUpdate(){
    this.isLoading = true;
    this.taxePayload.cout = this.taxeForm.get('cout')?.value;
    this.taxePayload.frequence = this.taxeForm.get('frequence')?.value;
    this.taxePayload.penalite = this.taxeForm.get('penalite')?.value;
    this.taxePayload.echeance = this.taxeForm.get('echeance')?.value;
    this.taxePayload.idActivite = this.taxeForm.get('idActivite')?.value;
    this.taxePayload.idCategorie = this.taxeForm.get('idCategorie')?.value;
    this.taxePayload.creerPar = null;
    this.taxePayload.modifierPar = this.data.id;
    this.taxeService.update(this.taxePayload, this.paramId).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Taxe modifié avec succès.";
        this.hasError = false;
        this.isLoading = false;
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
    
  }


}
