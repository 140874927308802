import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContribuableService } from '../../services/contribuable.service';
import { Datum, Datum as DatumActivite } from '../../../activite/interfaces/activite-response';
import { ActiviteContribuablePayload } from '../../models/contribuable-payload';
import { ActiviteService } from 'src/app/pages/activite/services/activite.service';
import { LocalPayload } from '../../models/local-payload';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';


declare let $: any;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  

  dataTableActiviteValue : DatumActivite[] = [];

  paramId:any;
  table : any;
  NomPrenom : any;
  tel:any;
  telUrgence: any;
  email : any;
  adresse : any;
  ville : any; 
  typePiece : any;
  numeroPiece : any;
  nationalite: any;
  etat : any;

  

  hasErrorActivite: boolean = false;
  hasSuccessActivite: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  errorMessageActivite: any;
  successMessageActivite: any;

  errorMessage: any;
  successMessage: any;
  DetailId :any;

  activiteContribuablePayload = new ActiviteContribuablePayload();

  localContribuablePayload = new LocalPayload();

  activiteContribuableForm = new FormGroup({
    numeroImmatriculation: new FormControl('', Validators.required),
    numeroRegistreCommerce: new FormControl(''),
    idActivite: new FormControl('', Validators.required),
    dateCreationEntreprise: new FormControl('', Validators.required),
    dateImmatriculation: new FormControl('', Validators.required)
  });

  localContribuableForm = new FormGroup({
    nom: new FormControl('', Validators.required),
    ilot: new FormControl(''),
    lot: new FormControl(''),
    longitude: new FormControl(''),
    latitude: new FormControl(''),
    pointRepere: new FormControl(''),
    quartier: new FormControl('', Validators.required),
    lien_localisation: new FormControl(''),
    photoLocal: new FormControl(''),
    fileSource: new FormControl(),

  })

  
  data : any = JSON.parse(this.localStorageService.getItem('user'));

  constructor(private elementRef: ElementRef, private activiteService: ActiviteService, private contribuableService: ContribuableService, private router: Router, 
    private route: ActivatedRoute, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.localStorageService.setItem('idContribuable', this.paramId);
    this.findById(this.paramId);
    this.findAllActivite();
  }

  //Activite Contribuable
  get numeroImmatriculation() {
    return this.activiteContribuableForm?.get('numeroImmatriculation');
  }
  get numeroRegistreCommerce() {
    return this.activiteContribuableForm?.get('numeroRegistreCommerce');
  }
  get idActivite() {
    return this.activiteContribuableForm?.get('idActivite');
  }
  get dateCreationEntreprise() {
    return this.activiteContribuableForm?.get('dateCreationEntreprise');
  }
  get dateImmatriculation() {
    return this.activiteContribuableForm?.get('dateImmatriculation');
  }

  //Local Contribuable
  get nom() {
    return this.localContribuableForm?.get('nom');
  }
  get ilot() {
    return this.localContribuableForm?.get('ilot');
  }
  get lot() {
    return this.localContribuableForm?.get('lot');
  }

  get longitude() {
    return this.localContribuableForm?.get('longitude');
  }
  get latitude() {
    return this.localContribuableForm?.get('latitude');
  }
  get pointRepere() {
    return this.localContribuableForm?.get('pointRepere');
  }
  get quartier() {
    return this.localContribuableForm?.get('quartier');
  }
  get lien_localisation() {
    return this.localContribuableForm?.get('lien_localisation');
  }
  get photoLocal() {
    return this.localContribuableForm?.get('photoLocal');
  }
  get idDetailContribuable() {
    return this.localContribuableForm?.get('idDetailContribuable');
  }


  onDataTable(data: any) {
    this.table = $('#example2').DataTable({
      lengthChange: false,
      "language": {
        "sLengthMenu": "Display _MENU_ records per page",
        "sZeroRecords": "Aucune donnée trouvée - désolé !",
        "sInfo": "Tableau Montrant _START_ à _END_ éléments sur un Total: _TOTAL_ ",
        "sInfoEmpty": "Tableau Montrant 0 à 0 sur 0 éléments",
        "sInfoFiltered": "(filtré sur un total de _MAX_ )",
        "paginate": {
          "previous": "Retour",
          "next": "Suivant"
        }

      },
      "bAutoWidth": false,
      data: data,
      columns: [
        { data: 'activite.libelle' },
        { data: 'activite.secteurActivite.libelle' },
        { data: 'numeroRegistreCommerce' },
        { data: 'numeroImatriculation' },
        {
          mRender: function (data: any, type: any, row: any) {
            return formatDate(row.dateCreationEntreprise, 'dd-MM-yyyy', 'en')
          },
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return formatDate(row.dateImmatriculation, 'dd-MM-yyyy', 'en')
          },
        },
        {
          mRender: function (data: any, type: any, row: any) {
            return (`<a class="edit btn btn-warning btn-sm" style="cursor:pointer; color: white; background-color: #E94E1B !important;border-color: #E94E1B !important;" href="/contribuable/activite/edit/${row.id}"><i class="bi bi-pencil-fill"></i> Modifier</a>
            <a class="btn btn-success btn-sm viewLocal" href="/contribuable/viewlocal/${row.id}"style="cursor:pointer; color: white; background-color: #1A345C !important;border-color: #1A345C !important;"><i class="bi bi-eye-fill"></i> Ses locaux</a>
            <a class="btn btn-secondary btn-sm  addlocalite" style="cursor:pointer; color: white; background-color: #7CA1C4 !important;border-color: #7CA1C4 !important;" id="${row.id}"><i class="bi bi-house"></i> Ajouter local</a>`);
          }
        }
        
      ],

    });
    this.onModalbtn();
  }

  onModalbtn(){
    const that = this;
    $('.addlocalite').on('click', function (e: any) {
      that.onViewModal();
      that.DetailId = e.delegateTarget.id;
    });
  }

  onViewModal(){
    $('#addlocaliteModal').modal('show');
  }

  findAllActivite() {
    this.activiteService.findAll().subscribe({
      next: (data) => {
        const response: Datum[] = data;
        this.dataTableActiviteValue = response;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  findById(id: any) {
    this.contribuableService.findById(id).subscribe({
      next: (data) => {
        this.NomPrenom = data.nom + ' ' + data.prenom;
        this.tel = data.telephone;
        this.telUrgence = data.contactUrgence;
        this.email = data.email;
        this.adresse = data.adresse;
        this.ville = data.ville;
        this.typePiece = data.typePiece;
        this.numeroPiece = data.numeroPiece;
        this.nationalite = data.nationalite;
        this.etat = data.etat;
        this.onDataTable(data.details);
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onAddActivite(){
    this.isLoading = true;
    this.activiteContribuablePayload.numeroImmatriculation = this.activiteContribuableForm.get('numeroImmatriculation')?.value;
    this.activiteContribuablePayload.numeroRegistreCommerce = this.activiteContribuableForm.get('numeroRegistreCommerce')?.value;
    this.activiteContribuablePayload.idActivite = this.activiteContribuableForm.get('idActivite')?.value;
    this.activiteContribuablePayload.dateCreationEntreprise = this.activiteContribuableForm.get('dateCreationEntreprise')?.value;
    this.activiteContribuablePayload.dateImmatriculation = this.activiteContribuableForm.get('dateImmatriculation')?.value;
    this.activiteContribuablePayload.idContribuable = this.paramId;

    this.activiteContribuablePayload.creerPar = this.data.id;
    this.activiteContribuablePayload.modifierPar = null;

    this.contribuableService.saveActiviteContribuable(this.activiteContribuablePayload).subscribe({
      next: (data) => {
        this.hasSuccessActivite = true;
        this.successMessageActivite = "Activité contribuable ajouté avec succès.";
        this.hasErrorActivite = false;
        this.isLoading = false;
        this.activiteContribuableForm.reset();
        this.table.destroy();
        this.findById(this.paramId);
      },
      error: (err) => {
        this.successMessageActivite = "Une erreur s'est produite.";
        this.hasErrorActivite = true;
        this.isLoading = false;
      }
    })
    
  }

  // Image Preview
  showPreview(event : any) {
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.localContribuableForm.patchValue({
          fileSource: reader.result
        });

      };
    }
  }

  onAddLocalite(){
    this.isLoading = true;
    var dataImage = this.localContribuableForm.get('fileSource')?.value.split(",")[1];
    this.localContribuablePayload.nom = this.localContribuableForm.get('nom')?.value;
    this.localContribuablePayload.ilot = this.localContribuableForm.get('ilot')?.value;
    this.localContribuablePayload.longitude = this.localContribuableForm.get('longitude')?.value;
    this.localContribuablePayload.latitude = this.localContribuableForm.get('latitude')?.value;
    this.localContribuablePayload.pointRepere = this.localContribuableForm.get('pointRepere')?.value;
    this.localContribuablePayload.quartier = this.localContribuableForm.get('quartier')?.value;
    this.localContribuablePayload.lien_localisation = this.localContribuableForm.get('lien_localisation')?.value;
    this.localContribuablePayload.photoLocal = dataImage;
    this.localContribuablePayload.idDetailContribuable = this.DetailId;

    this.localContribuablePayload.creerPar = this.data.id;
    this.localContribuablePayload.modifierPar = null;
    
    this.contribuableService.saveLocaliteContribuable(this.localContribuablePayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Local contribuable ajouté avec succès.";
        this.hasError = false;
        this.isLoading = false;
        this.localContribuableForm.reset();
        this.table.destroy();
        this.findById(this.paramId);
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      }
    })
  }

}
