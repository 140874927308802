import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParametreService } from './services/parametre.service';
import { ParametrePayload } from './models/parametre-payload';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';

declare let $: any;

@Component({
  selector: 'app-parametre',
  templateUrl: './parametre.component.html',
  styleUrls: ['./parametre.component.css']
})
export class ParametreComponent implements OnInit {

  parametrePayload = new ParametrePayload();

  parametreForm = new FormGroup({
    nom: new FormControl('', Validators.required),
    libelle: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    valeur: new FormControl('', Validators.required)
  });

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  isLoadingDesac: boolean = false;
  isLoadingGenerate: boolean = false;
  errorMessage: any;
  successMessage: any;
  table: any;
  cronForm: FormControl<string | null> | undefined;

  data : any = JSON.parse(this.localStorageService.getItem('user'));

  dataTable : any;

  constructor(private elementRef: ElementRef, private parametreService: ParametreService, private router: Router, 
    private localStorageService:LocalStorageService) {
    this.findByType();
  }

  ngOnInit(): void {
    /* var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/table-datatable.js";
    this.elementRef.nativeElement.appendChild(s); */
    this.cronForm = new FormControl('0 0 1/1 * *');
  }

  get nom() {
    return this.parametreForm?.get('nom');
  }
  get libelle() {
    return this.parametreForm?.get('libelle');
  }
  get type() {
    return this.parametreForm?.get('type');
  }
  get valeur() {
    return this.parametreForm?.get('valeur');
  }

  findByType() {
    var type = "APPLICATION"
    this.parametreService.findByType(type).subscribe({
      next: (data) => {
        console.log(data);
        this.table = data;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  onActive(dataParametre : any){
    this.isLoading = true;
    this.parametrePayload.libelle = dataParametre.libelle;
    this.parametrePayload.nom = dataParametre.nom;
    this.parametrePayload.type = dataParametre.type;
    this.parametrePayload.valeur = '1';
    this.parametreService.update(this.parametrePayload, dataParametre.id).subscribe({
      next: (data) => {
        console.log(data);
        this.isLoading = false;
        this.findByType();
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err);
      },
    })
  }

  onDesactive(dataParametre : any){
    this.isLoadingDesac = true;
    this.parametrePayload.libelle = dataParametre.libelle;
    this.parametrePayload.nom = dataParametre.nom;
    this.parametrePayload.type = dataParametre.type;
    this.parametrePayload.valeur = '0';
    this.parametreService.update(this.parametrePayload, dataParametre.id).subscribe({
      next: (data) => {
        console.log(data);
        this.isLoadingDesac = false;
        this.findByType();
      },
      error: (err) => {
        this.isLoadingDesac = false;
        console.log(err);
      },
    })
  }


  onGenerateTaxe(){
    this.isLoadingGenerate = true;
    this.parametreService.generateTaxe().subscribe({
      next: (data) => {
        console.log(data);
        this.isLoadingGenerate = false;
        this.hasSuccess = true;
        this.successMessage = "Generation de taxes éffectués avec succés.";
        this.hasError = false;
        this.findByType();
      },
      error: (err) => {
        this.isLoadingGenerate = false;
        this.hasSuccess = false;
        this.hasError = true;
        this.errorMessage = "Une erreur s'est produite.";
        console.log(err);
      },
    })
  }

}
