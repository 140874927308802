<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div class="breadcrumb-title pe-3">Contribuables</div>
    <div class="ps-3">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a>
                </li>
                <li class="breadcrumb-item" aria-current="page"><a routerLink="/contribuable">Contribuables</a></li>
                <li class="breadcrumb-item active" aria-current="page">Enrôlement</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col-xl-12 mx-auto">
        <div class="alert alert-success mt-3" role="alert" *ngIf="hasSuccess">
            {{successMessage}}
        </div>
        <div class="alert alert-danger mt-3" role="alert" *ngIf="hasError">
            {{errorMessage}}
        </div>
        <div id="multi-step-form-container">
            <!-- Form Steps / Progress Bar -->
            <ul class="form-stepper form-stepper-horizontal text-center mx-auto pl-0">
                <!-- Step 1 -->
                <li class="form-stepper-active text-center form-stepper-list" step="1">
                    <a class="mx-2">
                        <span class="form-stepper-circle">
                            <span>1</span>
                        </span>
                        <div class="label">Etape 1</div>
                    </a>
                </li>
                <!-- Step 2 -->
                <li class="form-stepper-unfinished text-center form-stepper-list" step="2">
                    <a class="mx-2">
                        <span class="form-stepper-circle text-muted">
                            <span>2</span>
                        </span>
                        <div class="label text-muted">Etape 2</div>
                    </a>
                </li>
            </ul>
            <!-- Step Wise Form Content -->
            <form class="mx-1 mx-md-6" id="userAccountSetupForm" name="userAccountSetupForm"
                enctype="multipart/form-data" method="POST">
                <!-- Step 1 Content -->
                <section id="step-1" class="form-step" novalidate [formGroup]="infoContribuableForm">
                    <h2 class="font-normal">Etape 1</h2>
                    <!-- Step 1 input fields -->
                    <div class="row">
                        <div class="col-lg-6 banner-content align-self pr-lg-5">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Nom</label>
                                <input type="text" style="height: 35px;" id="form3Example1c" class="form-control"
                                    placeholder="Ex: Akesse" formControlName="nom" />
                                <div class="ms-3" *ngIf="!nom?.valid && (nom?.dirty ||nom?.touched)">
                                    <div class="text-danger">Le champs nom est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Prénoms</label>
                                <input type="text" style="height: 35px;" id="form3Example1c" class="form-control"
                                    placeholder="Ex: Akesse Fofana" formControlName="prenom" />
                                <div class="ms-3" *ngIf="!prenom?.valid && (prenom?.dirty ||prenom?.touched)">
                                    <div class="text-danger">Le champs prenom est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Nationalité</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: Ivoirienne" formControlName="nationalite" />
                                <div class="ms-3"
                                    *ngIf="!nationalite?.valid && (nationalite?.dirty ||nationalite?.touched)">
                                    <div class="text-danger">Le champs nationalite est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Type de pièce</label>
                                <select style="height: 35px;" class="single-select form-control"
                                    formControlName="typePiece">
                                    <option *ngFor="let type of typePieceList" value="{{type.libelle}}">{{type.libelle}}
                                    </option>
                                </select>
                                <div class="ms-3" *ngIf="!typePiece?.valid && (typePiece?.dirty ||typePiece?.touched)">
                                    <div class="text-danger">Le champs type de papier est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Numéro {{textPiece}}</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 00000000000" formControlName="numeroPiece"
                                    minlength={{minNumeroPiece}} maxlength={{maxNumeroPiece}} />
                                <div class="ms-3"
                                    *ngIf="!numeroPiece?.valid && (numeroPiece?.dirty ||numeroPiece?.touched)">
                                    <div class="text-danger">Le champs Numéro pièce d'identite est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 banner-content align-self pr-lg-5">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Téléphone</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 000000000" formControlName="telephone" minlength=10 maxlength=10/>
                                <div class="ms-3" *ngIf="!telephone?.valid && (telephone?.dirty ||telephone?.touched)">
                                    <div class="text-danger">Le champs téléphone est requis</div>
                                    <div class="text-danger">Le téléphone doit être de 10 chiffres</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Contact d'urgence</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 000000000" formControlName="contact" minlength=10 maxlength=10 />
                                    <div class="ms-3" *ngIf="conbool">
                                        <div class="text-danger">Le contact doit être de 10 chiffres</div>
                                    </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Ville</label>
                                <select style="height: 35px;" class="single-select form-control"
                                    formControlName="ville">
                                    <option *ngFor="let villeci of dataVille" value="{{villeci.city}}">{{villeci.city}}
                                    </option>
                                </select>
                                <div class="ms-3" *ngIf="!ville?.valid && (ville?.dirty ||ville?.touched)">
                                    <div class="text-danger">Le champs ville est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Quatier d'habitation</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: Cocody" formControlName="adresse" />
                                <div class="ms-3" *ngIf="!adresse?.valid && (adresse?.dirty ||adresse?.touched)">
                                    <div class="text-danger">Le champs quatier est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Email</label>
                                <input type="email" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: Tax@gmail.com" formControlName="email" />
                                <div class="ms-3" *ngIf="!email?.valid && (email?.dirty ||email?.touched)">
                                    <div class="text-danger">Le champs email est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" style="text-align: right;">
                        <button class="btn btn-style btn-primary btn-navigate-form-step btn-sm"
                            [disabled]="!infoContribuableForm.valid" style="text-align: left;" type="button"
                            step_number="2">Suivant <i class="bi bi-arrow-right-short"></i></button>
                    </div>
                </section>
                <!-- Step 2 Content, default hidden on page load. -->
                <section id="step-2" class="form-step d-none" novalidate [formGroup]="activitecontribuableForm">
                    <h2 class="font-normal">Etape 2</h2>
                    <!-- Step 2 input fields -->
                    <div class="row">
                        <div class="col-lg-6 banner-content align-self pr-lg-5">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">N° Régis de commerce</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: 45855858..." formControlName="numeroRegistreCommerce" />
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">N° Immatriculation</label>
                                <input type="text" style="height: 35px;" id="form3Example3c" class="form-control"
                                    placeholder="Ex: M5585255TAX" formControlName="numeroImmatriculation" />
                                <div class="ms-3"
                                    *ngIf="!numeroImmatriculation?.valid && (numeroImmatriculation?.dirty ||numeroImmatriculation?.touched)">
                                    <div class="text-danger">Le champs numero immatriculation est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Activité</label>
                                <select style="height: 35px;" class="single-select form-control"
                                    formControlName="idActivite">
                                    <option *ngFor="let activite of dataTableActiviteValue" value="{{activite.id}}">
                                        {{activite.libelle}}</option>
                                </select>
                                <div class="ms-3"
                                    *ngIf="!idActivite?.valid && (idActivite?.dirty ||idActivite?.touched)">
                                    <div class="text-danger">Le champs activite est requis</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 banner-content align-self pr-lg-5">
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Date de Création</label>
                                <input type="date" style="height: 35px;" id="form3Example3c" class="form-control"
                                    formControlName="dateCreationEntreprise" min="1960-01-01" max="{{today}}" />
                                <div class="ms-3"
                                    *ngIf="!dateCreationEntreprise?.valid && (dateCreationEntreprise?.dirty ||dateCreationEntreprise?.touched)">
                                    <div class="text-danger">Le champs date de création est requis</div>
                                </div>
                            </div>
                            <div class="form-outline flex-fill mb-2">
                                <label class="form-label" for="form3Example1c">Date d'Immatriculation</label>
                                <input type="date" style="height: 35px;" id="form3Example3c" class="form-control"
                                     formControlName="dateImmatriculation" min="1960-01-01" max="{{today}}" />
                                <div class="ms-3"
                                    *ngIf="!dateImmatriculation?.valid && (dateImmatriculation?.dirty ||dateImmatriculation?.touched)">
                                    <div class="text-danger">Le champs date immatriculation est requis</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" style="text-align: right;">
                        <button class="btn btn-style btn-secondary btn-navigate-form-step btn-sm m-2" type="button"
                            step_number="1"><i class="bi bi-arrow-left-short"></i> Précédent</button>
                        <button *ngIf="!isLoading" class="btn btn-style btn-success btn-sm" style="text-align: left;"
                            (click)="onSubmit()" [disabled]="!activitecontribuableForm.valid"><i
                                class="bi bi-plus-circle"></i>
                            Soumettre</button>
                        <button *ngIf="isLoading" class="btn btn-success" type="button" disabled=""><span
                                class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </div>
                </section>
            </form>
        </div>
    </div>
</div>