import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { ChangePayload } from '../models/change-payload';
import { UtilisateurPayload } from '../models/utilisateur-payload';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {


  
  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }
  
  constructor(private http : HttpClient,private localStorageService:LocalStorageService) { }

  save(payload: UtilisateurPayload):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}users`, payload, this.onHeader());
  }

  change(payload: ChangePayload):Observable<any> {
    return this.http.post<any>(`${this.ipAdress}/${this.BaseUrl}users/password/change`, payload, this.onHeader());
  }

  update(payload: UtilisateurPayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}users/${id}`, payload, this.onHeader());
  }

  findById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}users/${id}`, this.onHeader());
    
  }
  
  findAll():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}users`, this.onHeader());
  }

}


