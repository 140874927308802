export class ContribuablePayload {
    nom : any;
    prenom : any;
    telephone : any;
    contact : any;
    email : any;
    typePiece : any;
    numeroPiece : any;
    nationalite : any;
    adresse: any;
    ville : any;
    detailRequest : ActiviteContribuablePayload | undefined;
    etat : any;
    motPasse:any;
    creerPar : any;
    modifierPar : any;
}

export class ActiviteContribuablePayload{
    numeroImmatriculation : any;
    numeroRegistreCommerce : any;
    idActivite : any;
    dateCreationEntreprise : any;
    dateImmatriculation : any;
    idContribuable : any | undefined;
    creerPar : any;
    modifierPar : any;
    //quatier : any;
}