import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { SecteurActivitePayload } from '../models/secteur-activite-payload';

@Injectable({
  providedIn: 'root'
})
export class SecteurActiviteService {

  
  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
    constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }

  save(payload: SecteurActivitePayload):Observable<any> {
    return this.http.post<SecteurActivitePayload>(`${this.ipAdress}/${this.BaseUrl}secteurs`, payload, this.onHeader());
  }

  findAll():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}secteurs`, this.onHeader());
  }

  findById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}secteurs/${id}`, this.onHeader());
  }

  update(payload: SecteurActivitePayload, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}secteurs/${id}`, payload, this.onHeader());
  }
}
