import { LocalStorageService } from './storage/LocalStorageService';
import { Component, ElementRef } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { debug } from './utility/module';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'admin';
  constructor(private localStorageService: LocalStorageService, private translate: TranslateService, private elementRef: ElementRef, public _router: Router,) {
    this.translate.addLangs(['fr', 'en']);
    this.translate.setDefaultLang('fr');
    const browserLang = this.translate.getBrowserLang();
    const lang = this.localStorageService.getItem("lang");
    debug(lang)
    this.translate.use(lang != "" ? lang : 'fr');
  }

  ngOnInit() {
    var p = document.createElement("script");
    p.type = "text/javascript";
    p.src = "../assets/js/app.js";
    this.elementRef.nativeElement.appendChild(p);

    
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "../assets/js/index.js";
    this.elementRef.nativeElement.appendChild(s);
    

  }
}
