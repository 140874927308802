import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { Forgot } from '../models/forgot';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  forgotPayload = new Forgot()

  forgotForm = new FormGroup({
    email: new FormControl('', Validators.required),
    tenant: new FormControl('', Validators.required),
  })

  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  isLoadingOtp : boolean = false;
  errorMessage: any;
  successMessage: any;

  constructor(private authService: AuthService, private localStorageService: LocalStorageService, public router: Router) { }

  ngOnInit(): void {
  }

  get email() {
    return this.forgotForm?.get('email');
  }

  get tenant() {
    return this.forgotForm?.get('tenant');
  }


  onForgot(){
    this.isLoading = true;
    var tenantUser = this.forgotForm.get('tenant')?.value;
    this.localStorageService.setItem('tenantId', tenantUser);
    this.forgotPayload.login = this.forgotForm.get('email')?.value;

    this.authService.reset(this.forgotPayload).subscribe({
      next: (data) => {
        this.hasSuccess = true;
        this.successMessage = "Un nouveau mot de passe est envoyé sur votre email.";
        this.hasError = false;
        this.isLoading = false;
        this.forgotForm.reset();
      },
      error: (err) => {
        this.errorMessage = "Une erreur s'est produite.";
        this.hasError = true;
        this.isLoading = false;
      },
    })
  }

}
