<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
  <div class="breadcrumb-title pe-3">Paiements</div>
  <div class="ps-3">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item"><a routerLink="/dashboard">Tableau de Bord</a></li>
              <li class="breadcrumb-item"><a routerLink="/paiement/contribuable">Effectuer un paiement</a></li>
              <li class="breadcrumb-item"><a routerLink="/paiement/contribuable/activite/{{ActiviteId}}">Activité du contribuable</a></li>
              <li class="breadcrumb-item active" aria-current="page">Local du contribuable</li>
          </ol>
      </nav>
  </div>
</div>
<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">Locals contribuables</h5>
                </div>
                <div class="table-responsive mt-3">
                  <table id="example2" class="table align-middle">
                    <thead class="table-secondary" class="table-primary">
                      <tr>
                        <th>Image</th>
                        <th>Nom</th>
                        <th>ilot</th>
                        <th>lot</th>
                        <th>longitude</th>
                        <th>latitude</th>
                        <th>point de rèpere</th>
                        <th>quartier</th>
                        <th>lien de localisation</th>
                        <th class="text-center" style="width: 50px;"></th>
                      </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
        </div>
    </div>
</div>