import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/LocalStorageService';
import { environment } from 'src/environments/environment.prod';
import { Activite } from '../models/activite';

@Injectable({
  providedIn: 'root'
})
export class ActiviteService {

  ipAdress : string = `${environment.IpAdress}`;

  BaseUrl: string = `${environment.BaseUrl}`;
  constructor(private http : HttpClient,private localStorageService:LocalStorageService) {
  }

  onHeader(){
    var tenant  = this.localStorageService.getItem('tenantId');
    var token: string = 'Bearer '+this.localStorageService.getItem('token');
    var httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'X-Content-Type-Options': 'no-sniff', 'X-TenantID': tenant, 'Authorization':token, 'Accept-language': 'fr'})
    }
    return httpOption;
  }

  save(payload:Activite):Observable<any> {
    return this.http.post<Activite>(`${this.ipAdress}/${this.BaseUrl}activites`, payload, this.onHeader());
  }

  findAll():Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}activites`, this.onHeader());
    
  }
  findById(id : any):Observable<any> {
    return this.http.get<any>(`${this.ipAdress}/${this.BaseUrl}activites/${id}`, this.onHeader());
  }

  update(payload: Activite, id : any):Observable<any> {
    return this.http.put<any>(`${this.ipAdress}/${this.BaseUrl}activites/${id}`, payload, this.onHeader());
  }

}
