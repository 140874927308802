<div class="row">
    <div class="col col-md-12 col-lg-12 mx-auto">
        <div class="card radius-10">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">Audits</h5>
                    <div class="ms-auto position-relative">
                        <form class="row" novalidate [formGroup]="auditForm">
                            <div class="col-12 mb-2">
                                <label class="form-label">Date d'activité</label>
                                <input type="date" class="form-control form-control-sm"  placeholder="Entrer une date"
                                    formControlName="date">
                            </div>
                        </form>
                    </div>
                </div>
                <div class="table-responsive mt-3">
                    <table id="example2" class="table align-middle">
                        <thead class="table-secondary" class="table-primary">
                            <tr>
                                <th>Utilisateur </th>
                                <th>Email</th>
                                <th>Ip</th>
                                <th>Page</th>
                                <th>Action</th>
                                <th>Message</th>
                                <th>Valeur</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
